import { SET_HOMES, ADD_HOME, UPDATE_HOME, SAVE_HOME, REMOVE_HOME, REFRESH_HOMES } from '../actions/homes';
import Analytics from '../utils/analytics';

export default function homes(state = [], { type, payload }) {
    switch (type) {
        case SET_HOMES:
        case REFRESH_HOMES: {
            (state ?? []).forEach((home) => {
                const receivedHome = (payload ?? []).find((result) => result?.id === home?.id);
                Analytics.trackHomeCompletion(home, receivedHome);
            });
            return payload;
        }
        case ADD_HOME:
            return state.concat(payload);
        case SAVE_HOME:
        case UPDATE_HOME: {
            const currentHome = (state ?? []).find((home) => home?.id == payload?.id);
            Analytics.trackHomeCompletion(currentHome, payload);
            return state.map((home) => {
                if (home.id == payload.id) {
                    return payload;
                }
                return home;
            });
        }
        case REMOVE_HOME:
            return state.filter((home) => home.id != payload.id);
        default:
            return state;
    }
}
