import i18n from '../i18n';

export default class Common {
    static getApiUri() {
        return (process.env.URL_API || '').replace(/\/$/, '');
    }

    static request(params, retry = true, relativeUrl = false) {
        const ajaxParams = Common.prepareRequest(params, relativeUrl);

        const xhr = new XMLHttpRequest();
        Object.assign(ajaxParams, {
            xhr: () => xhr
        });

        if (params.hasOwnProperty('auth') && !params.auth) {
            xhr.auth = false;
        }

        // progressbar profile handler
        if (params.progressBarProfile) {
            xhr.upload.addEventListener(
                'progress',
                (evt) => {
                    if (evt.lengthComputable) {
                        const percentComplete = (evt.loaded / evt.total) * 100;
                        $('#upload-profile').find('.upload-progress-bar').css('width', `${percentComplete}%`);
                    }
                },
                false
            );
        }

        const ajax = $.ajax(ajaxParams);

        // retry request if failed with status 401
        ajax.fail((req) => {
            if (req.status == 401 && retry) {
                const deferred = $.Deferred();
                setTimeout(() => {
                    deferred.resolve(Common.request(params, false));
                }, 1000);
                return deferred;
            }
            return req;
        });

        // extends promise with an "abort" method
        const promise = $.when(ajax);
        promise.abort = function () {
            if (typeof xhr.abort === 'function') {
                xhr.abort();
            }
        };
        return promise;
    }

    static prepareRequest(params, relativeUrl = false) {
        const ajaxParams = Object.assign(
            {
                method: 'GET',
                dataType: 'json',
                contentType: 'application/json'
            },
            params
        );

        if (!relativeUrl) {
            ajaxParams.url = params.url.match(/^https?:\/\//) ? params.url : Common.getApiUri() + params.url;
        }

        // Stringify JSON data if needed
        if (
            ajaxParams.contentType == 'application/json' &&
            _.contains(['post', 'patch'], ajaxParams.method)
        ) {
            ajaxParams.data = JSON.stringify(params.data);
        }

        // use current locale as accept-language per default
        ajaxParams.headers = Object.assign(
            {},
            { 'Accept-Language': i18n.language, he_web_version: process.env.VERSION },
            ajaxParams.headers
        );

        return ajaxParams;
    }

    static params(params, filters, orderBy, limit, offset) {
        if (filters) {
            params.data = Object.assign({}, params.data, { filters });
        }
        if (orderBy) {
            params.data = Object.assign({}, params.data, { order_by: orderBy });
        }
        if (limit) {
            params.data = Object.assign({}, params.data, { limit });
        }
        if (offset) {
            params.data = Object.assign({}, params.data, { offset });
        }
        return params;
    }

    static fetch(params, callback, fail) {
        return Common.request(params).done(callback).fail(fail);
    }

    static login(email, password, locale, data) {
        return $.ajax({
            url: '/auth/signin',
            method: 'POST',
            dataType: 'json',
            data: {
                email,
                password,
                locale,
                data
            }
        });
    }

    static loginApple(res, data) {
        return $.ajax({
            url: '/auth/signin/apple',
            method: 'POST',
            data: {
                res,
                data
            }
        });
    }

    static loginFacebook(fbId, fbToken, data) {
        return $.ajax({
            url: '/auth/signin/facebook',
            method: 'POST',
            data: {
                userID: fbId,
                accessToken: fbToken,
                data
            }
        });
    }
}
