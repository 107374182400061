import React from 'react';
import { StepsComponent } from './Steps';
import styles from './nextSteps.module.scss';
import { Button, Callout, Flex, Title, Text, Link, Divider, Container } from '@homeexchange/design';
import i18n from '../../../../../i18n';
import { Trans } from 'react-i18next';
import Utils from '../../../../../utils/utils';
import { trackButtonClick } from '../../../../../utils/utils-ts';
export function NextSteps(props) {
    var user = props.user, currentHome = props.currentHome, annualMembership = props.annualMembership;
    var defaultLocales = ['fr', 'en', 'it'];
    var localePrefix = defaultLocales.includes(i18n.language) ? '' : "/".concat(i18n.language);
    var handleCompleteProfile = function (text) {
        trackButtonClick('stepper_page', text);
        window.location.href = "".concat(localePrefix, "/user/edit/").concat(user.id);
    };
    var handleSubscribe = function (text) {
        trackButtonClick('stepper_page', text);
        window.location.href = i18n.t('url:my-plan.url');
    };
    var handleCreateHome = function (text) {
        trackButtonClick('stepper_page', text);
        window.location.href = currentHome === null || currentHome === void 0 ? void 0 : currentHome.getEditUrl();
    };
    var BUTTONS = {
        COMPLETE_HOME: {
            label: i18n.t('home:funnel.homeCompletion.nextSteps.cta.completeHome'),
            onClick: function () { return handleCreateHome('i_complete_my_home'); }
        },
        COMPLETE_PROFILE: {
            label: i18n.t('home:funnel.homeCompletion.nextSteps.cta.completeProfile'),
            onClick: function () { return handleCompleteProfile('i_complete_my_profile'); }
        },
        SUBSCRIBE: {
            label: i18n.t('home:funnel.homeCompletion.nextSteps.cta.becomeMember'),
            onClick: function () { return handleSubscribe('i_complete_my_membership'); }
        }
    };
    var getButtons = function () {
        var isHomeComplete = (currentHome === null || currentHome === void 0 ? void 0 : currentHome.get('completion_rate')) === 100;
        var isProfileComplete = (user === null || user === void 0 ? void 0 : user.get('completion_rate')) === 100;
        if (isProfileComplete && isHomeComplete) {
            return [BUTTONS.SUBSCRIBE];
        }
        if (isHomeComplete) {
            return [BUTTONS.COMPLETE_PROFILE, BUTTONS.SUBSCRIBE];
        }
        if (isProfileComplete) {
            return [BUTTONS.COMPLETE_HOME, BUTTONS.SUBSCRIBE];
        }
        return [BUTTONS.COMPLETE_HOME, BUTTONS.COMPLETE_PROFILE];
    };
    var buttons = getButtons();
    var steps = [
        {
            id: 'create_home',
            img: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/create-home.svg"),
            imageAlt: i18n.t('welcome.steps.create_home.image_alt'),
            title: i18n.t('home:funnel.onboarding.getStarted.createHome.title'),
            description: i18n.t('home:funnel.onboarding.getStarted.createHome.body'),
            completed: (currentHome === null || currentHome === void 0 ? void 0 : currentHome.get('completion_rate')) === 100,
            onClick: (currentHome === null || currentHome === void 0 ? void 0 : currentHome.get('completion_rate')) <= 100 &&
                (function () { return handleCreateHome('create_my_home_section'); })
        },
        {
            id: 'complete_profile',
            img: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/complete-profile.svg"),
            imageAlt: i18n.t('welcome.steps.complete_profile.image_alt'),
            title: i18n.t('home:funnel.onboarding.getStarted.completeProfile.title'),
            description: i18n.t('home:funnel.onboarding.getStarted.completeProfile.body'),
            completed: user.get('completion_rate') === 100,
            onClick: user.get('completion_rate') <= 100 && (function () { return handleCompleteProfile('profile_section'); })
        },
        {
            id: 'subscribe',
            img: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/activate-membership.svg"),
            imageAlt: i18n.t('welcome.steps.subscribe.image_alt'),
            title: i18n.t('home:funnel.onboarding.getStarted.becomeMember.title', {
                price: annualMembership
            }),
            description: i18n.t('home:funnel.onboarding.getStarted.becomeMember.body'),
            completed: false,
            onClick: user.get('completion_rate') <= 100 && (function () { return handleSubscribe('membership_section'); })
        }
    ];
    return (React.createElement(Container, { className: styles['container'] },
        React.createElement(Flex, { gap: "4", isFullWidth: true },
            React.createElement(Flex, { gap: "4", direction: "column", isFullWidth: true, flexGrow: true },
                React.createElement(Title, { className: styles['title'], level: 2 }, i18n.t('home:funnel.homeCompletion.nextSteps.title')),
                React.createElement(StepsComponent, { user: user, currentHome: currentHome, steps: steps }),
                React.createElement("div", { className: styles['callout-container'] },
                    React.createElement(Callout, { hasIcon: true },
                        React.createElement(Flex, { direction: "column", gap: "2" },
                            React.createElement(Text, { isBold: true }, i18n.t('home:funnel.homeCompletion.nextSteps.calloutTitle')),
                            React.createElement("ul", { className: styles['callout-body-list'] },
                                React.createElement("li", null,
                                    React.createElement(Text, null,
                                        React.createElement(Trans, { i18n: i18n, i18nKey: "home:funnel.homeCompletion.nextSteps.calloutBody.ambassadeurs", components: {
                                                chatLink: (React.createElement(Link, { external: true, href: "".concat(i18n.t('url:dashboard'), "?chatOpen=true") },
                                                    React.createElement(React.Fragment, null)))
                                            } }))),
                                React.createElement("li", null,
                                    React.createElement(Text, null,
                                        React.createElement(Trans, { i18n: i18n, i18nKey: "home:funnel.homeCompletion.nextSteps.calloutBody.facebook", components: {
                                                fbGroupLink: (React.createElement(Link, { external: true, href: "https://www.facebook.com/groups/AllAboutHomeExchange" },
                                                    React.createElement(React.Fragment, null))),
                                                forumLink: (React.createElement(Link, { external: true, href: i18n.t('url:forum', {
                                                        defaultValue: 'https://forum.homeexchange.com/c/20'
                                                    }) },
                                                    React.createElement(React.Fragment, null)))
                                            } }))))))),
                React.createElement(Divider, { space: "small" })),
            React.createElement("div", { className: styles['buttons-container'] }, buttons.map(function (button, index) { return (React.createElement(Button, { key: index, color: index === 0 ? 'primary' : 'tertiary', onClick: button.onClick }, button.label)); })))));
}
