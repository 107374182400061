import Api from '../api/Api';
import Transfert from '../models/Transfert';

export const SET_TRANSFERTS = 'SET_TRANSFERTS';

export function setTransferts(transferts) {
    return {
        type: SET_TRANSFERTS,
        payload: transferts
    };
}

export function fetchTransferts() {
    return (dispatch) =>
        Api.User.getTransferts().then((result) => {
            const transferts = result.map((transfert) => new Transfert(transfert));
            dispatch(setTransferts(transferts));
        });
}

export function fetchTransfers(page) {
    return (dispatch) =>
        Api.Transfer.getTransfers(page).then((result) => {
            const transfers = result.items.map((transfert) => new Transfert(transfert));

            result = { ...result, items: transfers };

            dispatch(setTransferts(result));
        });
}
