import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../i18n';
import { Divider, Text } from '@homeexchange/design';
import AvailabilitiesCaptionCalendar from './availabilities-ts/availabilities-caption-calendar';

const CalendarCaption = ({ openDropdown = false }) => {
    return (
        <>
            <details className="calendar-caption" open={openDropdown}>
                <summary>
                    <span>{i18n.t('home:calendar-caption.how-calendar-works')}</span>
                    <i className="fa fa-angle-down down-arrow"></i>
                </summary>
                <div className="calendar-caption-content">
                    <Text className="calendar-caption-subtitle">
                        {i18n.t('home:calendar-caption.your-home-unavailable')}
                    </Text>
                    <ol className="calendar-caption-list">
                        <li>
                            <Text>{i18n.t('home:calendar-caption.instruction-start-range')}</Text>
                        </li>
                        <li>
                            <Text>{i18n.t('home:calendar-caption.instruction-choose-type')}</Text>
                        </li>
                    </ol>
                    <Text>
                        {i18n.t('home:calendar-caption.feeling-lost')}{' '}
                        <a
                            className="calendar-caption-link"
                            href={i18n.t('home:calendar.white_explanation_link')}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span>{i18n.t('home:calendar-caption.learn-more-link')}</span>
                            <i className="icon-open-link"></i>
                        </a>
                    </Text>
                </div>
                <Divider space="small" />
                <AvailabilitiesCaptionCalendar />
            </details>
        </>
    );
};

CalendarCaption.propTypes = {
    isOwner: PropTypes.bool,
    userFirstName: PropTypes.string,
    dropdown: PropTypes.bool,
    openDropdown: PropTypes.bool,
    verticalDisplay: PropTypes.bool,
    horizontalDisplay: PropTypes.bool
};

export default CalendarCaption;
