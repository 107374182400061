import { UuidV4 } from '@homeexchange-legacy/events-api-client-js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { search, searchProcessing, searchResults } from '../../actions/search';
import i18n from '../../i18n';
import User from '../../models/User';
import Analytics from '../../utils/analytics';
import Utils from '../../utils/utils';

import { searchResultsData, trackPerformedSearch, trackSearchResults } from '../../utils/utils-ts';
import useGTM from '../analytics/useGTM';
import withAnalytics from '../analytics/withAnalytics';

const { fetchSearchTrackingData } = useGTM();

export class SearchProvider extends React.PureComponent {
    static MAX_RESULTS_TO_SHOW_ADDITIONAL_SEARCH = 20;

    static propTypes = {
        children: PropTypes.element,
        resultsCount: PropTypes.number,
        search: PropTypes.func.isRequired,
        searchResults: PropTypes.func.isRequired,
        searchProcessing: PropTypes.func.isRequired,
        queryString: PropTypes.string,
        track: PropTypes.func.isRequired,
        user: PropTypes.instanceOf(User)
    };

    constructor(props) {
        super(props);

        this.state = {
            searchInProcess: false,
            displaySearchInThisRegionBtn: false
        };
        this.isLargeScreen = this.isLargeScreen.bind(this);
        this.getNumResultsPerPage = this.getNumResultsPerPage.bind(this);
        this.runNewSearch = this.runNewSearch.bind(this);
        this.runSearchForPage = this.runSearchForPage.bind(this);
        this.updateDisplaySearchInThisRegionBtnStatus =
            this.updateDisplaySearchInThisRegionBtnStatus.bind(this);
    }

    updateDisplaySearchInThisRegionBtnStatus(status) {
        this.setState({ displaySearchInThisRegionBtn: status });
    }

    /**
     * Return true if the screen is larger than 1366px
     */
    isLargeScreen() {
        return 'matchMedia' in window && matchMedia('(min-width: 768px)').matches;
    }

    /**
     * Returns the number of homes to display based on the screen width
     */
    getNumResultsPerPage() {
        if (this.isLargeScreen()) {
            return 36;
        } else {
            return 20;
        }
    }

    /**
     * Get offset for main request based on a page number
     * @param {int} page
     */
    getOffsetForPage(page = 1) {
        if (page < 1) {
            return 0; // out of range
        }
        return (page - 1) * this.getNumResultsPerPage();
    }

    /**
     * Get offset for additional request based on a page number
     * @param {int} page
     * @param {int} resultsCount number of results for the main request
     */
    getAdditionalOffsetForPage(page = 1, resultsCount = 0) {
        if (page < 1) {
            return 0; // out of range
        }
        return Math.max(0, (page - 1) * this.getNumResultsPerPage() - resultsCount);
    }

    /**
     * Get the limit for the additional request
     * @param {int} page
     * @param {int} resultsCount number of results for the main request
     */
    getAdditionalLimitForPage(page = 1, resultsCount = 0) {
        const itemsPerPage = this.getNumResultsPerPage();
        if (page < 1) {
            return itemsPerPage; // out of range
        }
        if (itemsPerPage * (page - 1) < resultsCount && resultsCount < itemsPerPage * page) {
            return itemsPerPage * page - resultsCount;
        } else {
            return itemsPerPage;
        }
    }

    /**
     * Handle dual search when number of results less than MAX_RESULTS_TO_SHOW_ADDITIONAL_SEARCH
     * @param {Object} searchParams
     * @param {int} currentPage
     */
    runNewSearch(searchParams, currentPage = 1) {
        const trackingData = fetchSearchTrackingData(searchParams.filters.calendar);
        trackPerformedSearch(trackingData.searchParam, trackingData.searchValue);

        return this.executeSearch(searchParams, currentPage).then((results) => {
            if (this.shouldDoAdditionalSearchForPage(results.total, currentPage)) {
                trackSearchResults(searchResultsData.text.dualSearch);
                // Perform additional search to suggest other homes when small amount of results
                searchParams.offset = this.getAdditionalOffsetForPage(currentPage, results.total);
                searchParams.limit = this.getAdditionalLimitForPage(currentPage, results.total);
                return this.executeSearch(searchParams, currentPage, true);
            } else {
                // Empty results for additional search
                this.props.searchResults(
                    {
                        homes: [],
                        bounds: null,
                        total: 0
                    },
                    true
                );
            }
            return results;
        });
    }

    /**
     * Run search for a given page
     * @param {Object} filters
     * @param {int} page page to load
     */
    runSearchForPage(filters, page) {
        if (this.shouldDoMainSearchForPage(this.props.resultsCount, page)) {
            filters.offset = this.getOffsetForPage(page);
            filters.limit = this.getNumResultsPerPage();
            this.executeSearch(filters, page, false, true);
        }

        if (this.shouldDoAdditionalSearchForPage(this.props.resultsCount, page)) {
            const additionalFilters = Object.assign({}, filters, {
                offset: this.getAdditionalOffsetForPage(page, this.props.resultsCount),
                limit: this.getAdditionalLimitForPage(page, this.props.resultsCount)
            });
            this.executeSearch(additionalFilters, page, true, true);
        }
    }

    /**
     * Should reset and hide the results for the additional search
     * @param {Object} filters Search filters
     */
    shouldResetAdditionalSearch(filters) {
        return !(filters.calendar && filters.calendar.date_ranges?.length > 0);
    }

    /**
     * Should we trigger the main search for this page
     * @param {int} resultsCount Number of results of the main search
     * @param {int} page
     */
    shouldDoMainSearchForPage(resultsCount, page) {
        return resultsCount > (page - 1) * this.getNumResultsPerPage();
    }

    /**
     * Should we trigger the additional search for this page
     * @param {int} resultsCount Number of results of the main search
     * @param {int} page
     */
    shouldDoAdditionalSearchForPage(resultsCount, page) {
        if (
            !_.isNull(resultsCount) &&
            resultsCount < SearchProvider.MAX_RESULTS_TO_SHOW_ADDITIONAL_SEARCH &&
            resultsCount <= page * this.getNumResultsPerPage()
        ) {
            return true;
        }
        return false;
    }

    /**
     * Handle the call to the API
     * @param {Object} searchParams contains filters, offset, limit and placeId
     * @param {int} currentPage the current page (used to compute offsets)
     * @param {bool} isAdditional is it an additional request (dual search)
     * @param {bool} noAbort do not abord previous request
     */
    executeSearch(searchParams = {}, currentPage, isAdditional = false, noAbort = false) {
        this.setState({ searchInProcess: true });
        const { filters, placeId, lastSearchId } = searchParams;
        const offset = _.isNumber(searchParams.offset)
            ? searchParams.offset
            : this.getOffsetForPage(currentPage);
        const limit = searchParams.limit || this.getNumResultsPerPage();

        // remove "extended" before each search
        filters.extended = undefined;

        if (isAdditional) {
            filters.extended = true;
        }

        const params = {
            queryString: this.props.queryString || this.getQuery(),
            filters,
            offset,
            limit,
            placeId,
            lastSearchId
        };

        document.dispatchEvent(
            new CustomEvent('search-start', {
                detail: {
                    type: isAdditional ? 'additional' : 'main'
                }
            })
        );

        return this.props.search(params, isAdditional, noAbort).then((results) => {
            this.setState({
                searchInProcess: false,
                displaySearchInThisRegionBtn: false
            });
            document.dispatchEvent(
                new CustomEvent('search-success', {
                    detail: {
                        type: isAdditional ? 'additional' : 'main'
                    }
                })
            );

            // Event api client track
            const queryId = new UuidV4();
            this.props.track('homes-searched', {
                id: queryId,
                query: Utils.filterObjectRecursive(filters)
            });

            if (results && results.homes && results.homes.length > 0) {
                const homes = results.homes.map((home) => home.id);
                this.props.track('home-search-results-viewed', { homes, queryId });
            }

            // Iterable tracking
            const travellers =
                filters.home && filters.home.size && filters.home.size.beds
                    ? filters.home.size.beds.adults +
                      filters.home.size.beds.children +
                      filters.home.size.beds.babies
                    : 0;

            const lastMinuteFilter = filters.hasOwnProperty('lastMinute') && filters.lastMinute;

            const exchangeTypeFilter =
                filters.availability &&
                filters.availability.hasOwnProperty('reciprocal') &&
                filters.availability.reciprocal;

            const dateRange =
                filters.availability && filters.availability.date_range
                    ? filters.availability.date_range
                    : {};

            const reverseSearhFilter = filters.hasOwnProperty('preferred_destination');

            if (isAdditional && window.hj) {
                window.hj.locationListener?.setMode('manual'); // Disable issue with URL change
                window.hj('trigger', `additional-search-executed`);
            }

            if (!isAdditional && this.props.user) {
                try {
                    Analytics.trackIterable('HomesSearchedForIterable', {
                        email: this.props.user.get('email'),
                        ...(travellers > 0 && { travellers }),
                        ...(dateRange.from && { lastSearchStartOn: dateRange.from }),
                        ...(dateRange.to && { lastSearchEndOn: dateRange.to }),
                        lastSearchExecutedAt: moment().format('YYYY-MM-DD'),
                        ...(params.queryString && {
                            destinationLastSearch: params.queryString
                        }),
                        urlLastSearch: window.location.href,
                        reverseSearhFilter: reverseSearhFilter ? 'yes' : 'no',
                        exchangeTypeFilter: exchangeTypeFilter ? 'reciprocal' : 'guestpoints',
                        lastMinuteFilter: lastMinuteFilter ? 'yes' : 'no'
                    });
                } catch (error) {
                    console.error('Error while tracking Iterable - HomesSearchedForIterable', error);
                }
            }

            return results;
        });
    }

    /**
     * Get search query from URL
     */
    getQuery() {
        const matches = document.location.pathname.match('/search-v2/(.+)');
        if (matches && matches.length > 1) {
            let query = decodeURIComponent(matches[1]);
            if (query === i18n.t('search:everywhere') || query === 'everywhere') {
                query = '';
            }
            return query;
        }
        return '';
    }

    render() {
        return React.cloneElement(this.props.children, {
            getNumResultsPerPage: this.getNumResultsPerPage,
            getQuery: this.getQuery,
            search: this.runNewSearch,
            searchProcessing: this.props.searchProcessing,
            searchPage: this.runSearchForPage,
            showAdditionalSearch: this.shouldDoAdditionalSearch,
            searchInProcess: this.state.searchInProcess,
            displaySearchInThisRegionBtn: this.state.displaySearchInThisRegionBtn,
            updateDisplaySearchInThisRegionBtnStatus: this.updateDisplaySearchInThisRegionBtnStatus
        });
    }
}

const mapStateToProps = (state) => ({
    resultsCount: state.search.main ? state.search.main.resultsCount : null,
    queryString: state.search.main ? state.search.main.queryString : null,
    accessToken: state.auth.accessToken,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    search: bindActionCreators(search, dispatch),
    searchResults: bindActionCreators(searchResults, dispatch),
    searchProcessing: bindActionCreators(searchProcessing, dispatch)
});

export default compose(withAnalytics, connect(mapStateToProps, mapDispatchToProps))(SearchProvider);
