import React from 'react';
import i18n from '../../../i18n';
import styles from './availabilities.module.scss';
import AvailabilityCaptionCalendar from './availability-caption-calendar';
var AVAILABLE_TYPES = [
    {
        type: 'reciprocal',
        title: i18n.t('exchange:reciprocal'),
        description: i18n.t('home:calendar.exchange_type_reciprocal.description')
    },
    {
        type: 'guest-points',
        title: i18n.t('home:guestpoints'),
        description: i18n.t('home:calendar.exchange_type_non_reciprocal.description')
    },
    {
        type: 'available',
        title: i18n.t('home:availability_popup.any_type'),
        description: i18n.t('home:calendar.exchange_type_available.description')
    }
];
var UNAVAILABLE_TYPES = [
    {
        type: 'reserved',
        title: i18n.t('home:calendar.exchange_type_reserved.title'),
        description: i18n.t('home:calendar.exchange_type_reserved.description')
    },
    {
        type: 'booked',
        title: i18n.t('home:disponibility.booked'),
        description: i18n.t('home:disponibility.booked_calendar_caption_description')
    }
];
export var AvailabilitiesCaptionCalendar = function () {
    return (React.createElement("div", { className: styles['caption-container'] },
        React.createElement("span", { className: styles['availabilities-info'] }, i18n.t('home:calendar-caption.available-home')),
        React.createElement("div", { className: styles['availabilities-row'] }, AVAILABLE_TYPES.map(function (availability, index) { return (React.createElement(AvailabilityCaptionCalendar, { key: index, title: availability.title, description: availability.description, type: availability.type })); })),
        React.createElement("span", { className: styles['availabilities-info'] }, i18n.t('home:calendar-caption.unavailable-home')),
        React.createElement("div", { className: styles['availabilities-row'] }, UNAVAILABLE_TYPES.map(function (availability, index) { return (React.createElement(AvailabilityCaptionCalendar, { key: index, title: availability.title, description: availability.description, type: availability.type })); }))));
};
export default AvailabilitiesCaptionCalendar;
