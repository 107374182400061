import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import { Modal, AlertDialog, Button } from '@homeexchange/design';
import CalendarUtils from '../../../utils/CalendarUtils';

import { Availabilities } from '../availabilities-ts';
import Availability from '../../../models/Availability';

const CalendarSelectAvailabilitiesModal = ({
    dateRange,
    validationHandler,
    mergedPeriods,
    type,
    overlapsSingleAvailability,
    overlapsAvailabilities,
    minimumNights,
    selectedTypeFromCalendar,
    contactAllowed
}) => {
    const [view, setView] = useState('init');
    const [showModal, setShowModal] = useState(true);
    const [showAlertDialog, setAlertDialog] = useState(true);
    const [selectedType, setSelectedType] = useState(
        overlapsSingleAvailability ? selectedTypeFromCalendar : false
    );
    const [error, setError] = useState(null);
    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };
    const popupText = () => {
        let emptyNight = null;

        if (mergedPeriods) {
            if (dateRange[0]?.isSame(mergedPeriods[0])) {
                emptyNight = dateRange[1];
            } else if (dateRange[1]?.isSame(mergedPeriods[1])) {
                emptyNight = dateRange[0].add(-1, 'days');
            }

            if (emptyNight) {
                return i18n.t('common:calendar.night.in.between', {
                    d1: `${emptyNight.format('D MMMM')}`,
                    d2: `${emptyNight.add(1, 'days').format('D MMMM')}`
                });
            } else {
                return i18n.t('common:calendar.night.in.between_plural', {
                    count: 2,
                    d1: `${mergedPeriods[0].format('D MMMM')}`,
                    d2: `${mergedPeriods[1].format('D MMMM')}`
                });
            }
        }
    };

    const handlePass = () => {
        setView('pass');
        setAlertDialog(false);
        setShowModal(true);
    };

    const handleConfirm = () => {
        if (selectedType) {
            validationHandler(selectedType, dateRange);
            setError(null);
        } else {
            setError('Error here');
        }
    };
    const showNumberOfNightMessage = () => {
        const numberOfNightsFromCalendar = CalendarUtils.calculateNumberOfNightsOfSelectedDates(dateRange);
        return numberOfNightsFromCalendar < minimumNights;
    };
    const mergePeriods = () => {
        validationHandler(type, mergedPeriods);
        setAlertDialog(false);
    };

    if (mergedPeriods === null || view === 'pass') {
        return (
            <Modal
                title={
                    overlapsAvailabilities ? i18n.t('home:manage_availabilities') : i18n.t('home:add_period')
                }
                confirmActionText={
                    overlapsAvailabilities ? i18n.t('common:save_changes') : i18n.t('home:add_period')
                }
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirmAction={handleConfirm}
                confirmationButton={{ disabled: !selectedType }}
                alternativeAction={
                    overlapsAvailabilities ? (
                        <Button
                            color="destructive-ghost"
                            icon="home-bin"
                            onClick={() => validationHandler(Availability.UNAVAILABLE.type, dateRange)}
                        >
                            <span>{i18n.t('home:calendar.delete_range_popup')}</span>
                        </Button>
                    ) : null
                }
            >
                <Availabilities
                    onUpdate={handleTypeChange}
                    dateRange={dateRange}
                    mergedPeriods={mergedPeriods}
                    error={error}
                    selectedType={selectedType}
                    overlapsAvailability={overlapsSingleAvailability}
                    minimumNights={minimumNights}
                    displayMinNbOfNights={showNumberOfNightMessage()}
                    contactAllowed={contactAllowed}
                />
            </Modal>
        );
    } else {
        return (
            <AlertDialog
                title={i18n.t('common:calendar.night.merge.period')}
                confirmActionText={i18n.t('common:calendar.night.merge.period')}
                isOpen={showAlertDialog}
                onConfirmAction={mergePeriods}
                confirmActionColor="primary"
                dismissActionText={i18n.t('common:calendar.night.keep.separated')}
                onDismissAction={handlePass}
            >
                <p>{popupText()}</p>
            </AlertDialog>
        );
    }
};

CalendarSelectAvailabilitiesModal.propTypes = {
    dateRange: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)])
    ).isRequired,
    validationHandler: PropTypes.func.isRequired,
    mergedPeriods: PropTypes.arrayOf(Date),
    type: PropTypes.string,
    overlapsSingleAvailability: PropTypes.bool,
    overlapsAvailabilities: PropTypes.bool,
    minimumNights: PropTypes.number,
    contactAllowed: PropTypes.number,
    selectedTypeFromCalendar: PropTypes.string
};
export default CalendarSelectAvailabilitiesModal;
