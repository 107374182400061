import _ from 'underscore';
import { CurrencyFormatter } from './Intl';
import i18n from '../i18n';
import Home from '../models/Home';
import Screen from '../utils/Screen';
import User from '../models/User';
import Product from '../models/Product';
import Subscription from '../models/Subscription';
import Dimensions from './Dimensions';

export default class Utils {
    static uniqueIdCounter = {};

    static PRIMARY_COLOR = 'e85710';
    static PRIMARY_COLOR_HE_COLLECTION = 'aa8c78';
    static BASE_COLOR = '191d1f';
    static PLACEHOLDER_COLOR = '8b8b8b';
    static FONT_FAMILY = 'Averta, Helvetica, Arial, Verdana, sans-serif';

    static itemCount() {
        return window && 'matchMedia' in window && window.matchMedia('(min-width: 1024px)').matches ? 8 : 4;
    }

    static getWebsiteLanguage() {
        return document.documentElement.lang || 'en';
    }

    static getLastUrlPath() {
        const path = window.location.pathname.split('/');
        return path[path.length - 1];
    }

    static getPersonOrPeople(count) {
        return count > 1 ? i18n.t('common:people') : i18n.t('common:person');
    }

    static hasUrlPath(value) {
        return location.href.indexOf(value) > -1;
    }

    static parseQuery(query) {
        const re = /([^&=]+)=?([^&]*)/g;

        const decode = function (str) {
            return decodeURIComponent(str.replace(/\+/g, ' '));
        };
        // recursive function to construct the result object
        function createElement(params, key, value) {
            key = String(key);
            // if the key is a property
            if (key.indexOf('.') !== -1) {
                // extract the first part with the name of the object
                const list = key.split('.');
                // the rest of the key
                const newKey = key.split(/\.(.+)?/)[1];
                // create the object if it doesnt exist
                if (!params[list[0]]) {
                    params[list[0]] = {};
                }
                // if the key is not empty, create it in the object
                if (newKey !== '') {
                    createElement(params[list[0]], newKey, value);
                } else {
                    console.warn(`parseParams :: empty property in key "${key}"`);
                }
            } else if (key.indexOf('[') !== -1) {
                // extract the array name
                let list = key.split('[');
                key = list[0];
                // extract the index of the array
                list = list[1].split(']');
                const index = list[0];
                // if index is empty, just push the value at the end of the array
                if (index == '') {
                    if (!params) {
                        params = {};
                    }
                    if (!params[key] || !$.isArray(params[key])) {
                        params[key] = [];
                    }
                    params[key].push(value);
                }
                // add the value at the index
                else {
                    if (!params) {
                        params = {};
                    }
                    if (!params[key] || !$.isArray(params[key])) {
                        params[key] = [];
                    }
                    params[key][index] = value;
                }
            }
            // just normal key
            else {
                if (!params) {
                    params = {};
                }
                params[key] = value;
            }
        }
        // be sure the query is a string
        query = String(query);
        if (query === '') {
            query = String(window.location);
        }
        const params = {};
        let e;
        if (query) {
            // remove # from end of query
            if (query.indexOf('#') !== -1) {
                query = query.substr(0, query.indexOf('#'));
            }

            // remove ? at the begining of the query
            if (query.indexOf('?') !== -1) {
                query = query.substr(query.indexOf('?') + 1, query.length);
            } else {
                return {};
            }
            // empty parameters
            if (query == '') {
                return {};
            }
            // execute a createElement on every key and value
            while ((e = re.exec(query)) !== null) {
                const key = decode(e[1]);
                const value = decode(e[2]);
                createElement(params, key, value);
            }
        }
        return params;
    }

    // utility method to get query parameters
    static getParameter(name, defaultVal) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
        const results = regex.exec(window.location.search);
        return results === null ? defaultVal : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    static addParameter(uri, param, value) {
        const parts = uri.split('?');
        const location = parts.shift();
        let params = parts.shift() || '';
        params = _.reject(params.split('&'), (keyvalue) => {
            const key = keyvalue.split('=')[0];
            return !key || key == param;
        });
        params = params.concat(`${param}=${value}`);
        return `${location}?${params.join('&')}`;
    }

    // utility method to convert PHP date format to Moment.js date format
    static convertPHPFormatMoment(format) {
        return format
            .replace('d', 'DD')
            .replace('j', 'D')
            .replace('m', 'MM')
            .replace('n', 'M')
            .replace('Y', 'YYYY')
            .replace('y', 'YY');
    }

    static slugify(string = '') {
        string = string.toString().toLowerCase();
        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const to = 'aaaaeeeeiiiioooouuuunc------';
        for (let i = 0, len = from.length; i < len; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
        return string
            .replace(/^\s+|\s+$/g, '') // trim
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+|-+$/g, ''); // remove leading, trailing -
    }

    static ucfirst(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    }

    static capitalize(string) {
        return String(string).replace(
            /\w\S*/g,
            (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        );
    }

    static nl2br(string, isXhtml) {
        const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
        return String(string).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
    }

    static deepclone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static convertBboxToBounds(bbox) {
        return {
            ne: {
                lat: bbox[3],
                lon: bbox[2]
            },
            sw: {
                lat: bbox[1],
                lon: bbox[0]
            }
        };
    }

    static filterObjectRecursive(object) {
        const obj = _.clone(object);
        for (const key in obj) {
            if (_.isObject(obj[key]) && !Array.isArray(obj[key])) {
                obj[key] = this.filterObjectRecursive(obj[key]);
            }
        }
        return _.omit(
            obj,
            (value) => _.isNull(value) || _.isUndefined(value) || (_.isObject(value) && _.isEmpty(value))
        );
    }

    static flatifyObject(object) {
        const flat = {};
        for (const key in object) {
            if (Array.isArray(object[key])) {
                flat[key] = object[key].join(',');
            } else if (_.isObject(object[key])) {
                const flatObject = this.flatifyObject(object[key]);
                for (const k in flatObject) {
                    flat[`${key}_${k}`] = flatObject[k];
                }
            } else {
                flat[key] = object[key];
            }
        }
        return flat;
    }

    static getDomain() {
        const urlParts = new URL(window.location.href).hostname.split('.');

        return urlParts.slice(-2).join('.');
    }

    static createCookie(name, value, days, domain) {
        let cookieProperties = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            cookieProperties = `; expires=${date.toGMTString()}`;
        }
        if (domain) {
            cookieProperties = `${cookieProperties}; domain=${domain}`;
        }
        document.cookie = `${name}=${value}${cookieProperties}; path=/`;
    }

    static readCookie(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    static removeCookie(name) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    }

    /**
     * Forces the browser to repaint an element
     * @see http://stackoverflow.com/questions/8840580/force-dom-redraw-refresh-on-chrome-mac
     */
    static forceBrowserRepaint(elem) {
        const disp = elem.style.display;
        elem.style.display = 'none';
        // eslint-disable-next-line no-unused-expressions
        elem.offsetHeight;
        elem.style.display = disp;
    }

    static formatRelativeTime(date, locale) {
        date = moment(date);
        if (locale) {
            date.locale(locale);
        }
        const string = date.calendar(null, {
            sameDay: `[${i18n.t('common:today')}]`,
            lastDay: `[${i18n.t('common:yesterday')}]`,
            lastWeek: 'dddd'
        });

        return string;
    }

    /* static insertAtCaret(textarea, text) {
        const scrollPos = textarea.scrollTop;
        let strPos = 0;
        const br = ((textarea.selectionStart || textarea.selectionStart == '0') ?
            "ff" : (document.selection ? "ie" : false));
        if (br == "ie") {
            textarea.focus();
            const range = document.selection.createRange();
            range.moveStart('character', -textarea.value.length);
            strPos = range.text.length;
        }
        else if (br == "ff") {
            strPos = textarea.selectionStart;
        }

        const front = (textarea.value).substring(0, strPos);
        const back = (textarea.value).substring(strPos, textarea.value.length);
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
        nativeInputValueSetter.call(textarea, front + text + back);
        strPos += text.length;
        if (br == "ie") {
            textarea.focus();
            const range = document.selection.createRange();
            range.moveStart('character', -textarea.value.length);
            range.moveStart('character', strPos);
            range.moveEnd('character', 0);
            range.select();
        }
        else if (br == "ff") {
            textarea.selectionStart = strPos;
            textarea.selectionEnd = strPos;
            textarea.focus();
        }
        textarea.scrollTop = scrollPos;
    } */

    /**
     * Returns the date at midnight UTC time for a given date
     * @param {moment} date
     */
    static getDayAtMidnightUTC(date) {
        const d = moment.parseZone(date);
        return moment(Date.UTC(d.year(), d.month(), d.date(), 0, 0, 0));
    }

    /**
     * Returns the date at midnight local time for a given date
     * Example: you give a date at 22PM in its own timezone, it will return the same date at midnight local time
     * 2017-07-05T22:00:00.000Z -> 2017-07-04T22:00:00.000Z if your time zone is +2
     * @param {moment} date
     */
    static getDayAtMidnightLocalTime(date) {
        const d = moment.parseZone(date);
        return moment(new Date(d.year(), d.month(), d.date()));
    }

    /**
     * Returns the URL to Cloudfront
     */
    static getCloudfrontDomain() {
        return process.env.URL_CLOUDFRONT;
    }

    static mapToObject(map) {
        const obj = Object.create(null);
        for (const [k, v] of map) {
            obj[k] = v;
        }
        return obj;
    }

    static getErrorMessage(error) {
        if (typeof error === 'string') {
            return error;
        }
        if (typeof error === 'object') {
            if (error.hasOwnProperty('responseText')) {
                try {
                    error = JSON.parse(error.responseText);
                } catch (e) {
                    // error.responseText is not a valid JSON string
                    return error.responseText;
                }
            }
            if (error.hasOwnProperty('message')) {
                return error.message;
            } else if (error.hasOwnProperty('error_description')) {
                return error.error_description;
            }
        }
    }

    static featureArrayToFlag(data) {
        if (_.has(data, 'feature')) {
            // Deep copy
            data = JSON.parse(JSON.stringify(data));
            for (const feature in data.feature) {
                if (Array.isArray(data.feature[feature])) {
                    data.feature[feature] = data.feature[feature].reduce(
                        (mask, value) => mask ^ Number(value),
                        0
                    );
                }
            }
        }
        return data;
    }

    static indexBy(arrayOfObjects, key) {
        const indexedObject = {};

        arrayOfObjects.forEach((object) => {
            indexedObject[object[key]] = object;
        });

        return indexedObject;
    }

    static uniqueId(prefix = '$gtg$') {
        if (!Utils.uniqueIdCounter[prefix]) {
            Utils.uniqueIdCounter[prefix] = 0;
        }

        const id = ++Utils.uniqueIdCounter[prefix];
        if (prefix === '$gtg$') {
            return `${id}`;
        }

        return `${prefix + id}`;
    }

    static humanFileSize(size) {
        const base = Math.floor(Math.log(size) / Math.log(1000));
        const value = Math.round(size / Math.pow(1000, base));
        const units = ['b', 'Kb', 'Mb', 'Gb', 'Tb'];
        return `${value} ${units[base]}`;
    }

    static getCountriesList() {
        return [
            { code: 'AF' },
            { code: 'AX' },
            { code: 'AL' },
            { code: 'DZ' },
            { code: 'AS' },
            { code: 'AD' },
            { code: 'AO' },
            { code: 'AI' },
            { code: 'AQ' },
            { code: 'AG' },
            { code: 'AR' },
            { code: 'AM' },
            { code: 'AW' },
            { code: 'AU' },
            { code: 'AT' },
            { code: 'AZ' },
            { code: 'BS' },
            { code: 'BH' },
            { code: 'BD' },
            { code: 'BB' },
            { code: 'BY' },
            { code: 'BE' },
            { code: 'BZ' },
            { code: 'BJ' },
            { code: 'BM' },
            { code: 'BT' },
            { code: 'BO' },
            { code: 'BQ' },
            { code: 'BA' },
            { code: 'BW' },
            { code: 'BV' },
            { code: 'BR' },
            { code: 'IO' },
            { code: 'UM' },
            { code: 'VG' },
            { code: 'VI' },
            { code: 'BN' },
            { code: 'BG' },
            { code: 'BF' },
            { code: 'BI' },
            { code: 'KH' },
            { code: 'CM' },
            { code: 'CA' },
            { code: 'CV' },
            { code: 'KY' },
            { code: 'CF' },
            { code: 'TD' },
            { code: 'CL' },
            { code: 'CN' },
            { code: 'CX' },
            { code: 'CC' },
            { code: 'CO' },
            { code: 'KM' },
            { code: 'CG' },
            { code: 'CD' },
            { code: 'CK' },
            { code: 'CR' },
            { code: 'HR' },
            { code: 'CU' },
            { code: 'CW' },
            { code: 'CY' },
            { code: 'CZ' },
            { code: 'DK' },
            { code: 'DJ' },
            { code: 'DM' },
            { code: 'DO' },
            { code: 'EC' },
            { code: 'EG' },
            { code: 'SV' },
            { code: 'GQ' },
            { code: 'ER' },
            { code: 'EE' },
            { code: 'ET' },
            { code: 'FK' },
            { code: 'FO' },
            { code: 'FJ' },
            { code: 'FI' },
            { code: 'FR' },
            { code: 'GF' },
            { code: 'PF' },
            { code: 'TF' },
            { code: 'GA' },
            { code: 'GM' },
            { code: 'GE' },
            { code: 'DE' },
            { code: 'GH' },
            { code: 'GI' },
            { code: 'GR' },
            { code: 'GL' },
            { code: 'GD' },
            { code: 'GP' },
            { code: 'GU' },
            { code: 'GT' },
            { code: 'GG' },
            { code: 'GN' },
            { code: 'GW' },
            { code: 'GY' },
            { code: 'HT' },
            { code: 'HM' },
            { code: 'VA' },
            { code: 'HN' },
            { code: 'HK' },
            { code: 'HU' },
            { code: 'IS' },
            { code: 'IN' },
            { code: 'ID' },
            { code: 'CI' },
            { code: 'IR' },
            { code: 'IQ' },
            { code: 'IE' },
            { code: 'IM' },
            { code: 'IL' },
            { code: 'IT' },
            { code: 'JM' },
            { code: 'JP' },
            { code: 'JE' },
            { code: 'JO' },
            { code: 'KZ' },
            { code: 'KE' },
            { code: 'KI' },
            { code: 'KW' },
            { code: 'KG' },
            { code: 'LA' },
            { code: 'LV' },
            { code: 'LB' },
            { code: 'LS' },
            { code: 'LR' },
            { code: 'LY' },
            { code: 'LI' },
            { code: 'LT' },
            { code: 'LU' },
            { code: 'MO' },
            { code: 'MK' },
            { code: 'MG' },
            { code: 'MW' },
            { code: 'MY' },
            { code: 'MV' },
            { code: 'ML' },
            { code: 'MT' },
            { code: 'MH' },
            { code: 'MQ' },
            { code: 'MR' },
            { code: 'MU' },
            { code: 'YT' },
            { code: 'MX' },
            { code: 'FM' },
            { code: 'MD' },
            { code: 'MC' },
            { code: 'MN' },
            { code: 'ME' },
            { code: 'MS' },
            { code: 'MA' },
            { code: 'MZ' },
            { code: 'MM' },
            { code: 'NA' },
            { code: 'NR' },
            { code: 'NP' },
            { code: 'NL' },
            { code: 'NC' },
            { code: 'NZ' },
            { code: 'NI' },
            { code: 'NE' },
            { code: 'NG' },
            { code: 'NU' },
            { code: 'NF' },
            { code: 'KP' },
            { code: 'MP' },
            { code: 'NO' },
            { code: 'OM' },
            { code: 'PK' },
            { code: 'PW' },
            { code: 'PS' },
            { code: 'PA' },
            { code: 'PG' },
            { code: 'PY' },
            { code: 'PE' },
            { code: 'PH' },
            { code: 'PN' },
            { code: 'PL' },
            { code: 'PT' },
            { code: 'PR' },
            { code: 'QA' },
            { code: 'XK' },
            { code: 'RE' },
            { code: 'RO' },
            { code: 'RU' },
            { code: 'RW' },
            { code: 'BL' },
            { code: 'SH' },
            { code: 'KN' },
            { code: 'LC' },
            { code: 'MF' },
            { code: 'PM' },
            { code: 'VC' },
            { code: 'WS' },
            { code: 'SM' },
            { code: 'ST' },
            { code: 'SA' },
            { code: 'SN' },
            { code: 'RS' },
            { code: 'SC' },
            { code: 'SL' },
            { code: 'SG' },
            { code: 'SX' },
            { code: 'SK' },
            { code: 'SI' },
            { code: 'SB' },
            { code: 'SO' },
            { code: 'ZA' },
            { code: 'GS' },
            { code: 'KR' },
            { code: 'SS' },
            { code: 'ES' },
            { code: 'LK' },
            { code: 'SD' },
            { code: 'SR' },
            { code: 'SJ' },
            { code: 'SZ' },
            { code: 'SE' },
            { code: 'CH' },
            { code: 'SY' },
            { code: 'TW' },
            { code: 'TJ' },
            { code: 'TZ' },
            { code: 'TH' },
            { code: 'TL' },
            { code: 'TG' },
            { code: 'TK' },
            { code: 'TO' },
            { code: 'TT' },
            { code: 'TN' },
            { code: 'TR' },
            { code: 'TM' },
            { code: 'TC' },
            { code: 'TV' },
            { code: 'UG' },
            { code: 'UA' },
            { code: 'AE' },
            { code: 'GB' },
            { code: 'US' },
            { code: 'UY' },
            { code: 'UZ' },
            { code: 'VU' },
            { code: 'VE' },
            { code: 'VN' },
            { code: 'WF' },
            { code: 'EH' },
            { code: 'YE' },
            { code: 'ZM' },
            { code: 'ZW' }
        ];
    }

    static sortLandmark(result) {
        const sortResult = result.filter((element) => element.MatchLevel !== 'landmark');

        return sortResult.length > 0 ? sortResult : result;
    }

    // Block country for american policies
    static blockCountry(result) {
        // Cuba, North Korea, Crimea, Syria, Iran
        const countryCode = ['CUB', 'PRK', 'SYR', 'IRAN'];
        if (countryCode.includes(result[0].Location.Address.Country)) {
            sweetAlert({
                icon: 'warning',
                text: i18n.t('home:creation_denied'),
                buttons: i18n.t('common:gp_popup_button')
            }).then((isConfirm) => {
                if (isConfirm) {
                    window.location.assign(i18n.t('url:dashboard'));
                }
            });
            return false;
        }
        return result;
    }

    static selectSuggestion = async (suggestion, props = null) => {
        if (suggestion) {
            const predictions = { address: {} };

            predictions.label = suggestion.formatedTitle;
            predictions.gid = suggestion.properties.gid;
            predictions.coordinates = suggestion.geometry.coordinates;
            predictions.country = suggestion.properties.country_a;
            predictions.bbox = suggestion.bbox;
            predictions.layer = suggestion.properties.layer;

            return (await props) && props.onSelect ? props.onSelect(predictions) : props(predictions);
        }
    };

    static isPhotoBlocked(user) {
        if (user) {
            const homes = user.get('homes');

            if (homes && homes.length > 0) {
                const homeOverCompletionRate = homes.some(
                    (home) => home.get('completion_rate') >= Home.MINIMUM_FILLING_TO_CONTACT
                );

                if (homeOverCompletionRate) {
                    return false;
                }
            }
        }

        return true;
    }

    /* ==================== PUSH APP UTILS ============================================ */
    static isAndroid = () => /android/i.test(navigator.userAgent);

    static isIOS = () => {
        const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
        const fromNavigatorPlatform = iDevices.some((device) => navigator.platform === device);
        const fromNavigatorUserAgent = /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream;
        return fromNavigatorPlatform || fromNavigatorUserAgent;
    };

    static getAndroidLocation = () => {
        const meta = document.querySelector("meta[name='google-play-app']");
        if (meta) {
            return `https://play.google.com/store/apps/details?${meta.getAttribute('content')}`;
        }
        return 'https://play.google.com/store/apps/details?id=com.guesttoguest.android';
    };

    static getIOSLocation = () => 'https://itunes.apple.com/app/guesttoguest-echange-maison/id966112496?mt=8';

    /**
     * App notation could be got dynamically at the end...
     */
    static getAppNotation = () => [1, 2, 3, 4, 5];

    static isFirefox = () => navigator.userAgent.indexOf('Firefox') != -1;
    static isChrome = () => navigator.userAgent.indexOf('Chrome') != -1;
    static isSafari = () => navigator.userAgent.indexOf('Safari') != -1;
    static isEdge = () => navigator.userAgent.indexOf('Edge') != -1;
    static isOpera = () => navigator.userAgent.indexOf('Opera') != -1;

    static isLandingPage = () => window.location.pathname.indexOf('/p/') != -1;
    static isFAQPage = () => window.location.hostname.indexOf('homeexchangehelp') != -1;
    static isCollectionPage = () => window.location.pathname.indexOf('/collection') != -1;

    static getPriceHr = (isThirdXPayment, amount) => {
        return isThirdXPayment
            ? Product.PRICE_THIRDX_PAYMENT * Product.FIXED_RATE_HR
            : amount * Product.FIXED_RATE_HR;
    };

    static get2DigitsDecimal = (number) => Math.round(number * 100) / 100;

    static getThreeTimesPaymentPrice = (subPrice, subCurrency) =>
        CurrencyFormatter.format(
            Utils.get2DigitsDecimal((subPrice + Subscription.EUROPE_SERVICE_CHARGE_PRICE) / 3),
            subCurrency
        );

    static isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    static getSelectedAlert = (alerts, id) => alerts.find((alert) => alert.id === id);

    /**
     * Checks if current feature name (flag) is defined in the feature flags object in AWS and return its value
     * @param {Object} featureFlags - The feature flags object from AWS S3 bucket
     * @param {string} flag - The current feature flag (defined as key in featureFlags object)
     * @returns {boolean}
     */
    static featureIsActive(featureFlags, flag) {
        if (!featureFlags.hasOwnProperty(flag)) {
            return false;
        }

        return featureFlags[flag];
    }

    static userIsFromHEAndLHS(userPlatforms) {
        if (!userPlatforms || userPlatforms.length === 0) {
            return false;
        }

        const fromHe = userPlatforms.find((platform) => {
            const { name } = platform.platform;
            return name === User.SOURCE_HE || name === User.SOURCE_NEW_HE || name === User.SOURCE_GTG;
        });

        const fromLhs = userPlatforms.find((platform) => {
            return platform.platform.name === User.SOURCE_LHS;
        });

        return fromHe && fromLhs;
    }

    static openModalsFullScreen(isSearchBarClicked = false) {
        if (Screen.isMobile()) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.height = '100%';
            document.body.style.width = '100%';
            const headNavBand = document.getElementById('head-nav-band');

            if (headNavBand) {
                headNavBand.style.zIndex = isSearchBarClicked ? '' : 0;
            }

            const launcher = document.getElementById('launcher');

            if (launcher) {
                launcher.parentElement.classList.add('hidden');
            }

            Utils.applyStyles('.espace', {
                position: 'relative',
                zIndex: 11
            });

            const buttonMobileMap = document.querySelector('.button-mobile-map');

            if (buttonMobileMap) {
                buttonMobileMap.classList.add('hidden');
            }
        }
    }

    static closeModalsFullScreen() {
        if (Screen.isMobile()) {
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.height = '';
            document.body.style.width = '';
            const headNavBand = document.getElementById('head-nav-band');

            if (headNavBand) {
                headNavBand.style.zIndex = '';
            }

            const launcher = document.getElementById('launcher');

            if (launcher) {
                launcher.parentElement.classList.remove('hidden');
            }

            Utils.applyStyles('.espace', {
                position: '',
                zIndex: ''
            });

            const buttonMobileMap = document.querySelector('.button-mobile-map');

            if (buttonMobileMap) {
                buttonMobileMap.classList.remove('hidden');
            }
        }
    }

    static isSearchBarClicked(event) {
        let currentElement = event.target;

        while (currentElement && !currentElement.classList.contains('search-bar-wrapper')) {
            currentElement = currentElement.parentElement;
        }

        return currentElement?.classList.contains('search-bar-wrapper') || false;
    }

    static applyStyles = (selector, styles, child = false) => {
        const applyStylesToElement = (element, stylesElement) => {
            if (element) {
                Object.assign(element.style, stylesElement);
            }
        };

        applyStylesToElement(document.querySelector(selector), styles);

        if (child) {
            applyStylesToElement(document.querySelector(child.selector), child.styles);
        }
    };

    static isCollectionWebSite = () => window?.gtg?.heCollection;

    static scrollToElement(element, excludeNav = false) {
        let position = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 15;

        if (excludeNav === false) {
            const headerHeight = Dimensions.getNavHeight();
            position = position - headerHeight;
        }

        window.scrollTo({ top: position, behavior: 'smooth' });
    }

    static scrollToElementById(elementId, excludeNav = false) {
        const element = document.getElementById(elementId);
        this.scrollToElement(element, excludeNav);
    }
}
