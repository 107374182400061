export var stepFields = {
    '1': {
        '1': ['type'],
        '2': ['shared_part'],
        '3': ['detail.residence_type'],
        '4': ['address', 'location_id', 'overridden_address'],
        '4.1': ['legal_registration_homes[0].data[0].value'],
        '5': ['feature.surrounding'],
        '6': ['detail.location_type'],
        '7': ['detail.size', 'detail.size_unit'],
        '8': ['detail.bedroom_nb', 'detail.bathroom_nb'],
        '9': [
            'detail.single_bed',
            'detail.double_bed',
            'detail.big_double_bed',
            'detail.children_bed',
            'detail.baby_bed',
            'detail.double_bed_up',
            'detail.single_bed_up',
            'detail.children_bed_up',
            'detail.baby_bed_up'
        ]
    },
    '2': {
        '1': ['feature.practical', 'is_accessible_for_prm'],
        '2': ['feature.multimedia'],
        '3': ['feature.various'],
        '4': ['feature.outdoor'],
        '5': ['feature.kids'],
        '6': ['feature.remote'],
        '7': ['feature.transportation'],
        '8': ['feature.eco'],
        '9': ['feature.animal'],
        '10': ['feature.favor', 'feature.rule']
    },
    '3': {
        '1': ['descriptions[0][title]', 'descriptions[0][locale]'],
        '2': ['descriptions[0][good_feature]', 'descriptions[0][locale]'],
        '3': ['descriptions[0][good_place]', 'descriptions[0][locale]']
    }
};
export var getStepFields = function (part, step) {
    var _a;
    return ((_a = stepFields[part.toString()]) === null || _a === void 0 ? void 0 : _a[step.toString()]) || [];
};
