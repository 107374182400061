import md5 from 'crypto-js/md5';

import Api from '../api/Api';
import HomeApi from '../api/Home';

import Model from '../models/Model';
import User from '../models/User';

import Utils from '../utils/utils';
import { setLoyaltyFetchingStatus, setUserFetchingStatus } from './loading';
import { fetchingStatuses } from '../reducers/loading';

export const SET_USER = 'SET_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ADD_FACEBOOK_ID = 'ADD_FACEBOOK_ID';
export const ADD_TOUR = 'ADD_TOUR';
export const SET_LOYALTY = 'SET_LOYALTY';

export function setUser(user) {
    return {
        type: SET_USER,
        payload: new User(user)
    };
}

export function logout() {
    return {
        type: USER_LOGOUT
    };
}

export function addTour(tourId) {
    return {
        type: ADD_TOUR,
        payload: tourId
    };
}

export const fetchUser = () => {
    return async (dispatch) => {
        dispatch(setUserFetchingStatus(fetchingStatuses.STARTED));

        try {
            const userResponse = await Api.User.me(true);
            const user = new User(userResponse);

            const hasDoneTrial = await Api.Product.hasDoneTrial();
            user.set('has_done_collection_freetrial', hasDoneTrial);

            if (user.isCollection()) {
                user.set('is_user_eligible_collection', false);
                dispatch({ type: SET_USER, payload: user });
                dispatch(setUserFetchingStatus(fetchingStatuses.FINISHED));

                return user;
            }

            try {
                const homesFlaggedStatus = await HomeApi.getStatusHomeFlag(user.id);
                const isEligible = User.userIsFlaggedEligible(homesFlaggedStatus);
                user.set('is_user_eligible_collection', isEligible);
                sessionStorage.setItem(
                    'user',
                    JSON.stringify({
                        ...JSON.parse(sessionStorage.getItem('user')),
                        is_user_eligible_collection: isEligible
                    })
                );
            } catch (error) {
                console.error('Error fetching home flag status:', error);
            }

            dispatch({ type: SET_USER, payload: user });
            dispatch(setUserFetchingStatus(fetchingStatuses.FINISHED));

            return user;
        } catch (error) {
            console.error('Error fetching user:', error);
            dispatch(setUserFetchingStatus(fetchingStatuses.FINISHED));
        }
    };
};

export function fetchNumberExchangeReady() {
    return () => {
        return Api.Exchange.getNumberExchangeReady();
    };
}

export function updateUser(user) {
    const data = user instanceof Model ? user.toJSON() : user;
    const completionRate = data.completion_rate;
    if (data.hasOwnProperty('password') && !data.password) {
        delete data.password;
    }
    if (_.has(data, 'user_information')) {
        delete data.user_information;
    }
    if (_.has(data, 'profil')) {
        delete data.profil.id;
        delete data.profil.last_connexion_at;
        delete data.profil.response_time;
        delete data.profil.filling_write;
    }
    return (dispatch) =>
        Api.User.patch(user.id, data).then((result) => {
            // update identity cookies
            if (data.email) {
                Utils.createCookie('email', data.email, 365);
            }
            if (data.password) {
                Utils.createCookie('password', md5(data.password).toString(), 365);
            }

            const userResult = new User(result);

            // send tracking
            if (
                completionRate &&
                completionRate < 100 &&
                userResult.get('profil').filling_write &&
                userResult.get('profil').filling_write == 100
            ) {
                window.analytics.track('Profil 100%');
            }

            dispatch({
                type: SET_USER,
                payload: userResult
            });
            return userResult;
        });
}

export function linkToFacebook(facebookId, facebookAuthenticationToken) {
    return (dispatch) =>
        Api.User.linkFacebook(facebookId, facebookAuthenticationToken).then(() => {
            dispatch({
                type: ADD_FACEBOOK_ID,
                payload: facebookId
            });
            return facebookId;
        });
}

export function setTourAsViewed(tourId) {
    return (dispatch) => Api.UserTour.setTourAsViewed(tourId).then(() => dispatch(addTour(tourId)));
}

export function fetchLoyalty(userId) {
    return (dispatch) => {
        dispatch(setLoyaltyFetchingStatus(fetchingStatuses.STARTED));

        Api.Loyalty.fetchBadge(userId)
            .then((loyalty) => {
                dispatch({
                    type: SET_LOYALTY,
                    payload: loyalty
                });

                dispatch(setLoyaltyFetchingStatus(fetchingStatuses.FINISHED));
                return loyalty;
            })
            .catch(() => {
                dispatch(setLoyaltyFetchingStatus(fetchingStatuses.FINISHED));
            });
    };
}
