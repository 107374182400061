var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_TRANSFER_ACHIEVEMENTS, SET_TRANSFER_BALANCE } from '../actions/transfers';
export default function transfers(state, _a) {
    if (state === void 0) { state = null; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case SET_TRANSFER_ACHIEVEMENTS:
            return __assign(__assign({}, state), { achievements: payload });
        case SET_TRANSFER_BALANCE:
            return __assign(__assign({}, state), { balance: payload });
        default:
            return state;
    }
}
