import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import User from '../../models/User';
import Home from '../../models/Home';

import i18n from '../../i18n';
import Switch from '../widget/Switch';
import Tour from '../../utils/Tour';
import Verification from '../popup/Contact/Verification';

export const HomeEditContactSwitchButtons = (props) => {
    const [isShowVerificationModal, setShowVerificationModal] = useState(false);

    const switchText = {
        on: i18n.t('common:yes'),
        off: i18n.t('common:no')
    };

    /**
     * Update User's "verified only" property
     */
    const toggleVerifiedOnly = useCallback(() => {
        const { user, updateUser } = props;

        if (user.get('verified_status') >= User.STATUS_VERIFIED) {
            // Check that the user is verified
            user.set('verified_only', !user.get('verified_only'));
            // Go to next step (or close) the onboarding
            Tour.nextStepIfAtStep('home_verified_only_tour', 0);
        } else {
            setShowVerificationModal(true);
            return false;
        }

        updateUser({
            id: user.id,
            verified_only: user.get('verified_only')
        });
    }, [isShowVerificationModal, props.user.get('verified_status'), props.user.get('verified_only')]);

    /**
     * Update User's "he collection only" property
     */
    const toggleCollectionOnly = useCallback(() => {
        const { user, home, updateHome } = props;

        if (user.get('is_he_collection')) {
            // Check that the user is collection
            home.set('is_search_he_collection_only', !home.get('is_search_he_collection_only'));
        } else {
            return false;
        }

        updateHome({
            id: home.id,
            is_search_he_collection_only: home.get('is_search_he_collection_only')
        });
    }, [
        isShowVerificationModal,
        props.user.get('is_he_collection'),
        props.home.get('is_search_he_collection_only')
    ]);

    const handleCloseVerificationModal = useCallback(() => {
        setShowVerificationModal(false);
    }, [isShowVerificationModal]);

    return (
        <>
            <div className="contact-switch-buttons">
                <p>{i18n.t('home:verified-only-description')}</p>
                <Switch checked={props.verifiedOnly} onChange={toggleVerifiedOnly} text={switchText} />
            </div>
            {props.isCollection && (
                <div className="contact-switch-buttons">
                    <p>{i18n.t('home:prestige-only-description')}</p>
                    <Switch
                        checked={props.collectionOnly}
                        onChange={toggleCollectionOnly}
                        text={switchText}
                    />
                </div>
            )}
            <Modal show={isShowVerificationModal} onHide={handleCloseVerificationModal} bsSize="large">
                <Verification
                    user={props.user}
                    title={i18n.t('common:only_for_verified_members')}
                    onClose={handleCloseVerificationModal}
                />
            </Modal>
        </>
    );
};

HomeEditContactSwitchButtons.propTypes = {
    verifiedOnly: PropTypes.bool,
    isCollection: PropTypes.bool,
    collectionOnly: PropTypes.bool,
    user: PropTypes.instanceOf(User).isRequired,
    home: PropTypes.instanceOf(Home).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    updateUser: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    updateHome: PropTypes.func.isRequired
};

export default HomeEditContactSwitchButtons;
