import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Text, Divider } from '@homeexchange/design';
import { updateUser } from '../../../actions/user';
import { updateHome } from '../../../actions/homes';

import i18n from '../../../i18n';
import Utils from '../../../utils/utils';

import User from '../../../models/User';
import Home from '../../../models/Home';

import Availability from '../../../models/Availability';
import EditPanel from '../../common/EditPanel';
import HomeEditContactSwitchButtons from '../HomeEditContactSwitchButtons';
import HomeEditCalendarSwitchButtons from '../HomeEditCalendarSwitchButtons';

export class HomeEditPanelContact extends EditPanel {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        home: PropTypes.instanceOf(Home),
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        updateUser: PropTypes.func.isRequired,
        updateHome: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired
    };

    static priority = 7;

    static title = i18n.t('home:contact-preferences');

    static checkValidity() {
        return {
            title: true
        };
    }

    render() {
        return (
            <fieldset className="panel panel-default" id={Utils.slugify(this.constructor.title)}>
                <div className="panel-heading">{this.constructor.title}</div>
                <div className="panel-body">
                    <div className="form-group">
                        <HomeEditCalendarSwitchButtons
                            availabilities={this.props.availabilities}
                            home={this.props.home}
                            onChange={this.props.onChange}
                        />
                        <Divider space="small" />
                        <Text size="md" isBold>
                            {i18n.t('home:title-contact-section')}
                        </Text>
                        <HomeEditContactSwitchButtons
                            user={this.props.user}
                            home={this.props.home}
                            verifiedOnly={this.props.user.get('verified_only')}
                            isCollection={
                                this.props.user.get('is_he_collection') &&
                                this.props.home.get('is_he_collection')
                            }
                            collectionOnly={this.props.home.get('is_search_he_collection_only')}
                            updateUser={this.props.updateUser}
                            updateHome={this.props.updateHome}
                        />
                    </div>
                </div>
            </fieldset>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateUser: bindActionCreators(updateUser, dispatch),
    updateHome: bindActionCreators(updateHome, dispatch)
});

export default connect(null, mapDispatchToProps)(HomeEditPanelContact);
