import React from 'react';
import { Flex, Divider, Text, Icon } from '@homeexchange/design';
import styles from './steps.module.scss';
import i18n from '../../../../../i18n';
import clsx from 'clsx';
export function StepsComponent(props) {
    var content = function (step) { return (React.createElement("div", { className: clsx(styles['step'], step.completed ? [styles['completed']] : undefined) },
        React.createElement("img", { className: step.completed ? styles['step-image-completed'] : undefined, src: step.img, alt: step.imageAlt }),
        React.createElement("div", null,
            React.createElement(Flex, { direction: "column", gap: "1" },
                React.createElement(Text, { isBold: true, size: "xl", className: styles['title'] }, step.title),
                step.completed && (React.createElement("span", { className: styles['completed-tag'] },
                    React.createElement(Icon, { name: "check" }),
                    i18n.t('global:global.status.completed')))),
            !step.completed && React.createElement(Text, { dangerouslySetInnerHTML: { __html: step.description } })))); };
    return (React.createElement(Flex, { gap: "0", isFullWidth: true, direction: "column" }, props.steps.map(function (step, index) { return (React.createElement(Flex, { gap: "0", key: index, isFullWidth: true },
        step.onClick && (React.createElement("div", { className: styles['step-container'], onClick: step.onClick }, content(step))),
        !step.onClick && content(step),
        React.createElement(Divider, { space: "small" }))); })));
}
