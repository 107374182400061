var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState, useCallback } from 'react';
import { routesConfig } from '../components/signup-funnel/signup-funnel-ts/create-home-funnel/config';
import { cloneDeep, get, set, toPath } from 'lodash';
export var useHomeForm = function (initialData, homes) {
    var _a = useState(transformApiToForm(initialData) || {}), formData = _a[0], setFormData = _a[1];
    var normalizePath = function (path) {
        return toPath(path).join('.');
    };
    var updateField = useCallback(function (path, value) {
        setFormData(function (prev) {
            var newData = cloneDeep(prev);
            set(newData, normalizePath(path), value);
            return newData;
        });
    }, []);
    var validateStep = useCallback(function (stepId, partId, type) {
        var _a;
        var stepConfig = (_a = routesConfig[partId]) === null || _a === void 0 ? void 0 : _a.steps[stepId];
        if (!stepConfig) {
            return { isValid: true };
        }
        if (stepConfig.required && stepConfig.fieldName) {
            var fieldNames = Array.isArray(stepConfig.fieldName)
                ? stepConfig.fieldName
                : [stepConfig.fieldName];
            var result = validateFields(fieldNames, stepConfig.errorMessage);
            if (!result.isValid)
                return result;
        }
        if (stepConfig.options) {
            for (var _i = 0, _b = stepConfig.options; _i < _b.length; _i++) {
                var option = _b[_i];
                if (option.required && option.fieldName) {
                    var result = validateFields([option.fieldName], option.errorMessage);
                    if (!result.isValid)
                        return result;
                }
            }
        }
        if (stepConfig.validationRules) {
            for (var _c = 0, _d = stepConfig.validationRules; _c < _d.length; _c++) {
                var rule = _d[_c];
                var result = validateFields(rule.fieldNames, rule.errorMessage, rule.rule);
                if (!result.isValid)
                    return result;
            }
        }
        if (type === 'address' && homes) {
            var homesAlreadySaved = homes
                .filter(function (home) { return home.get('location'); })
                .filter(function (home) {
                var _a, _b;
                return home.get('location').latitude === ((_a = formData.center) === null || _a === void 0 ? void 0 : _a[1]) &&
                    home.get('location').longitude === ((_b = formData.center) === null || _b === void 0 ? void 0 : _b[0]) &&
                    home.get('type') === Number(formData.type);
            });
            if (homesAlreadySaved.length > 0) {
                return {
                    isValid: false,
                    duplicateHomes: homesAlreadySaved
                };
            }
        }
        return { isValid: true };
    }, [formData]);
    var validateFields = function (fieldNames, errorMessage, rule) {
        for (var _i = 0, fieldNames_1 = fieldNames; _i < fieldNames_1.length; _i++) {
            var field = fieldNames_1[_i];
            var value = get(formData, normalizePath(field));
            if ((!value || value === '') && rule === undefined) {
                return {
                    isValid: false,
                    errorMessage: errorMessage || 'Ce champ est obligatoire',
                    fieldNames: [field]
                };
            }
            if (rule) {
                if (rule === 'positiveNumber' && value <= 0) {
                    return {
                        isValid: false,
                        errorMessage: errorMessage || 'Ce champ doit être un nombre positif',
                        fieldNames: [field]
                    };
                }
                if (rule === 'requiredOneOf') {
                    var hasAtLeastOneValue = fieldNames.some(function (field) {
                        var value = get(formData, normalizePath(field));
                        return value && value !== '';
                    });
                    if (!hasAtLeastOneValue) {
                        return {
                            isValid: false,
                            fieldNames: fieldNames
                        };
                    }
                }
            }
        }
        return { isValid: true };
    };
    return {
        formData: formData,
        updateField: updateField,
        validateStep: validateStep
    };
};
export var transformApiToForm = function (apiData) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!(apiData === null || apiData === void 0 ? void 0 : apiData.attributes)) {
        return {};
    }
    var attributes = apiData.attributes;
    var formData = {
        type: attributes.type,
        shared_part: attributes.shared_part
    };
    if (attributes.detail) {
        var defaultDetail = {
            residence_type: attributes.detail.residence_type,
            size: attributes.detail.size || 0,
            size_unit: attributes.detail.size_unit || '',
            bedroom_nb: attributes.detail.bedroom_nb || 0,
            bathroom_nb: attributes.detail.bathroom_nb || 0,
            single_bed: attributes.detail.single_bed || 0,
            single_bed_up: attributes.detail.single_bed_up || 0,
            double_bed: attributes.detail.double_bed || 0,
            big_double_bed: attributes.detail.big_double_bed || 0,
            double_bed_up: attributes.detail.double_bed_up || 0,
            children_bed: attributes.detail.children_bed || 0,
            children_bed_up: attributes.detail.children_bed_up || 0,
            baby_bed: attributes.detail.baby_bed || 0,
            baby_bed_up: attributes.detail.baby_bed_up || 0,
            location_type: attributes.detail.location_type || ''
        };
        formData.detail = defaultDetail;
    }
    var hasAddress = attributes.address;
    var hasLocation = ((_a = attributes.location) === null || _a === void 0 ? void 0 : _a.latitude) && ((_b = attributes.location) === null || _b === void 0 ? void 0 : _b.longitude);
    if (hasAddress || hasLocation) {
        if (hasAddress) {
            formData.address = attributes.address;
        }
        if (hasLocation) {
            formData.coordinates = "".concat(((_c = attributes.location) === null || _c === void 0 ? void 0 : _c.latitude) || '', ", ").concat(((_d = attributes.location) === null || _d === void 0 ? void 0 : _d.longitude) || '');
        }
        formData.center = (_e = attributes.location) === null || _e === void 0 ? void 0 : _e.center;
    }
    var hasFeatures = attributes.feature;
    if (hasFeatures) {
        formData.feature = __assign({}, attributes.feature);
    }
    if ((_f = attributes.descriptions) === null || _f === void 0 ? void 0 : _f.length) {
        formData.descriptions = __assign(__assign({}, attributes.descriptions), { good_feature: attributes.descriptions.good_feature || '', good_place: attributes.descriptions.good_place || '' });
    }
    if ((_g = attributes.images) === null || _g === void 0 ? void 0 : _g.length) {
        formData.images = attributes.images;
    }
    formData.is_accessible_for_prm = (_h = attributes.is_accessible_for_prm) !== null && _h !== void 0 ? _h : false;
    if (attributes.legal_registration_required) {
        formData.legal_registration_required = attributes.legal_registration_required;
    }
    if (attributes.legal_registration_homes) {
        formData.legal_registration_homes = attributes.legal_registration_homes;
    }
    if (attributes.translated_admins) {
        formData.translated_admins = attributes.translated_admins;
    }
    return formData;
};
