import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../../i18n';
import Utils from '../../../utils/utils';

import Home from '../../../models/Home';

import CompletionIndicator from '../../common/CompletionIndicator';
import EditPanel from '../../common/EditPanel';
import InputNumber from '../../form/InputNumber';
import InputOption from '../../form/InputOption';
import InputBedList from './InputBedList';

export default class HomeEditPanelHouseAndBeds extends EditPanel {
    static propTypes = {
        home: PropTypes.instanceOf(Home).isRequired,
        onChange: PropTypes.func.isRequired
    };

    static priority = 5;

    static title = i18n.t('home:housing_and_beds');

    constructor(props) {
        super(props);
        this.onSizeChange = this.onSizeChange.bind(this);
    }

    static checkValidity(props) {
        return {
            type: _.contains([Home.TYPE_HOME, Home.TYPE_APARTMENT], props.home.get('type')),
            residence_type: _.contains(
                [Home.RESIDENCE_PRIMARY, Home.RESIDENCE_SECONDARY],
                props.home.get('detail').residence_type
            ),
            beds:
                props.home.get('detail').single_bed +
                    props.home.get('detail').double_bed +
                    props.home.get('detail').big_double_bed +
                    props.home.get('detail').single_bed_up +
                    props.home.get('detail').double_bed_up >
                0,
            bedroom_nb:
                _.isNumber(props.home.get('detail').bedroom_nb) && props.home.get('detail').bedroom_nb >= 0,
            bathroom_nb:
                _.isNumber(props.home.get('detail').bathroom_nb) && props.home.get('detail').bathroom_nb > 0,
            size: _.isNumber(props.home.get('detail').size) && props.home.get('detail').size > 0
        };
    }

    onSizeChange(e) {
        this.props.home.get('detail').localized_size = e.target.value;
        this.props.onChange(e);
    }

    static bedroomNbTransformer(value) {
        return value === 0 ? i18n.t('home:studio') : value;
    }

    render() {
        const { home, onChange } = this.props;
        return (
            <fieldset className="panel panel-default" id={Utils.slugify(this.constructor.title)}>
                <div className="panel-heading">{i18n.t('home:housing_and_beds')}</div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className={classNames('form-group', {
                                    'has-error': this.hasError('type')
                                })}
                            >
                                <div className="label-row">
                                    <label htmlFor="type">{i18n.t('home:home_type')}</label>
                                    <CompletionIndicator object={home} fieldNames={['type']} page="home" />
                                </div>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <InputOption
                                            type="radio"
                                            label={i18n.t('home:type-home')}
                                            name="type"
                                            id="type-home"
                                            trackingData="home_type_changed"
                                            value={Home.TYPE_HOME}
                                            checked={home.get('type') == Home.TYPE_HOME}
                                            onChange={onChange}
                                            required
                                            icon="house"
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <InputOption
                                            type="radio"
                                            label={i18n.t('home:type-flat')}
                                            name="type"
                                            id="type-apartment"
                                            trackingData="home_type_changed"
                                            value={Home.TYPE_APARTMENT}
                                            checked={home.get('type') == Home.TYPE_APARTMENT}
                                            onChange={onChange}
                                            required
                                            icon="flat"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="radio">
                                            <label htmlFor="shared-part-1">
                                                <div className="radio-button-container">
                                                    <input
                                                        type="radio"
                                                        name="shared_part"
                                                        id="shared-part-1"
                                                        value={Home.SHARED_PART_WHOLE_UNIT}
                                                        checked={
                                                            home.get('shared_part') ===
                                                            Home.SHARED_PART_WHOLE_UNIT
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <div className="radio-button"></div>
                                                    <span className="shared-part">
                                                        {i18n.t('home:shared-type.whole-unit')}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label htmlFor="shared-part-2">
                                                <div className="radio-button-container">
                                                    <input
                                                        type="radio"
                                                        name="shared_part"
                                                        id="shared-part-2"
                                                        value={Home.SHARED_PART_PRIVATE_ROOM}
                                                        checked={
                                                            home.get('shared_part') ===
                                                            Home.SHARED_PART_PRIVATE_ROOM
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <div className="radio-button"></div>
                                                    <span className="shared-part">
                                                        {i18n.t('home:shared-type.private-room')}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className={classNames('form-group', {
                                    'has-error': this.hasError('residence_type')
                                })}
                            >
                                <div className="label-row">
                                    <label htmlFor="residence_type">{i18n.t('home:residence_type')}</label>
                                    <CompletionIndicator
                                        object={home}
                                        fieldNames={['residence_type']}
                                        page="home"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <InputOption
                                            type="radio"
                                            label={i18n.t('home:residence_type_primary')}
                                            name="detail.residence_type"
                                            id="residence-primary"
                                            trackingData="residence_type_changed"
                                            value={Home.RESIDENCE_PRIMARY}
                                            checked={
                                                home.get('detail').residence_type == Home.RESIDENCE_PRIMARY
                                            }
                                            onChange={onChange}
                                            required
                                            icon="primary-home"
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <InputOption
                                            type="radio"
                                            label={i18n.t('home:residence_type_secondary')}
                                            name="detail.residence_type"
                                            id="residence-secondary"
                                            trackingData="residence_type_changed"
                                            value={Home.RESIDENCE_SECONDARY}
                                            checked={
                                                home.get('detail').residence_type == Home.RESIDENCE_SECONDARY
                                            }
                                            onChange={onChange}
                                            required
                                            icon="secondary-home"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div
                                className={classNames('form-group', {
                                    'has-error': this.hasError('beds')
                                })}
                            >
                                <div className="label-row">
                                    <label htmlFor="beds">
                                        {i18n.t('home:beds')} (
                                        {i18n.t('home:capacity', {
                                            count: home.getCapacity()
                                        })}
                                        )
                                    </label>
                                    <CompletionIndicator object={home} fieldNames={['beds']} page="home" />
                                </div>
                                <InputBedList home={home} onChange={onChange} />
                                {this.hasError('beds') && (
                                    <span className="help-block">{i18n.t('home:beds_invalid')}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className={classNames('form-group')}>
                                <div className="label-row">
                                    <label htmlFor="bedrooms_and_bathrooms">
                                        {i18n.t('home:bedrooms_and_bathrooms')}
                                    </label>
                                    <CompletionIndicator
                                        object={home}
                                        fieldNames={['bedroom_nb', 'bathroom_nb']}
                                        page="home"
                                    />
                                </div>
                                <div className="form-input-number">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="bedroom_nb"
                                            className="col-xs-6 control-label form-label"
                                        >
                                            <i className="icon icon-bedroom icon-large"></i>
                                            <span>{i18n.t('home:bedroom')}</span>
                                        </label>
                                        <div className="input-number-container col-xs-6">
                                            <InputNumber
                                                id="bedroom_nb"
                                                trackingData="rooms_number_changed"
                                                name="detail.bedroom_nb"
                                                value={home.get('detail').bedroom_nb || '0'}
                                                onChange={onChange}
                                                min={0}
                                                max={15}
                                                valueTransformer={this.constructor.bedroomNbTransformer}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="bathroom_nb"
                                            className={`col-xs-6 control-label form-label ${
                                                this.hasError('bathroom_nb') ? 'has-error-bathroom-nb' : ''
                                            }`}
                                        >
                                            <i className="icon icon-bathroom icon-large"></i>
                                            <span>{i18n.t('home:bathroom')}</span>
                                        </label>
                                        <div className="input-number-container col-xs-6">
                                            <InputNumber
                                                id="bathroom_nb"
                                                trackingData="bathrooms_number_changed"
                                                name="detail.bathroom_nb"
                                                value={home.get('detail').bathroom_nb || '0'}
                                                onChange={onChange}
                                                min={0}
                                                max={15}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={classNames('form-group', {
                            'has-error': this.hasError('size')
                        })}
                    >
                        <div className="label-row">
                            <label htmlFor="size">{i18n.t('home:size')}</label>
                            <CompletionIndicator object={home} fieldNames={['size']} page="home" />
                        </div>
                        <p className="informative-metric">{i18n.t('home:informative_metric')}</p>
                        <div className="input-group">
                            <input
                                className="form-control"
                                type="number"
                                pattern="[0-9]*"
                                id="size"
                                data-track-edition="surface_changed"
                                name="detail.size"
                                value={home.get('detail').localized_size || ''}
                                onChange={this.onSizeChange}
                                min="0"
                            />
                            <select
                                name="detail.size_unit"
                                id="metric"
                                className="form-control"
                                value={home.get('detail').size_unit || ''}
                                onChange={onChange}
                                required
                            >
                                <option value={Home.METRIC_M2}>m&sup2;</option>
                                <option value={Home.METRIC_SQFT}>ft&sup2;</option>
                            </select>
                        </div>
                    </div>
                </div>
            </fieldset>
        );
    }
}
