import { setConversationsFetchConfig } from '@homeexchange/conversations';
import { HeFetch } from '@homeexchange/he-fetch';
var heFetch;
export var setHeFetchConfig = function () {
    var heFetchOptions = {
        headers: {
            he_web_version: process.env.VERSION
        }
    };
    heFetch = new HeFetch(process.env.URL_BFF, heFetchOptions);
    setConversationsFetchConfig(process.env.URL_BFF, heFetchOptions);
};
export { heFetch };
