import React from 'react';
import { createRoot } from 'react-dom/client';

export const replaceElement = function (element, target) {
    return new Promise((resolve) => {
        const temp = document.createElement('div');
        const clone = React.cloneElement(element, { ref: resolve });
        createRoot(temp).render(clone);
        setTimeout(() => {
            target.parentNode.replaceChild(temp.firstChild, target);
            resolve(temp.firstChild);
        }, 1000);
    });
};

export const insertElementBefore = function (element, target) {
    return new Promise((resolve) => {
        const temp = document.createElement('div');
        const clone = React.cloneElement(element, { ref: resolve });
        createRoot(temp).render(clone);
        setTimeout(() => {
            target.parentNode.insertBefore(temp.firstChild, target);
            resolve(temp.firstChild);
        }, 1000);
    });
};

export const appendElementBefore = function (element, target) {
    return new Promise((resolve) => {
        const temp = document.createElement('div');
        createRoot(temp).render(React.cloneElement(element, { ref: resolve }));
        setTimeout(() => {
            target.insertBefore(temp.firstChild, target.firstChild);
            resolve(temp.firstChild);
        }, 1000);
    });
};

export const stringToJSX = (htmlString) => <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
