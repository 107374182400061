import SHA256 from 'crypto-js/sha256';
import User from '../../models/User';

export default function useGTM(user, paymentMethods) {
    const reset = () => {
        window.dataLayer.push({ user_data: null, event_data: null, page_data: null });
    };

    const fetchUserData = () => {
        const loginStatus = user ? 'logged' : 'anonym';
        const userData = { login_status: loginStatus };

        if (user) {
            const primaryHome = user.getPrimaryHome();

            userData.uid = user.get('id');
            userData.email = SHA256(user.get('email')).toString();
            userData.profile = user.getStatus();
            userData.verified_phone = user.isPhoneVerified();
            userData.verified_user = user.isVerified();
            userData.verified_home = user.hasVerifiedHome();
            userData.eligible_collection = user.isUserEligibleCollection();
            userData.level_home_completion_col = user.isCollection()
                ? user.getBestHomeRate(User.COLLECTION)
                : null;
            userData.level_home_completion_reg = user.getBestHomeRate(User.REGULAR);
            userData.country = primaryHome?.country();
            userData.subscription_renewal_status = user.isAutoRenew() ? 'auto_renew' : 'manual';
            userData.sponsored_user = user.get('has_godfather');
            userData.loyalty_badge = parseInt(user.getLoyaltyLevel(), 10);
            // We don't have this information, I create a ticket (https://guesttoguest.atlassian.net/browse/MBS-472)
            userData.number_subscription_purchase = null;
            userData.number_exchanges = user.countExchanges();
            userData.number_homes = user.countHomes();
            userData.card_status = 'no_card';
            userData.has_done_collection_freetrial = user.get('has_done_collection_freetrial');
        }

        if (paymentMethods && paymentMethods.stripe && paymentMethods.stripe.sources) {
            const defaultPayment = paymentMethods.stripe.sources.find(
                (payment) => paymentMethods.stripe.default_source === payment.get('id')
            );
            if (defaultPayment && defaultPayment.isExpired) {
                userData.card_status = defaultPayment.isExpired() ? 'card_expired' : 'card_valid';
            }
        }

        return userData;
    };

    const fetchEventData = (text, method) => {
        const eventData = { collection: false, text, method };

        eventData.collection =
            user?.getStatus() === User.COLLECTION_STATUS || location.pathname.includes('collection');

        return eventData;
    };

    const fetchPageData = () => ({
        location: location.href,
        referrer: document.referrer,
        title: document.title,
        language: navigator.language
    });

    const fetchPageView = (text = null, method = null) => {
        reset();

        return {
            user_data: fetchUserData(),
            event_data: fetchEventData(text, method),
            page_data: fetchPageData()
        };
    };

    const fetchUserCreated = (text = null, method = null) => {
        reset();

        return {
            user_data: {
                uid: user.get('id'),
                email: SHA256(user.get('email')).toString(),
                profile: 'lead',
                sponsored_user: user.get('has_godfather'),
                club_invitation: user.hasGroup()
            },
            event_data: fetchEventData(text, method),
            page_data: fetchPageData()
        };
    };

    const fetchAccount = (text = null, method = null) => {
        reset();

        return {
            user_data: fetchUserData(),
            event_data: fetchEventData(text, method),
            page_data: fetchPageData()
        };
    };

    const fetchHomeCreation = () => {
        // todo
    };

    const fetchFunnel = () => {
        // todo
    };

    const fetchSearchTrackingData = (calendar = {}) => {
        const { date_ranges: dateRange, flexibility, duration, last_minute: lastMinute } = calendar;

        const data = {
            searchParam: null,
            searchValue: null
        };

        if (dateRange && dateRange.length > 0) {
            if (flexibility) {
                data.searchParam = 'fixed_dates';
                switch (flexibility) {
                    case 1:
                        data.searchValue = '+/-1_day';
                        break;
                    case 3:
                        data.searchValue = '+/-3_days';
                        break;
                    case 7:
                        data.searchValue = '+/-7_days';
                        break;
                    default:
                        break;
                }
            } else if (lastMinute) {
                data.searchParam = 'last_minute';
            } else if (duration?.kind) {
                data.searchParam = 'flexible_dates';
                switch (duration.kind) {
                    case 'week-end':
                        data.searchValue = 'flexible_dates_weekend';
                        break;
                    case 'one-week':
                        data.searchValue = 'flexible_dates_1_week';
                        break;
                    case 'two-weeks':
                        data.searchValue = 'flexible_dates_2_weeks';
                        break;
                    case 'one-month':
                        data.searchValue = 'flexible_dates_1_month';
                        break;
                    default:
                        break;
                }
            } else {
                data.searchParam = 'fixed_dates';
                data.searchValue = 'exact_dates';
            }
        }

        return data;
    };

    const fetchFavorites = () => {
        // todo
    };

    const fetchMessaging = () => {
        // todo
    };

    const fetchExchanges = () => {
        // todo
    };

    const fetchNavigation = (action, area = null, text = null) => {
        reset();

        const eventData = fetchEventData();
        const page = document.querySelector('.page');
        const pageArea = page ? page.getAttribute('data-area') : null;

        return {
            user_data: fetchUserData(),
            event_data: {
                action,
                collection: eventData.collection,
                area: area ? `${pageArea}_${area}` : pageArea,
                text
            },
            page_data: fetchPageData()
        };
    };

    const fetchLead = () => {
        // todo
    };

    const fetchPurchase = (source) => {
        const userData = fetchUserData();
        const eventData = fetchEventData();
        const pageData = fetchPageData();

        const isGuestPointsItem = (item) => item.name === 'guest_points';
        const getGuestPointsItem = () => source.product.find((item) => isGuestPointsItem(item));

        const isTrial = new URLSearchParams(window.location.search).get('start-trial') === 'true';

        const items = source.product.map((product) => ({
            item_name:
                !isGuestPointsItem(product) && eventData.collection
                    ? isTrial
                        ? 'collection_freetrial'
                        : 'collection_subscription'
                    : product.name,
            price: product.price,
            quantity: product.quantity,
            discount: !isGuestPointsItem(product) && source.coupon?.discount ? source.coupon.discount : 0
        }));

        const getPrice = () => {
            const guestPointsItem = getGuestPointsItem();

            if (source.coupon?.newPrice) {
                if (guestPointsItem) {
                    return guestPointsItem.price + source.coupon.newPrice;
                }

                return source.coupon.newPrice;
            }

            return source.price;
        };

        const ecommerce = {
            currency: source.currency,
            value: getPrice(),
            coupon: source.coupon?.couponName ? source.coupon.couponName : 'no_coupon',
            payment_type: source.is3xPayment ? 'card_3x' : source.type,
            payment_status: source.payment_status,
            transaction_id: source.order?.id ?? null,
            items
        };

        return {
            user_data: userData,
            event_data: eventData,
            page_data: pageData,
            ecommerce
        };
    };

    return {
        fetchUserData,
        fetchPageView,
        fetchUserCreated,
        fetchAccount,
        fetchHomeCreation,
        fetchFunnel,
        fetchSearchTrackingData,
        fetchFavorites,
        fetchMessaging,
        fetchExchanges,
        fetchNavigation,
        fetchLead,
        fetchPurchase
    };
}
