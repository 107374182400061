var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button, Flex, Title } from '@homeexchange/design';
import { storyManagerConfig, commonOptions, storiesListOptions, storyReaderOptions } from '../../../widgets/in-app-story/in-app-story';
import i18n from '../../../i18n';
import { getRetro } from '../../../api/Retro';
import Analytics from '../../../utils/analytics';
import useGTM from '../../analytics/useGTM';
import { NAVIGATION_ACTION } from '../../analytics/analytics-constants';
var Retro = function (_a) {
    var user = _a.user, hasAccessToken = _a.hasAccessToken, showRetroOnLoad = _a.showRetroOnLoad;
    var _b = useState(), tags = _b[0], setTags = _b[1];
    var _c = useState(), placeholders = _c[0], setPlaceholders = _c[1];
    var _d = useState(), storyManager = _d[0], setStoryManager = _d[1];
    var _e = useState(), appearanceManager = _e[0], setAppearanceManager = _e[1];
    var fetchNavigation = useGTM(user).fetchNavigation;
    var handleOnShowStory = function () {
        if (storyManager) {
            storyManager.setUserId("".concat(user.get('id'), "-").concat(uuidv4()));
            storyManager.showOnboardingStories(appearanceManager, { feed: 'default' });
            Analytics.trackGTM('button', fetchNavigation(NAVIGATION_ACTION.CLICK, showRetroOnLoad ? null : 'retro', 'retro-2024'));
        }
    };
    var redirectToHomePage = function () {
        window.location.href = "".concat(location.origin).concat(i18n.t('url:homepage'), "?redirectTo=").concat(location.href);
    };
    var redirectToDashboard = function () {
        window.location.href = "".concat(location.origin).concat(i18n.t('url:homepage'), "dashboard");
    };
    useEffect(function () {
        if (user) {
            var fetchRetro = function () { return __awaiter(void 0, void 0, void 0, function () {
                var data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, getRetro(user.get('id'))];
                        case 1:
                            data = _a.sent();
                            setTags(data.tags);
                            setPlaceholders(data.placeholders);
                            Analytics.trackGTM('banner', fetchNavigation(NAVIGATION_ACTION.VIEW, showRetroOnLoad ? null : 'retro', 'retro-2024'));
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            console.error(error_1);
                            if (showRetroOnLoad) {
                                redirectToDashboard();
                            }
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            fetchRetro();
        }
    }, [user]);
    useEffect(function () {
        if (user && tags) {
            var storyManager_1 = new window.IAS.StoryManager(__assign({ tags: tags, placeholders: placeholders }, storyManagerConfig));
            var appearanceManager_1 = new window.IAS.AppearanceManager();
            appearanceManager_1
                .setCommonOptions(commonOptions)
                .setStoriesListOptions(storiesListOptions)
                .setStoryReaderOptions(storyReaderOptions);
            setStoryManager(storyManager_1);
            setAppearanceManager(appearanceManager_1);
        }
    }, [tags]);
    useEffect(function () {
        if (storyManager && appearanceManager) {
            new storyManager.StoriesList('#stories_widget', appearanceManager, { feed: 'default' });
            if (showRetroOnLoad) {
                if (!tags) {
                    redirectToHomePage();
                }
                storyManager.onStoryReaderClosed = redirectToDashboard;
                handleOnShowStory();
            }
        }
    }, [storyManager, appearanceManager]);
    useEffect(function () {
        if (showRetroOnLoad && !hasAccessToken) {
            redirectToHomePage();
        }
    }, [showRetroOnLoad, hasAccessToken]);
    if (user && user.get('is_he_collection')) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "stories_widget", style: { display: 'none' } }),
        user && tags && !showRetroOnLoad && (React.createElement(Flex, { className: "retro", alignItems: "center", justifyContent: "center" },
            React.createElement(Title, { className: "retro-title" }, i18n.t('dashboard:retro.title', { name: user.get('first_name') })),
            React.createElement(Button, { className: "retro-button", onClick: handleOnShowStory }, i18n.t('dashboard:retro.button'))))));
};
var mapStateToProps = function (state) { return ({
    user: state.user,
    hasAccessToken: Boolean(state.auth.accessToken)
}); };
export default connect(mapStateToProps)(Retro);
