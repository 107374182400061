import Api from '../api/Api';
import Utils from '../utils/utils';

import Model from '../models/Model';
import Home from '../models/Home';

export const SET_HOMES = 'SET_HOMES';
export const ADD_HOME = 'ADD_HOME';
export const UPDATE_HOME = 'UPDATE_HOME';
export const SAVE_HOME = 'SAVE_HOME';
export const REMOVE_HOME = 'REMOVE_HOME';
export const REFRESH_HOMES = 'REFRESH_HOMES';

export function setHomes(homes) {
    return {
        type: SET_HOMES,
        payload: homes.map((home) => new Home(home))
    };
}

export function refreshHomes() {
    return {
        type: REFRESH_HOMES,
        payload: []
    };
}

export function addHome(home) {
    return {
        type: ADD_HOME,
        payload: new Home(home)
    };
}

export function setHome(home) {
    return {
        type: UPDATE_HOME,
        payload: new Home(home)
    };
}

export function fetchHomes() {
    return (dispatch) =>
        Api.Home.me().then((result) => {
            const homes = result.map((home) => new Home(home));
            dispatch({
                type: SET_HOMES,
                payload: homes
            });
            return homes;
        });
}

export function createHome(home) {
    let data = home instanceof Model ? home.toJSON() : home;
    data = Utils.featureArrayToFlag(data);
    data.provider = 'Jawg';
    return (dispatch) =>
        Api.Home.post(data).then((result) => {
            const homeResult = new Home(result);
            dispatch(addHome(homeResult));
            return homeResult;
        });
}

export function updateHome(home, refreshGP, additionalData = {}) {
    let data = home instanceof Model ? home.toJSON() : home;
    data = { ...data, ...additionalData };
    return (dispatch) => {
        // TODO: API has trouble handle this property, return the right type (array)
        if (_.has(data, 'feature') && _.has(data.feature, 'others')) {
            delete data.feature.others;
        }
        data = Utils.featureArrayToFlag(data);

        // API will throw an error if sending "localized_size"
        if (_.has(data, 'detail') && _.has(data.detail, 'localized_size')) {
            delete data.detail.localized_size;
        }

        return Api.Home.patch(home.id, data, null, refreshGP).then((result) => {
            const homeResult = new Home(result);
            dispatch({
                type: SAVE_HOME,
                payload: homeResult
            });
            return homeResult;
        });
    };
}

export function removeHome(home) {
    const data = home instanceof Model ? home.toJSON() : home;
    return (dispatch) =>
        Api.Home.delete(home.id).then(() => {
            dispatch({
                type: REMOVE_HOME,
                payload: new Home(data)
            });
        });
}
