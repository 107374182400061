import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';

const basename = document.location.pathname.match(/^(\/\w{2})\/.+$/);

const history = createBrowserHistory({
    basename: basename ? basename[1] : '/'
});

history.listen(({ location }) => {
    try {
        window.analytics.track('page-viewed', {
            url: location.pathname
        });
    } catch (err) {
        Sentry.captureException(err);
    }
});

export const redirect = (path) => {
    document.location.assign(history.createHref(path));
};

export default history;
