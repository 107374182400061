var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RECEIVE_STAY_REQUEST_CONFIG, RECEIVE_STAY_REQUESTS_FOR_CONVERSATION_ID, RECEIVE_UPDATE_STAY_REQUEST_TO_RECIPROCAL } from '../actions/stayRequest';
var initialStore = {
    stayRequestsByConversationId: {},
    config: {
        nbDaysPreapprovalExpiration: 4
    }
};
var stayRequest = function (state, action) {
    var _a;
    if (state === void 0) { state = initialStore; }
    switch (action.type) {
        case RECEIVE_STAY_REQUEST_CONFIG:
            return Object.assign({}, state, __assign(__assign({}, state), { config: {
                    nbDaysPreapprovalExpiration: action.nbDaysPreapprobationExpiration
                } }));
        case RECEIVE_STAY_REQUESTS_FOR_CONVERSATION_ID:
            return Object.assign({}, state, __assign(__assign({}, state), { stayRequestsByConversationId: __assign(__assign({}, state.stayRequestsByConversationId), (_a = {}, _a[action.id] = action.stayRequests, _a)) }));
        case RECEIVE_UPDATE_STAY_REQUEST_TO_RECIPROCAL:
            var stayRequests = Object.entries(state.stayRequestsByConversationId).reduce(function (cumul, _a) {
                var _b;
                var key = _a[0], value = _a[1];
                if (parseInt(key) === action.conversationId) {
                    return cumul;
                }
                return __assign(__assign({}, cumul), (_b = {}, _b[key] = value, _b));
            }, {});
            return Object.assign({}, state, __assign(__assign({}, state), { stayRequestsByConversationId: stayRequests }));
        default:
            return state;
    }
};
export default stayRequest;
