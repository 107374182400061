import Common from './Common';

export default class Subscription {
    static getSubscription() {
        const params = Common.params({
            url: `/v1/subscription`,
            method: 'get'
        });
        return Common.request(params);
    }

    static updateSubscription(data) {
        const params = Common.params({
            url: `/v1/subscription`,
            method: 'patch',
            data
        });
        return Common.request(params);
    }

    static paySubscription(data, hasCollectionProduct, startTrial = false) {
        if (hasCollectionProduct) {
            data.product = 'collection';
        }

        data.is_trial = startTrial;

        const params = Common.params({
            url: `/v1/subscription`,
            method: 'post',
            data
        });
        return Common.request(params);
    }

    static paySubscription3x(data) {
        const params = Common.params({
            url: `/v1/subscription/3x`,
            method: 'post',
            data
        });
        return Common.request(params);
    }

    static paySubscriptionWithToken(stripeToken, promoCode, hasCollectionProduct, startTrial = false) {
        return Subscription.paySubscription(
            {
                stripeToken,
                coupon_name: promoCode
            },
            hasCollectionProduct,
            startTrial
        );
    }

    static paySubscriptionWithToken3x(stripeToken, promoCode, hasCollectionProduct, startTrial = false) {
        return Subscription.paySubscription3x(
            {
                stripeToken,
                coupon_name: promoCode
            },
            hasCollectionProduct,
            startTrial
        );
    }

    static paySubscriptionWithPaypal(
        braintreeNonce,
        braintreeDeviceData,
        promoCode,
        hasCollectionProduct,
        startTrial = false
    ) {
        return Subscription.paySubscription(
            {
                braintreeNonce,
                braintreeDeviceData,
                coupon_name: promoCode
            },
            hasCollectionProduct,
            startTrial
        );
    }

    static paySubscriptionWithExistingCard(cardId, promoCode, hasCollectionProduct, startTrial = false) {
        return Subscription.paySubscription(
            {
                card_id: cardId,
                coupon_name: promoCode
            },
            hasCollectionProduct,
            startTrial
        );
    }

    static paySubscriptionWithExistingCard3x(cardId, promoCode, hasCollectionProduct, startTrial = false) {
        return Subscription.paySubscription(
            {
                card_id: cardId,
                coupon_name: promoCode
            },
            hasCollectionProduct,
            startTrial
        );
    }

    static paySubscriptionWithExistingPaypalAccount(id, promoCode, hasCollectionProduct, startTrial = false) {
        return Subscription.paySubscription(
            {
                braintreeId: id,
                coupon_name: promoCode
            },
            hasCollectionProduct,
            startTrial
        );
    }

    static cancelFreeTrial() {
        const params = Common.params({
            url: `/v1/subscription`,
            method: 'patch',
            data: {
                is_trial_end: true
            }
        });
        return Common.request(params);
    }
}
