import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import Alert from 'react-bootstrap/lib/Alert';

import { currencies, withCurrency } from '../currency';
import i18n from '../../i18n';
import ProductApi from '../../api/Product';
import User from '../../models/User';
import HomeApi from '../../api/Home';
import { CurrencyFormatter } from '../../utils/Intl';
import Subscription from '../../api/Subscription';
import { fetchNotifications } from '../../actions/notifications';
/**
 * @todo displayed notification should be manager in state instead of render method
 */
export class Headband extends React.PureComponent {
    static propTypes = {
        currency: PropTypes.string,
        cookies: PropTypes.instanceOf(Cookies).isRequired,
        notifications: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                params: PropTypes.object
            })
        ),
        refreshFiltersLayout: PropTypes.func,
        fetchNotifications: PropTypes.func.isRequired
    };

    static defaultProps = {
        notifications: []
    };

    constructor(props) {
        super(props);

        this.state = {
            notification: null,
            oldPrices: {},
            newPrices: {},
            nextSubscription: null,
            isEligible: false,
            statusHomes: null
        };

        this.handleClose = this.handleClose.bind(this);
        this.getPrices = this.getPrices.bind(this);
    }

    componentDidMount() {
        this.props.fetchNotifications();
        ProductApi.getOldPrice()
            .then((response) => response.json())
            .then((data) => this.setState({ oldPrices: data }));
        ProductApi.getNewPrice()
            .then((response) => response.json())
            .then((data) => this.setState({ newPrices: data }));

        Subscription.getSubscription().then((response) =>
            this.setState({ nextSubscription: response?.next_product })
        );
        this.getStatusHomeFlag();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.notification && prevProps.refreshFiltersLayout) {
            this.props.refreshFiltersLayout();
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const { cookies, notifications } = nextProps;
        const cookie = cookies.get('headband') || {};
        // filter notifications closed and return the first one
        const notification = notifications.filter((notif) => {
            if (cookie[notif.type]) {
                // headband type is close since more than 3 days
                if (moment().diff(cookie[notif.type], 'days') > 3) {
                    // remove headband type from cookie to display it
                    delete cookie[notif.type];
                    // update cookie
                    cookies.set('headband', cookie, {
                        maxAge: 30 * 24 * 60 * 60,
                        path: '/'
                    });
                } else {
                    // else don't display it
                    return false;
                }
            }
            return true;
        })[0];

        return { notification };
    }

    getLink(notification) {
        switch (notification.type) {
            case 'exchange-preapproved-for-guest':
            case 'exchange-preapproved-for-host':
                return i18n.t('exchange:conversation.url', {
                    id: notification.params.conversation_id
                });
            case 'exchange-to-rate':
                return i18n.t('url:exchange.rating', {
                    id: notification.params.conversation_id
                });
            case 'not-verified':
                return i18n.t('user:user.verif.url');
            case 'limited-price-offer':
            case 'subscription-is-not-active':
            case 'subscription-expired-less-than-two-days':
            case 'price-increase-information':
                return i18n.t('url:subscription_payment');
            case 'subscription-will-expire-soon':
            case 'subscription-will-expire-soon-collection':
                return i18n.t('url:subscription-renewal');
            case 'home-qualification-information':
                return i18n.t('url:home_edit', {
                    id: notification.params.home_id
                });
            default:
                return false;
        }
    }

    getPrices(currency) {
        const oldAndNewPrice = [];
        const { oldPrices, newPrices } = this.state;

        if (oldPrices[currency] && newPrices[currency]) {
            if (currencies[currency]) {
                oldAndNewPrice.push(
                    `${oldPrices[currency].YEAR_1ST}${currencies[currency]}`,
                    `${newPrices[currency].LIMITED_PRICE_OFFER}${currencies[currency]}`,
                    newPrices[currency].COUPON_NAME
                );
            }
        } else {
            oldAndNewPrice.push(
                `${oldPrices.EUR.YEAR_1ST}${currencies.EUR}`,
                `${newPrices.EUR.LIMITED_PRICE_OFFER}${currencies.EUR}`,
                newPrices[currency].COUPON_NAME
            );
        }

        return oldAndNewPrice;
    }

    getStatusHomeFlag() {
        HomeApi.getStatusHomeFlag()
            .then((statusHomes) => {
                this.setState({ ...(statusHomes.length === 0 ? { statusHomes: null } : { statusHomes }) });
            })
            .catch((e) => {
                console.error(e);
            });
    }

    handleClose(e) {
        // store notification type with closing date in cookie
        const type = e.currentTarget.getAttribute('data-type');
        const cookie = this.props.cookies.get('headband') || {};
        cookie[type] = new Date().toUTCString();
        this.props.cookies.set('headband', cookie, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/'
        });
        this.props.refreshFiltersLayout();
        // update render
        this.forceUpdate();
    }

    render() {
        const { currency } = this.props;
        const { notification, nextSubscription, statusHomes } = this.state;
        const isEligible = User.userIsFlaggedEligible(statusHomes);

        // display only one notification in headband
        if (!notification) {
            return false;
        }

        if (notification.params.end_on) {
            notification.params.endOnFormatted = moment(notification.params.end_on).format('LL');
        }

        if (notification.params.currency && !notification.params.isLoaded) {
            const arrayOldAndNewPrice = this.getPrices(notification.params.currency);
            notification.params.old_price = arrayOldAndNewPrice[0];
            notification.params.new_price = arrayOldAndNewPrice[1];
            notification.params.promo_code = arrayOldAndNewPrice[2];
            notification.params.isLoaded = true;
        }

        if (
            nextSubscription &&
            (notification.type === 'subscription-will-expire-soon' ||
                notification.type === 'subscription-expired-less-than-two-days')
        ) {
            notification.params.new_price = CurrencyFormatter.format(nextSubscription.price, currency);
            notification.params.old_price = CurrencyFormatter.format(
                nextSubscription.original_price,
                currency
            );
        }

        const link = this.getLink(notification);

        return (
            <Alert bsStyle="info" className={`alert-dismissable alert-headband ${notification.type}`}>
                <div className="container">
                    <button
                        type="button"
                        className="close"
                        onClick={this.handleClose}
                        data-type={notification.type}
                    >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18n.t(`headband.${notification.type}.text`, notification.params)
                        }}
                    />
                    {link && (
                        <a
                            className={`btn btn-xs btn-headband btn-white-filled btn-primary-color btn-${notification.type}`}
                            href={isEligible ? '/my-plan' : link}
                        >
                            {i18n.t(`headband.${notification.type}.btn`)}
                        </a>
                    )}
                </div>
            </Alert>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notifications
});

const mapDispatchToProps = (dispatch) => ({
    fetchNotifications: bindActionCreators(fetchNotifications, dispatch)
});

export default compose(withCookies, withCurrency, connect(mapStateToProps, mapDispatchToProps))(Headband);
