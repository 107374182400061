import Common from './Common';
var Transfer = /** @class */ (function () {
    function Transfer() {
    }
    Transfer.getTransfers = function (page) {
        var params = Common.params({
            url: "/v1/transfer/history".concat(page ? "?page=".concat(page) : ''),
            method: 'get'
        });
        return Common.request(params);
    };
    return Transfer;
}());
export default Transfer;
