import { Title, Callout, Text, Link } from '@homeexchange/design';
import React from 'react';
import Utils from '../../../../../utils/utils';
import styles from './guaranteesModal.module.scss';
import i18n from '../../../../../i18n';
import { Trans } from 'react-i18next';
var content = function (isCollection) { return [
    {
        title: i18n.t('home:modal.homeCreation.guarantees.guest.title'),
        paragraphs: [
            {
                title: i18n.t('home:modal.homeCreation.guarantees.guest.cancellation.title'),
                description: i18n.t('home:modal.homeCreation.guarantees.guest.cancellation.desc', {
                    nb: isCollection ? 2450 : 840
                })
            },
            {
                title: i18n.t('home:modal.homeCreation.guarantees.guest.nonCompliance.title'),
                description: i18n.t('home:modal.homeCreation.guarantees.guest.nonCompliance.desc')
            },
            {
                title: i18n.t('home:modal.homeCreation.guarantees.guest.damages.title'),
                description: i18n.t('home:modal.homeCreation.guarantees.guest.damages.desc', {
                    nb: isCollection ? '2 000 000' : '1 000 000'
                })
            }
        ]
    },
    {
        title: i18n.t('home:modal.homeCreation.guarantees.host.title'),
        paragraphs: [
            {
                title: i18n.t('home:modal.homeCreation.guarantees.host.caution.title', {
                    nb: isCollection ? 2500 : 500
                }),
                description: i18n.t('home:modal.homeCreation.guarantees.host.caution.desc')
            },
            {
                title: i18n.t('home:modal.homeCreation.guarantees.host.robbery.title'),
                description: i18n.t('home:modal.homeCreation.guarantees.host.robbery.desc')
            },
            {
                title: i18n.t('home:modal.homeCreation.guarantees.host.damages.title'),
                description: i18n.t('home:modal.homeCreation.guarantees.host.damages.desc', {
                    nb: isCollection ? '2 000 000' : '1 000 000'
                })
            }
        ]
    }
]; };
export default function GuaranteesModal(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("img", { className: styles['guarantees-modal-image'], src: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/guarantees").concat(props.isCollection ? '-collection' : '', ".svg"), alt: "Garanties" }),
        React.createElement(Title, { level: 3, className: styles['guarantees-modal-title'] }, i18n.t('home:modal.homeCreation.guarantees.title')),
        React.createElement("div", { className: styles['guarantees-modal-content'] }, content(props.isCollection).map(function (item, index) { return (React.createElement("div", { key: index },
            React.createElement(Text, { isBold: true, size: "xl", className: styles['guarantees-modal-subtitle'] }, item.title),
            item.paragraphs.map(function (paragraph) { return (React.createElement(React.Fragment, null,
                React.createElement(Text, { isBold: true, className: styles['guarantees-modal-paragraph-title'] }, paragraph.title),
                React.createElement(Text, { className: styles['guarantees-modal-paragraph-description'] }, paragraph.description))); }))); })),
        React.createElement(Callout, null,
            React.createElement(Text, null,
                React.createElement(Trans, { i18n: i18n, i18nKey: "home:modal.homeCreation.guarantees.callout.body", components: {
                        guaranteesLink: (React.createElement(Link, { external: true, href: props.isCollection ? '/p/garanties' : i18n.t('url:service') },
                            React.createElement(React.Fragment, null))),
                        termsLink: (React.createElement(Link, { external: true, target: "_blank", href: i18n.t('url:terms-of-use') },
                            React.createElement(React.Fragment, null)))
                    }, parent: React.Fragment })))));
}
