var IN_APP_STORY_API_KEY = process.env.IN_APP_STORY_API_KEY;
export var storyManagerConfig = {
    apiKey: IN_APP_STORY_API_KEY
};
export var commonOptions = {
    hasShare: true,
    hasLike: false,
    hasFavorite: false
};
export var storiesListOptions = {
    card: {
        title: {
            padding: 8
        },
        gap: 10,
        height: 200,
        variant: 'quad',
        border: {
            radius: 20,
            color: 'blue',
            width: 2,
            gap: 3
        },
        opacity: 1,
        mask: {
            color: 'rgba(34, 34, 34, 0.3)'
        },
        opened: {
            mask: {
                color: 'rgba(34, 34, 34, 0.1)'
            }
        }
    },
    layout: {
        height: 0,
        backgroundColor: 'transparent'
    },
    sidePadding: 20,
    topPadding: 20,
    bottomPadding: 20,
    bottomMargin: 0,
    navigation: {
        showControls: false,
        controlsSize: 48,
        controlsBackgroundColor: 'white',
        controlsColor: 'black'
    }
};
export var sharePanelConfig = {
    background: 'black',
    borderRadius: 12,
    divider: {
        background: '#dee2e6'
    },
    targets: ['facebook', 'linkedin', 'twitter']
};
export var storyReaderOptions = {
    scrollStyle: 'flat',
    sharePanel: sharePanelConfig
};
