import React, { useState, useEffect, useCallback } from 'react';
import scriptjs from 'scriptjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { requestTokenApple } from '../../actions/auth';

import i18n from '../../i18n';

export const AppleButton = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    function callApi(data) {
        const authorization = data.detail.authorization ? data.detail.authorization : '';
        const user = data.detail.user ? data.detail.user : '';
        const authorizationCode = authorization ? authorization.code : '';
        const firstName = user ? user.name.firstName : '';
        const lastName = user ? user.name.lastName : '';
        const locale = i18n.language;

        return props.requestTokenApple(authorizationCode, firstName, lastName, locale);
    }

    const redirectApple = window.location.origin.concat('/signupApple');

    useEffect(() => {
        scriptjs.get(
            'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
            () => {
                const params = {
                    clientId: 'com.homeexchange.client',
                    redirectURI: redirectApple,
                    scope: 'name email',
                    usePopup: true
                };
                window.AppleID.auth.init(params);
            }
        );
    }, []);

    useEffect(() => {
        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
            callApi(data).then((response) => props.onLogin(response));
            setIsLoading(true);
        });
        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess', (data) => {
                callApi(data).then((response) => props.onLogin(response));
                setIsLoading(false);
            });
        };
    }, []);

    useEffect(() => {
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.error(error);
        });
        return () => {
            document.removeEventListener('AppleIDSignInOnFailure', (error) => {
                console.error(error);
            });
        };
    }, []);

    const handleClick = useCallback(() => {
        if (props.onClick) props.onClick();
        window.AppleID.auth.signIn();
    }, []);

    return (
        <button className={`apple-button apple-${props.text}`} onClick={handleClick}>
            {isLoading ? (
                <ClipLoader size={25} color={'#ffffff'} />
            ) : (
                <i className="icon icon-apple-logo-white" />
            )}
            <p className="apple-text">{`${i18n.t(`common:${props.text}_apple`)}`}</p>
        </button>
    );
};

AppleButton.propTypes = {
    requestTokenApple: PropTypes.func,
    onLogin: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    text: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
    requestTokenApple: bindActionCreators(requestTokenApple, dispatch)
});

export default connect(null, mapDispatchToProps)(AppleButton);
