import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useGTM from './components/analytics/useGTM';
import Analytics from './utils/analytics';
import User from './models/User';
import {
    NAVIGATION_ACTION,
    NAVIGATION_AREAS,
    NAVIGATION_TEXT
} from './components/analytics/analytics-constants';

const TrackingTwig = ({ user }) => {
    const onTrackNavigationButton = (area, text) => {
        const { fetchNavigation } = useGTM(user);
        Analytics.trackGTM('button', fetchNavigation(NAVIGATION_ACTION.CLICK, area, text));
    };

    // Header - Sign in tracking
    const signInButtons = document.getElementsByClassName('btn-sign-in-header');
    if (signInButtons.length > 0) {
        for (const signInButton of signInButtons) {
            signInButton.addEventListener('click', () => {
                onTrackNavigationButton(NAVIGATION_AREAS.HEADER, NAVIGATION_TEXT.SIGN_IN);
            });
        }
    }

    // Header - Sign up tracking
    const signUpButtons = document.getElementsByClassName('btn-sign-up-header');
    if (signUpButtons.length > 0) {
        for (const signUpButton of signUpButtons) {
            const area = signUpButton.classList.contains('signup-navbar-right')
                ? NAVIGATION_AREAS.HEADER_WEBMOBILE_OUTSIDE_MENU
                : NAVIGATION_AREAS.HEADER;

            signUpButton.addEventListener('click', () => {
                onTrackNavigationButton(area, NAVIGATION_TEXT.SIGN_UP);
            });
        }
    }

    // Home view - Add to favorites tracking
    const homeViewAddToFavoritesButtons = document.getElementsByClassName('btn-home-view-add-to-favorites');
    if (homeViewAddToFavoritesButtons.length > 0) {
        for (const homeViewAddToFavoritesButton of homeViewAddToFavoritesButtons) {
            homeViewAddToFavoritesButton.addEventListener('click', () => {
                onTrackNavigationButton(NAVIGATION_AREAS.DETAILS, NAVIGATION_TEXT.ADD_TO_FAVORITES_SIGN_UP);
            });
        }
    }

    // Home view - Contact tracking
    const homeViewContactButtons = document.getElementsByClassName('btn-home-view-contact');
    if (homeViewContactButtons.length > 0) {
        for (const homeViewContactButton of homeViewContactButtons) {
            homeViewContactButton.addEventListener('click', () => {
                onTrackNavigationButton(NAVIGATION_AREAS.USER_PROFILE, NAVIGATION_TEXT.CONTACT_SIGN_UP);
            });
        }
    }

    // Home view - Sign up map tracking
    const homeViewSignUpMapButtons = document.getElementsByClassName('btn-home-view-sign-up-map');
    if (homeViewSignUpMapButtons.length > 0) {
        for (const homeViewSignUpMapButton of homeViewSignUpMapButtons) {
            homeViewSignUpMapButton.addEventListener('click', () => {
                onTrackNavigationButton(NAVIGATION_AREAS.MAP, NAVIGATION_TEXT.SIGN_UP);
            });
        }
    }

    // Home view - User profile tracking
    const homeViewUserProfileButtons = document.getElementsByClassName('btn-home-view-user-profile');
    if (homeViewUserProfileButtons.length > 0) {
        for (const homeViewUserProfileButton of homeViewUserProfileButtons) {
            homeViewUserProfileButton.addEventListener('click', () => {
                onTrackNavigationButton(NAVIGATION_AREAS.USER_PROFILE, NAVIGATION_TEXT.PHOTO_SIGN_UP);
            });
        }
    }

    return null;
};

TrackingTwig.propTypes = {
    user: PropTypes.instanceOf(User)
};

export default connect((state) => ({
    user: state.user
}))(TrackingTwig);
