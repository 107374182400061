var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Icon, Text } from '@homeexchange/design';
import i18n from '../../../i18n';
var MenuItem = function (_a) {
    var url = _a.url, icon = _a.icon, title = _a.title, status = _a.status, text = _a.text;
    return (React.createElement("li", { id: "menu-profile-verification", role: "itemmenu" },
        React.createElement("a", { className: "link-top-profile item-link", href: url },
            React.createElement("div", { className: "item" },
                React.createElement("div", { className: "dropdown-title" },
                    React.createElement(Icon, { size: "2xl", name: icon }),
                    React.createElement("span", null, title)),
                status && (React.createElement(Text, { color: status.color, size: "sm", isBold: status.isBold }, status.text))),
            React.createElement("div", null, text && (React.createElement("div", { className: "section-status-container" },
                React.createElement(Text, { color: "secondary", size: "sm", isBold: true, className: "top-menu-messages" }, text)))))));
};
export var MenuProfileVerification = function (_a) {
    var user = _a.user;
    var _b = useState(), verificationItem = _b[0], setVerificationItem = _b[1];
    var getVerification = function () {
        var homes = user.get('homes');
        var pendingVerification = function (url) { return ({
            status: {
                text: i18n.t('user:menu.verification.pending'),
                color: 'tertiary',
                isBold: true
            },
            url: url
        }); };
        // Verification case: user account in pending
        if (user.isOnVerification()) {
            return pendingVerification(i18n.t('user:user.verif.url'));
        }
        if (homes) {
            var leadVerification = {
                text: i18n.t('global:menu.common.account.verification.text'),
                url: i18n.t('user:user.verif.url')
            };
            // Verification case: no homes or user account not verified
            if (homes.length === 0 || user.isUnverified()) {
                return leadVerification;
            }
            // Verification case: phone is not verified
            if (!user.isPhoneVerified()) {
                return {
                    text: i18n.t('user:menu.verification.new-phone-details'),
                    url: i18n.t('url:user_edit', { id: user.id }) + '#anchor-phone'
                };
            }
            // Verification case: new home added and user account not in pending
            for (var i = 0; i < homes.length; i++) {
                var home = homes[i];
                if (home.isUnverified() && home.isQualified()) {
                    if (user.hasVerificationPending()) {
                        return pendingVerification(i18n.t('url:home-verification', { id: home.id }));
                    }
                    return i === 0
                        ? leadVerification
                        : {
                            text: i18n.t('user:menu.verification.new-home-details'),
                            url: i18n.t('url:home-verification', { id: home.id })
                        };
                }
            }
        }
        // Verification case: user account is verified
        if (user.isVerified()) {
            return {
                status: {
                    text: i18n.t('user:menu.verification.verified'),
                    color: 'success',
                    isBold: false
                },
                url: i18n.t('user:user.verif.url')
            };
        }
    };
    useEffect(function () {
        if (user) {
            setVerificationItem(getVerification());
        }
    }, [user]);
    return (React.createElement(MenuItem, __assign({ icon: "verification-check-regular", title: i18n.t('user:menu.verification') }, verificationItem)));
};
