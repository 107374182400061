import Api from '../api/Api';
export var SET_TRANSFER_ACHIEVEMENTS = 'SET_TRANSFER_ACHIEVEMENTS';
export var SET_TRANSFER_BALANCE = 'SET_TRANSFER_BALANCE';
export function setTransferAchievements(transfers) {
    return {
        type: SET_TRANSFER_ACHIEVEMENTS,
        payload: transfers
    };
}
export function setTransferBalance(balance) {
    return {
        type: SET_TRANSFER_BALANCE,
        payload: balance
    };
}
export function fetchTransferAchievements() {
    return function (dispatch) {
        return Api.User.getTransferAchievements().then(function (result) {
            dispatch(setTransferAchievements(result.next_steps));
            dispatch(setTransferBalance(result.gp_balance));
            return result;
        });
    };
}
