import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import PaymentMethodModel from '../../models/PaymentMethod';

import PaymentMethodCard from './PaymentMethodCard';
import PaymentMethodPaypal from './PaymentMethodPaypal';

const PaymentMethodDetail = ({ paymentMethod }) => {
    let paymentMethodDetail;
    switch (paymentMethod.get('type')) {
        case 'payment_method':
        case 'card':
            paymentMethodDetail = <PaymentMethodCard paymentMethod={paymentMethod} />;
            break;
        case 'paypal':
            paymentMethodDetail = <PaymentMethodPaypal paymentMethod={paymentMethod} />;
            break;
        default:
            Sentry.withScope((scope) => {
                scope.setExtra('extra', {
                    paymentMethod
                });
                Sentry.captureException(new Error('Unknown payment method'));
            });
            paymentMethodDetail = null;
    }

    return <div className="payment-method-left">{paymentMethodDetail}</div>;
};

PaymentMethodDetail.propTypes = {
    paymentMethod: PropTypes.instanceOf(PaymentMethodModel)
};

export default PaymentMethodDetail;
