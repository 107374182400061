import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import braintree from 'braintree-web';
import * as Sentry from '@sentry/react';

import Loading from '../common/Loading';
import Screen from '../../utils/Screen';
import i18n, { locales } from '../../i18n';

export default class PaypalButton extends React.PureComponent {
    _isMounted = false;
    clientInstance;

    static propTypes = {
        token: PropTypes.string.isRequired,
        handlePaypalError: PropTypes.func.isRequired,
        onPaypalTokenGenerated: PropTypes.func.isRequired,
        context: PropTypes.oneOf(['messaging', 'subscription-payment', 'my-plan']),
        style: PropTypes.shape({
            height: PropTypes.number,
            label: PropTypes.string
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.loadPaypalButton = this.loadPaypalButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadPaypalButton();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.clientInstance) {
            this.clientInstance.teardown();
        }
    }

    mapPaypalLocales() {
        // Paypal supported locales: https://developer.paypal.com/docs/integration/direct/rest/locale-codes/#
        const currentLocale = i18n.language;
        const supportedLocales = ['en', 'fr', 'es', 'it', 'pt', 'nl', 'de', 'da', 'sv', 'no'];
        return supportedLocales.includes(currentLocale) ? locales[currentLocale].replace('-', '_') : 'en_US';
    }

    loadPaypalButton() {
        const { token, style, handlePaypalError, onPaypalTokenGenerated } = this.props;
        const paypalButtonStyle = Object.assign(
            {},
            {
                color: 'gold',
                shape: 'rect',
                label: 'paypal',
                height: 33,
                tagline: false
            },
            style
        );
        let deviceData;

        return braintree.client
            .create({
                authorization: token
            })
            .then((ci) => {
                this.clientInstance = ci;

                // Create Braintree Data Collector (needed to improve security)
                return braintree.dataCollector.create({
                    client: this.clientInstance,
                    paypal: true
                });
            })
            .then((dataCollectorInstance) => {
                ({ deviceData } = dataCollectorInstance);

                // Create a PayPal Checkout component.
                return braintree.paypalCheckout.create({
                    client: this.clientInstance
                });
            })
            .then((paypalCheckoutInstance) => {
                // Set up PayPal with the checkout.js library
                import('paypal-checkout').then((paypal) => {
                    paypal.Button.render(
                        {
                            env: process.env.ENV === 'production' ? 'production' : 'sandbox',
                            style: paypalButtonStyle,
                            size: 'responsive',
                            locale: this.mapPaypalLocales(),
                            payment: () =>
                                paypalCheckoutInstance.createPayment({
                                    flow: 'vault',
                                    intent: 'authorize'
                                }),
                            onAuthorize: (data) =>
                                paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
                                    // Handle generated token
                                    onPaypalTokenGenerated(payload.nonce, deviceData);
                                }),
                            onCancel: () => {
                                handlePaypalError('payment-cancelled');
                            },
                            onError: (err) => {
                                handlePaypalError('payment-error');
                                Sentry.withScope((scope) => {
                                    scope.setExtra('extra', {
                                        type: 'paypalError',
                                        err
                                    });
                                    Sentry.captureException(
                                        new Error('Checkout.js - Unable to get Paypal user')
                                    );
                                });
                            }
                        },
                        Screen.isMobile() && this.props.context !== 'my-plan'
                            ? '#paypal-button-mobile'
                            : '#paypal-button-desktop'
                    );
                });
            })
            .then(() => {
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            })
            .catch((err) => {
                if (this._isMounted) {
                    // Handle component creation error
                    handlePaypalError('component-creation-error');
                    // Send to Sentry
                    Sentry.withScope((scope) => {
                        scope.setExtra('extra', {
                            type: 'paypalError',
                            err
                        });
                        Sentry.captureException(new Error('Unable to create Paypal button'));
                    });
                    this.setState({ loading: false });
                }
            });
    }

    displayButton(id, className, loadingWidth = '30px') {
        const { loading } = this.state;

        return (
            <div id={id} className={className}>
                {loading && <Loading width={loadingWidth} />}
            </div>
        );
    }

    render() {
        const { context } = this.props;

        if (context === 'my-plan') {
            return this.displayButton('paypal-button-desktop', 'paypal-button');
        }

        return (
            <Fragment>
                {this.displayButton('paypal-button-desktop', 'paypal-button hidden-xs')}

                <div className="sticky-bottom visible-xs">
                    {this.displayButton('paypal-button-mobile', 'paypal-button')}
                </div>
            </Fragment>
        );
    }
}
