var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Analytics from '../analytics';
// ================================= HOME VIEW TRACKING =============================================
export var BUTTON_BLOCKER_EVENT = 'button_blocker';
export var MODAL_BLOCKER_EVENT = 'modal_blocker';
export var HOME_VIEW_EVENT = 'home_view';
export var HOME_VIEW_DATA_AREA = 'homeview';
export var HOME_EDIT_DATA_AREA = 'home_edit';
export var HOME_FUNNEL_DATA_AREA = 'home_funnel';
export var HOME_FUNNEL_REGULAR = 'home_funnel_regular';
export var trackHomeview = function (eventName, text, event) {
    Analytics.trackGTM(eventName, {
        event_data: {
            action: 'click',
            area: HOME_VIEW_DATA_AREA,
            text: text
        }
    });
    if (event) {
        event.preventDefault();
        var anchorTarget = event.target;
        window.location.href = anchorTarget.href;
    }
};
export var trackButtonClick = function (area, text, collection) {
    return Analytics.trackGTM('button', {
        event_data: {
            action: 'click',
            area: area,
            text: text,
            collection: collection
        }
    });
};
//================================SEARCH TRACKING=================================
export var trackPerformedSearch = function (searchParam, searchValue) {
    return Analytics.trackGTM('PerformedSearch', {
        event_data: __assign(__assign({ action: 'onSearchRequest', area: 'search' }, (searchParam && { search_parameter: searchParam })), (searchValue && { search_value: searchValue }))
    });
};
export var searchResultsData = {
    text: {
        homeCard: 'home_card',
        saveDestination: 'save_this_destination',
        moreFilters: 'more_filters',
        searchBanner: 'become_member_search-banner',
        dualSearch: 'dual_search',
        noResults: 'no_results'
    },
    searchParam: {
        saveDestination: 'save_this_destination',
        sortBy: 'sort_by',
        noResults: 'save_this_destination_no_results'
    }
};
export var trackSearchResults = function (text, searchParam, searchValue) {
    return Analytics.trackGTM('SearchResults', {
        event_data: __assign(__assign(__assign({ action: 'click', area: 'search' }, (text && { text: text })), (searchParam && { search_parameter: searchParam })), (searchValue && { search_value: searchValue }))
    });
};
// ==============================FILLING PANNEL TRACKING==================================
export var HOME_FILLING_PANNEL_EVENT_NAME = 'home_filling_pannel';
export var HOME_FILLING_PANNEL_EVENT_DATA_AREA = 'homepage';
export var HOME_FILLING_PANNEL_EVENT_DATA_TEXT = {
    homeCreation: 'home_creation',
    homeQualification: 'home_qualification',
    homeQualificationCalendarNoAvailabilities: 'home_qualification_calendar_no_availabilities',
    calendarUpdateNoAvailabilities: 'calendar_update_no_availabilities',
    calendarNotUpdatedSinceFourMonths: 'calendar_not_updated_since_4_months'
};
export var trackFillingPanel = function (text) {
    return Analytics.trackGTM(HOME_FILLING_PANNEL_EVENT_NAME, {
        event_data: {
            action: 'click',
            area: HOME_FILLING_PANNEL_EVENT_DATA_AREA,
            text: text
        }
    });
};
//==============================HEADER MENU TRACKING=============================
export var HEADER_MENU_TRACKING_DATA_TEXT = {
    addHome: 'add_a_home',
    completeHomeFirst: 'complete_your_home_first_time',
    completeHome: 'complete_your_home',
    publishHome: 'publish_your_home',
    addAvailabilities: 'add_availabilities_in_the_coming_months',
    updateAvailabilities: 'are_your_availabilities_up_to_date',
    homeCompleted: 'home_and_calendar_completed'
};
export var trackHeaderMenu = function (text) {
    Analytics.trackGTM('header_menu_home', {
        event_data: {
            action: 'click',
            area: 'header',
            text: text
        }
    });
};
var RegularFunnelsEvents = /** @class */ (function () {
    function RegularFunnelsEvents() {
    }
    RegularFunnelsEvents.funnelStart = function (name) {
        return Analytics.trackGTM('funnel_start', {
            event_data: { funnel_name: name, funnel_step: 'welcome_page', area: HOME_FUNNEL_DATA_AREA }
        });
    };
    RegularFunnelsEvents.funnelProgress = function (name, step) {
        return Analytics.trackGTM('funnel_progress', {
            event_data: { funnel_name: name, funnel_step: step, area: HOME_FUNNEL_DATA_AREA }
        });
    };
    RegularFunnelsEvents.funnelCompleted = function (name, step) {
        return Analytics.trackGTM('funnel_completed', {
            event_data: { funnel_name: name, funnel_step: step, area: HOME_FUNNEL_DATA_AREA }
        });
    };
    RegularFunnelsEvents.funnelModalView = function (name) {
        var step = stepFromUrl();
        return Analytics.trackGTM('modal', {
            event_data: __assign({ funnel_name: name, text: 'modal_save_and_resume_qualification_funnel', area: HOME_FUNNEL_DATA_AREA }, (step && { funnel_step: step }))
        });
    };
    RegularFunnelsEvents.funnelModalContinue = function (name) {
        var step = stepFromUrl();
        return Analytics.trackGTM('button', {
            event_data: __assign({ funnel_name: name, text: 'go_to_dashboard_qualification_funnel', area: HOME_FUNNEL_DATA_AREA }, (step && { funnel_step: step }))
        });
    };
    RegularFunnelsEvents.funnelButtonClick = function (text, step) {
        return Analytics.trackGTM('button', {
            event_data: {
                funnel_name: HOME_FUNNEL_REGULAR,
                text: text,
                area: HOME_FUNNEL_DATA_AREA,
                funnel_step: step
            }
        });
    };
    RegularFunnelsEvents.funnelModalCancel = function (name) {
        var step = stepFromUrl();
        return Analytics.trackGTM('button', {
            event_data: __assign({ funnel_name: name, text: 'modal_keep_editing_qualification_funnelcancel', area: HOME_FUNNEL_DATA_AREA }, (step && { funnel_step: step }))
        });
    };
    RegularFunnelsEvents.HOME_CREATION_FUNNEL = {
        name: 'home_creation_regular',
        step: {
            listing: 'home_creation_type_of_listing',
            address: 'home_creation_address',
            surroundingTags: 'home_creation_surrounding_tags',
            size: 'home_creation_size',
            beds: 'home_creation_beds',
            amenities: 'home_creation_amenities',
            description: 'home_creation_description',
            calendar: 'home_creation_calendar',
            photos: 'home_creation_photos',
            registration: 'home_creation_registration_number',
            final: 'home_creation_sumup_finalscreen',
            nextSteps: 'home_creation_next_steps'
        },
        cta: {
            continue: 'continue',
            skip: 'skip',
            back: 'back'
        },
        byStep: {
            part1_1: 'home_type',
            part1_2: 'accomodation_type',
            part1_3: 'residence_type',
            part1_4: 'address',
            part1_5: 'surroundings',
            part1_6: 'distance_touristic_places',
            part1_7: 'surface',
            part1_8: 'nbr_of_rooms',
            part1_9: 'type_of_beds',
            part2_1: 'practical_amenities',
            part2_2: 'multimedia_amenities',
            part2_3: 'unique_amenities',
            part2_4: 'outside_amenities',
            part2_5: 'kids-friendly_amenities',
            part2_6: 'remote-work_amenities',
            part2_7: 'transport_amenities',
            part2_8: 'eco-friendly_amenities',
            part2_10: 'animals',
            part2_11: 'preferences_and_requests',
            part3_1: 'listing_name',
            part3_2: 'home_description',
            part3_3: 'neighborhood_description',
            part4_1: 'photos',
            part5_1: 'calendar'
        }
    };
    return RegularFunnelsEvents;
}());
export { RegularFunnelsEvents };
function stepFromUrl() {
    var urlParts = window.location.pathname.split('/');
    var stepIndex = urlParts.indexOf('step');
    var partIndex = urlParts.indexOf('part');
    // Handle special steps: next-steps and final
    if (urlParts.includes('next-steps')) {
        return RegularFunnelsEvents.HOME_CREATION_FUNNEL.step.nextSteps;
    }
    if (urlParts.includes('final')) {
        return RegularFunnelsEvents.HOME_CREATION_FUNNEL.step.final;
    }
    // Handle regular step/part URLs
    if (stepIndex !== -1 && urlParts[stepIndex + 1] && partIndex !== -1 && urlParts[partIndex + 1]) {
        var stepKey = "part".concat(urlParts[partIndex + 1], "_").concat(urlParts[stepIndex + 1]);
        return RegularFunnelsEvents.HOME_CREATION_FUNNEL.byStep[stepKey];
    }
    return false;
}
export var trackVerificationModalView = function () {
    var eventData = {
        area: 'homeview',
        action: 'view',
        text: 'verification_collection',
        collection: true
    };
    Analytics.trackGTM('modal', { event_data: eventData });
};
export var trackVerificationButtonClick = function () {
    var eventData = {
        area: 'homeview',
        action: 'click',
        text: 'complete_verification',
        collection: true
    };
    Analytics.trackGTM('button', { event_data: eventData });
};
