/*
 * This translator class use the website's route which call the Microsoft API.
 * In future, we should use our own implementation of Microsoft API.
 */
import Common from '../api/Common';

export default class MicrosoftTranslator {
    static translate(string, target, callback) {
        if (typeof string !== 'string') {
            throw new Error('Input source must be a string.');
        }
        if (typeof target !== 'string') {
            throw new Error('No target language specified. Must be a string.');
        }

        // fetch translation
        const promise = new Promise((resolve, reject) => {
            MicrosoftTranslator.getTranslate(string, target)
                .then((response) => {
                    if (
                        response.length &&
                        response.length > 0 &&
                        response[0] &&
                        response[0].translations &&
                        response[0].translations.length &&
                        response[0].translations.length > 0 &&
                        response[0].translations[0] &&
                        response[0].translations[0].text
                    ) {
                        resolve(response[0].translations[0].text);
                    } else {
                        reject();
                    }
                })
                .fail((response) => {
                    reject(response.statusText);
                });
        });

        // pass response to callback if define
        if (callback) {
            promise.then(callback);
        }

        return promise;
    }

    static getTranslate(text, to) {
        const params = Common.params({
            url: '/v1/translate',
            method: 'post',
            data: {
                to: to,
                text: text
            }
        });

        return Common.request(params);
    }
}
