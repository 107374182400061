import Common from './Common';
var Contact = /** @class */ (function () {
    function Contact() {
    }
    Contact.contact = function (data) {
        var params = {
            url: '/v1/contact',
            method: 'post',
            data: data
        };
        return Common.request(params);
    };
    return Contact;
}());
export default Contact;
