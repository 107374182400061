import Api from '../api/Api';
import Analytics from '../utils/analytics';

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION';
export const ERROR_FETCHING_SUBSCRIPTION = 'ERROR_FETCHING_SUBSCRIPTION';
export const SET_COUPON = 'SET_COUPON';

export function setSubscription(payload) {
    return {
        type: SET_SUBSCRIPTION,
        payload
    };
}

export function requestSubscription() {
    return {
        type: REQUEST_SUBSCRIPTION
    };
}

export function errorFetchingSubscription(error, code) {
    return {
        type: ERROR_FETCHING_SUBSCRIPTION,
        payload: {
            error,
            code
        }
    };
}

export function fetch() {
    return (dispatch) =>
        Api.Subscription.getSubscription().then((response) => {
            let subscription = null;
            let nextProduct = null;
            let isToggleAutoRenewDisabled = false;
            // for 3xpayment
            let isLoyaltyExpired = null;
            let isSubMultiPaymentFinished = null;
            let trial = null;
            if (response) {
                ({
                    subscription,
                    next_product: nextProduct,
                    is_loyalty_expired: isLoyaltyExpired,
                    is_sub_multi_payment_finished: isSubMultiPaymentFinished,
                    is_toggle_auto_renew_disabled: isToggleAutoRenewDisabled,
                    trial
                } = response);
            }
            dispatch(
                setSubscription({
                    subscription,
                    nextProduct,
                    isLoyaltyExpired,
                    isSubMultiPaymentFinished,
                    isToggleAutoRenewDisabled,
                    trial
                })
            );
        });
}

export function toggleAutoRenew(data, userEmail = null, disabled = false) {
    return (dispatch) => {
        dispatch(
            setSubscription({
                subscription: {
                    auto_renew: data
                },
                isToggleAutoRenewDisabled: disabled
            })
        );
        return Api.Subscription.updateSubscription({
            auto_renew: data
        }).then(
            (response) => {
                // send tracking to iterable api
                if (userEmail) {
                    try {
                        if (data) {
                            Analytics.trackIterable('SubscriptionAutoRenewEnabled', {
                                email: userEmail
                            });
                        } else {
                            Analytics.trackIterable('SubscriptionAutoRenewDisabled', {
                                email: userEmail
                            });
                        }
                    } catch (error) {
                        console.error('Error while tracking Iterable', error);
                    }
                }
                return dispatch(
                    setSubscription({
                        subscription: response
                    })
                );
            },
            () =>
                dispatch(
                    setSubscription({
                        subscription: {
                            auto_renew: !data
                        }
                    })
                )
        );
    };
}

export function pay(
    data,
    type = 'credit-card',
    couponName,
    is3xPayment = false,
    hasCollectionProduct = false,
    startTrial = false
) {
    return (dispatch) => {
        dispatch(requestSubscription());
        let payFunction = Api.Subscription.paySubscriptionWithToken;

        if (is3xPayment) {
            payFunction = Api.Subscription.paySubscriptionWithToken3x;
        }

        if (type === 'saved-card') {
            payFunction = Api.Subscription.paySubscriptionWithExistingCard;
            if (is3xPayment) {
                payFunction = Api.Subscription.paySubscriptionWithExistingCard3x;
            }
        } else if (type === 'saved-paypal') {
            payFunction = Api.Subscription.paySubscriptionWithExistingPaypalAccount;
        } else if (type === 'paypal') {
            payFunction = Api.Subscription.paySubscriptionWithPaypal;
        }

        return payFunction(...data, couponName, hasCollectionProduct, startTrial).then(
            (response) =>
                dispatch(
                    setSubscription({
                        subscription: response
                    })
                ),
            (response) => {
                const errorAction = dispatch(
                    errorFetchingSubscription(response.responseJSON, response.status)
                );
                return Promise.reject(errorAction);
            }
        );
    };
}

export function setCoupon(payload) {
    return {
        type: SET_COUPON,
        payload
    };
}

export function getCoupon(promoCode, productType) {
    return (dispatch) =>
        Api.User.getPromoCode(promoCode, productType).then(
            (response) => dispatch(setCoupon(response)),
            (err) => err
        );
}

export function getCouponByType(promoCode) {
    return (dispatch) =>
        Api.User.getPromoCodeByType(promoCode).then(
            (response) => dispatch(setCoupon(response)),
            (err) => err
        );
}
