import emojione from 'emojione';

import Model from './Model';
import i18n from '../i18n';
import User from './User';
import Utils from '../utils/utils';
import Api from '../api/Api';

emojione.ascii = true;

export default class Message extends Model {
    static STATUS_RECEIVED_UNREAD = 0;
    static STATUS_RECEIVED_READ = 1;

    static TYPE_USER = 0;
    static TYPE_AUTO = 1;
    static TYPE_EXCHANGE = 2;
    static TYPE_PAYMENT = 3;
    static TYPE_MODIFICATION = 5;
    static TYPE_INFO = 6;

    static TYPE_AUTO_MESSAGE_APPROVED = 1;
    static TYPE_AUTO_MESSAGE_FINALIZED = 2;
    static TYPE_AUTO_MESSAGE_CANCEL_APPROVAL = 3;
    static TYPE_AUTO_MESSAGE_CANCEL = 4;
    static TYPE_AUTO_MESSAGE_RELEASE_DEPOSIT = 5;
    static TYPE_AUTO_MESSAGE_KEEP_DEPOSIT = 6;
    static TYPE_AUTO_MESSAGE_RELEASE_GP = 7;
    static TYPE_AUTO_MESSAGE_KEEP_GP = 8;
    static TYPE_AUTO_MESSAGE_CHANGE_STARTON = 9;
    static TYPE_AUTO_MESSAGE_CHANGE_ENDON = 10;
    static TYPE_AUTO_MESSAGE_CHANGE_DEPOSIT = 11;
    static TYPE_AUTO_MESSAGE_CHANGE_INSURANCE = 12;
    static TYPE_AUTO_MESSAGE_CHANGE_NBGUEST = 13;
    static TYPE_AUTO_MESSAGE_CHANGE_GP = 14;
    static TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE = 15;
    static TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL = 16;
    static TYPE_AUTO_MESSAGE_CHANGE_SERVICE_PLUS = 17;
    static TYPE_MESSAGE_SUPPORT_CHANGE_DATE = 21;
    static TYPE_MESSAGE_SUPPORT_CHANGE_HOME = 22;
    static TYPE_MESSAGE_SUPPORT_CHANGE_GP = 23;
    static TYPE_MESSAGE_SUPPORT_CHANGE_GUESTS = 24;
    static TYPE_MESSAGE_SUPPORT_CHANGE_MANY_DETAILS = 25;
    static TYPE_AUTO_MESSAGE_UPDATE_REQUEST_CANCELLED = 31;
    static TYPE_AUTO_MESSAGE_UPDATE_REQUEST_APPROVED = 32;
    static TYPE_AUTO_MESSAGE_UPDATE_REQUEST_REJECTED = 33;
    static TYPE_AUTO_MESSAGE_UPDATE_FINALIZED = 35;
    static TYPE_AUTO_MESSAGE_PREAPPROBATION_EXPIRED = 40;
    static TYPE_AUTO_MESSAGE_AUTOMATIC_DECLINED = 41;

    static AUTOMESSAGE_FINALIZE = 0;

    static KEY_AUTO_MESSAGE_NAME_APPROVED_MODIFICATION = 'auto_message_name_approved_modification';
    static KEY_AUTO_MESSAGE_RECEIVER_REJECTED_MODIFICATION = 'auto_message_receiver_rejected_modification';
    static KEY_AUTO_MESSAGE_REQUESTER_CANCELED_MODIFICATION = 'auto_message_requester_cancels_modification';

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('author') && _.isObject(attributes.author)) {
            if (attributes.author instanceof User === false) {
                attributes.author = new User(attributes.author);
            }
        }

        return attributes;
    }

    getType() {
        switch (this.get('type')) {
            case Message.TYPE_USER:
                return 'user';
            case Message.TYPE_AUTO:
                return 'auto';
            case Message.TYPE_EXCHANGE:
                return 'exchange';
            case Message.TYPE_PAYMENT:
                return 'payment';
            case Message.TYPE_MODIFICATION:
                return 'modification';
            case Message.TYPE_INFO:
                return 'info';
            default:
                return '';
        }
    }

    typeAutoToSlug(typeAuto) {
        switch (typeAuto) {
            case Message.TYPE_AUTO_MESSAGE_APPROVED:
                return 'approved';
            case Message.TYPE_AUTO_MESSAGE_FINALIZED:
                return 'finalized';
            case Message.TYPE_AUTO_MESSAGE_CANCEL_APPROVAL:
                return 'cancel-approval';
            case Message.TYPE_AUTO_MESSAGE_CANCEL:
                return 'cancel';
            case Message.TYPE_AUTO_MESSAGE_RELEASE_DEPOSIT:
                return 'release-deposit';
            case Message.TYPE_AUTO_MESSAGE_KEEP_DEPOSIT:
                return 'keep-deposit';
            case Message.TYPE_AUTO_MESSAGE_RELEASE_GP:
                return 'release-gp';
            case Message.TYPE_AUTO_MESSAGE_KEEP_GP:
                return 'keep-gp';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_STARTON:
                return 'change-starton';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_ENDON:
                return 'change-endon';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_DEPOSIT:
                return 'change-deposit';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_INSURANCE:
                return 'change-insurance';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_NBGUEST:
                return 'change-nbguest';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_GP:
                return 'change-gp';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE:
                return 'change-to-simple';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL:
                return 'change-to-reciprocal';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_SERVICE_PLUS:
                return 'change-service-plus';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_DATE:
                return 'support-change-exchange-dates';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_HOME:
                return 'support-change-exchange-home';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_GP:
                return 'support-change-exchange-GP';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_GUESTS:
                return 'support-change-exchange-guests';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_MANY_DETAILS:
                return 'support-change-exchange-many-details';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_APPROVED:
                return 'status-name-approved-modification';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_REJECTED:
                return 'status-receiver-rejected-modification';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_CANCELLED:
                return 'status-requester-cancels-modification';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_FINALIZED:
                return 'status-name-finalized-modification';
            case Message.TYPE_AUTO_MESSAGE_PREAPPROBATION_EXPIRED:
                return 'pre-approbation-expired';
            default:
                return '';
        }
    }

    getTypeAuto() {
        return this.typeAutoToSlug(this.get('type_auto'));
    }

    getTypeAutoField() {
        switch (this.get('type_auto')) {
            case Message.TYPE_AUTO_MESSAGE_CHANGE_STARTON:
                return 'start_on';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_ENDON:
                return 'end_on';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_DEPOSIT:
                return 'deposit';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_INSURANCE:
                return 'insurance';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_NBGUEST:
                return 'guest_nb';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_GP:
                return 'guestpoints';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE:
                return 'simple';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL:
                return 'reciprocal';
            default:
                return '';
        }
    }

    isTypeChanged() {
        return _.contains(
            [Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE, Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL],
            this.get('type_auto')
        );
    }

    getContent(attributes = {}) {
        if (this.get('type') != Message.TYPE_USER && this.get('type_auto')) {
            const key = this.typeAutoToSlug(this.get('type_auto')).replace(new RegExp('-', 'g'), '_');

            if (key) {
                const author = this.get('author');
                const content = i18n.t(`messaging:auto_message_${key}`, {
                    context: author.get('id') === Api.User.identity().id ? 'me' : 'you',
                    first_name: author.get('first_name'),
                    ...attributes
                });

                return content;
            }
        }
        return this.get('content');
    }

    static getHTMLContent(content, nl2br = true) {
        if (!content) return content;

        let newContent = content;

        if (nl2br) {
            newContent = Utils.nl2br(content);
        }
        newContent = emojione.shortnameToImage(newContent);

        return newContent;
    }
}
