import {
    REQUEST_CONVERSATIONS,
    RECEIVE_CONVERSATIONS,
    STATS_CONVERSATIONS,
    RANGE_CONVERSATIONS,
    FILTER_CONVERSATIONS,
    SELECT_CONVERSATIONS,
    SEARCH_CONVERSATIONS,
    TOGGLE_FAVORITE_CONVERSATIONS,
    ARCHIVE_CONVERSATION,
    ARCHIVE_CONVERSATIONS,
    UNARCHIVE_CONVERSATION,
    UNARCHIVE_CONVERSATIONS,
    DELETE_CONVERSATION,
    DELETE_CONVERSATIONS,
    REQUEST_CONVERSATION,
    RECEIVE_CONVERSATION,
    FAILED_REQUEST_CONVERSATION,
    UPDATE_CONVERSATION,
    EXCHANGE_UPDATE_SUCCESS,
    EXCHANGE_UPDATE_FAILED,
    EXCHANGE_FINALIZATION_FAILED,
    CLEAR_CONVERSATION
} from '../actions/conversations';

import Conversation from '../models/Conversation';

const sortConversations = function (conversations) {
    // reverse sort conversation list by date
    return _.sortBy(conversations, (conversation) => moment(conversation.getDate()).format('X')).reverse();
};

export const removeConversation = function (state, conversationId) {
    let index = -1;
    if (state.conversation) {
        // current conversation index in list
        index = _.findIndex(state.conversations, (conversation) => conversation.id == conversationId);
        // if index is out of range after conversation removed, set index to first item
        if (state.conversations.length - 2 < index) {
            index = 0;
        }
    }
    const remainsConversations = state.conversations.filter(
        (conversation) => conversation.id != conversationId
    );
    return Object.assign({}, state, {
        conversations: remainsConversations,
        conversation: remainsConversations[index] || null
    });
};

export const removeConversationMultiple = function (state, conversationIds) {
    let currentConversation = state.conversation;
    if (state.conversation && _.contains(conversationIds, state.conversation.id)) {
        currentConversation = null;
    }
    return Object.assign({}, state, {
        conversations: state.conversations.filter(
            (conversation) => !_.contains(conversationIds, conversation.id)
        ),
        conversation: currentConversation
    });
};

const conversations = (
    state = {
        isFetching: false,
        conversations: [],
        total: 0,
        stats: {},
        offset: 0,
        limit: 10,
        filter: 'all',
        selected: [],
        search: '',
        isFetchingOne: false,
        conversation: null,
        error: null
    },
    action
) => {
    switch (action.type) {
        case REQUEST_CONVERSATIONS:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_CONVERSATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                conversations: action.conversations,
                total: action.total
            });
        case STATS_CONVERSATIONS:
            // if stats.status is empty, api return an array instead of an object...
            if (
                action.hasOwnProperty('stats') &&
                action.stats.hasOwnProperty('status') &&
                Array.isArray(action.stats.status)
            ) {
                action.stats.status = {};
            }
            return Object.assign({}, state, {
                stats: action.stats
            });
        case RANGE_CONVERSATIONS:
            return Object.assign({}, state, {
                offset: action.offset,
                limit: action.limit
            });
        case FILTER_CONVERSATIONS:
            return Object.assign({}, state, {
                filter: action.filter,
                offset: 0,
                search: ''
            });
        case SELECT_CONVERSATIONS:
            return Object.assign({}, state, { selected: action.selected });
        case SEARCH_CONVERSATIONS:
            return Object.assign({}, state, {
                search: action.search,
                offset: action.search === state.search ? state.offset : 0
            });
        case TOGGLE_FAVORITE_CONVERSATIONS:
            return Object.assign({}, state, {
                conversations: state.conversations.map((conversation) => {
                    if (conversation.id == action.id) {
                        conversation.set('favorite', action.favorite);
                        return new Conversation(conversation.attributes);
                    }
                    return conversation;
                })
            });
        case ARCHIVE_CONVERSATION:
            return removeConversation(state, action.id);
        case ARCHIVE_CONVERSATIONS:
            return removeConversationMultiple(state, action.ids);
        case UNARCHIVE_CONVERSATION:
            return removeConversation(state, action.id);
        case UNARCHIVE_CONVERSATIONS:
            return removeConversationMultiple(state, action.ids);
        case DELETE_CONVERSATION:
            return removeConversation(state, action.id);
        case DELETE_CONVERSATIONS:
            return removeConversationMultiple(state, action.ids);
        case REQUEST_CONVERSATION: {
            const convFromList = state.conversations.find((conv) => conv.id == action.id);
            return Object.assign({}, state, {
                conversation: convFromList,
                isFetchingOne: true,
                error: null
            });
        }
        case RECEIVE_CONVERSATION:
            return Object.assign({}, state, {
                // update conversation in list
                conversations: state.conversations.map((conversation) => {
                    if (conversation.id == action.conversation.id) {
                        return action.conversation;
                    }
                    return conversation;
                }),
                isFetchingOne: false,
                conversation: action.conversation,
                error: null
            });
        case FAILED_REQUEST_CONVERSATION:
            return Object.assign({}, state, {
                isFetchingOne: false,
                error: action.error
            });
        case UPDATE_CONVERSATION:
            // update state
            return Object.assign({}, state, {
                // update conversation updated date in conversation list
                conversations: sortConversations(
                    state.conversations.map((conversation) => {
                        if (conversation.id == action.conversation.id) {
                            return action.conversation;
                        }
                        return conversation;
                    })
                )
            });
        case EXCHANGE_UPDATE_SUCCESS: {
            // update conversation
            const conversation = new Conversation(state.conversation.attributes);
            const exchanges = conversation.get('exchanges').map((exchange) => {
                action.exchanges.forEach((exch) => {
                    if (exchange.id == exch.id) {
                        exchange = exch;
                    }
                });
                return exchange;
            });
            conversation.set('exchanges', exchanges);
            // get last update date for conversation's exchanges
            const lastUpdate = action.exchanges.reduce((memo, exch) => {
                if (moment(exch.get('last_modify_at')).isAfter(memo)) {
                    return exch.get('last_modify_at');
                }
                return memo;
            }, 0);
            // update conversation "updated_at"
            conversation.set('updated_at', lastUpdate);
            // update conversation in conversation list and resort
            const conversationList = sortConversations(
                state.conversations.map((conv) => {
                    if (conv.id == conversation.id) {
                        return conversation;
                    }
                    return conv;
                })
            );
            // update state
            return Object.assign({}, state, {
                // update conversation in list
                conversations: conversationList,
                isFetchingOne: false,
                conversation,
                error: null
            });
        }
        case EXCHANGE_UPDATE_FAILED:
            return Object.assign({}, state, {
                isFetchingOne: false,
                error: action.error
            });
        case EXCHANGE_FINALIZATION_FAILED:
            return Object.assign({}, state, {
                isFetchingOne: false
            });
        case CLEAR_CONVERSATION:
            return Object.assign({}, state, {
                conversation: null,
                error: null
            });
        default:
            return state;
    }
};

export default conversations;
