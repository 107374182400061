var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import i18n from '../../../../../i18n';
import FeatureModels from '../../../../../models/Feature';
import GuaranteesModal from '../modal/GuaranteesModal';
import { COMMON_FIELDS, ERROR_MESSAGES, IMAGES, STEP_TITLES } from './constants';
var routes = function (isCollection) { return ({
    1: {
        title: i18n.t('home:funnel.homeCompletion.nav.homeLayout'),
        steps: {
            0: {
                title: i18n.t('home:funnel.homeCreation.part.homeLayout.title'),
                description: i18n.t('home:funnel.homeCreation.part.homeLayout.body'),
                callout: {
                    title: i18n.t('home:funnel.homeCreation.part.homeLayout.calloutTitle'),
                    description: i18n.t('home:funnel.homeCreation.part.homeLayout.calloutBody')
                },
                img: IMAGES(isCollection).HOME,
                nextTitle: STEP_TITLES.START,
                excludedFromStepper: true,
                analyticsName: 'p1_describe_your_home'
            },
            1: __assign(__assign({}, COMMON_FIELDS.RADIO), { title: i18n.t('home:funnel.homeCreation.homeType.label'), fieldName: 'type', errorMessage: ERROR_MESSAGES.SELECT_OPTION, required: true, options: [
                    {
                        value: '1',
                        label: i18n.t('home:funnel.homeCreation.homeType.house.title'),
                        icon: 'house',
                        description: i18n.t('home:funnel.homeCreation.homeType.house.desc')
                    },
                    {
                        value: '2',
                        label: i18n.t('home:funnel.homeCreation.homeType.flat.title'),
                        icon: 'flat',
                        description: i18n.t('home:funnel.homeCreation.homeType.flat.desc')
                    }
                ], analyticsName: 'home_type' }),
            2: __assign(__assign({}, COMMON_FIELDS.RADIO), { title: i18n.t('home:funnel.homeCreation.accomodationType.label'), fieldName: 'shared_part', errorMessage: ERROR_MESSAGES.SELECT_OPTION, required: true, options: [
                    {
                        value: '1',
                        label: i18n.t('home:funnel.homeCreation.accomodationType.wholeUnit.title'),
                        icon: 'house',
                        description: i18n.t('home:funnel.homeCreation.accomodationType.wholeUnit.desc')
                    },
                    {
                        value: '2',
                        label: i18n.t('home:funnel.homeCreation.accomodationType.privateRoom.title'),
                        icon: 'private-room',
                        description: i18n.t('home:funnel.homeCreation.accomodationType.privateRoom.desc')
                    }
                ], analyticsName: 'accomodation_type' }),
            3: __assign(__assign({}, COMMON_FIELDS.RADIO), { title: i18n.t('home:funnel.homeCreation.residenceType.label'), fieldName: 'detail[residence_type]', errorMessage: ERROR_MESSAGES.SELECT_OPTION, required: true, options: [
                    {
                        value: '3',
                        label: i18n.t('home:funnel.homeCreation.residenceType.primary.title'),
                        icon: 'primary-home',
                        description: i18n.t('home:funnel.homeCreation.residenceType.primary.desc')
                    },
                    {
                        value: '4',
                        label: i18n.t('home:funnel.homeCreation.residenceType.secondary.title'),
                        icon: 'secondary-home',
                        description: i18n.t('home:funnel.homeCreation.residenceType.secondary.desc')
                    }
                ], analyticsName: 'residence_type' }),
            4: {
                title: i18n.t('home:funnel.homeCreation.address.title'),
                callout: {
                    description: i18n.t('home:funnel.homeCreation.address.calloutBody')
                },
                type: 'address',
                nextTitle: STEP_TITLES.CONFIRM_ADDRESS,
                backTitle: STEP_TITLES.BACK,
                required: true,
                fieldName: 'address',
                errorMessage: ERROR_MESSAGES.SELECT_ADDRESS,
                analyticsName: 'address'
            },
            4.1: __assign(__assign({}, COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:legal-registration-title'), type: 'legal-registration', nextTitle: STEP_TITLES.NEXT, backTitle: STEP_TITLES.BACK, analyticsName: 'legal_registration', excludedFromStepper: true }),
            5: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.surroundings.label'), description: i18n.t('home:funnel.homeCreation.surroundings.helper'), fieldName: 'feature[surrounding]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.SURROUNDING_TAGS; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), maxSelected: 2, analyticsName: 'surroundings' }),
            6: __assign(__assign({}, COMMON_FIELDS.RADIO), { title: i18n.t('home:funnel.homeCreation.touristSites.label'), description: i18n.t('home:funnel.homeCreation.touristSites.helper'), fieldName: 'detail[location_type]', errorMessage: ERROR_MESSAGES.SELECT_OPTION, required: true, options: [
                    {
                        value: '5',
                        label: i18n.t('global:global.distanceTouristSites.siteHeart')
                    },
                    {
                        value: '4',
                        label: i18n.t('global:global.touristSites.international30')
                    },
                    {
                        value: '3',
                        label: i18n.t('global:global.touristSites.national30')
                    },
                    {
                        value: '2',
                        label: i18n.t('global:global.distanceTouristSites.local30')
                    },
                    {
                        value: '1',
                        label: i18n.t('global:global.distanceTouristSites.more30')
                    }
                ], analyticsName: 'distance_touristic_places' }),
            7: {
                title: i18n.t('home:funnel.homeCreation.surface.label'),
                type: 'surface',
                required: true,
                fieldName: 'detail[size]',
                validationRules: [
                    {
                        rule: 'positiveNumber',
                        fieldNames: ['detail[size]'],
                        errorMessage: ERROR_MESSAGES.POSITIVE_NUMBER
                    }
                ],
                nextTitle: STEP_TITLES.NEXT,
                backTitle: STEP_TITLES.BACK,
                analyticsName: 'surface'
            },
            8: __assign(__assign({}, COMMON_FIELDS.COUNTER), { title: i18n.t('home:funnel.homeCreation.nbRooms.label'), fieldName: ['detail[bedroom_nb]', 'detail[bathroom_nb]'], options: [
                    {
                        fieldName: 'detail[bedroom_nb]',
                        value: 'bedroom_nb',
                        label: i18n.t('global:global.bedrooms'),
                        icon: 'bedroom',
                        placeholderForZero: i18n.t('global:global.studio')
                    },
                    {
                        fieldName: 'detail[bathroom_nb]',
                        value: 'bathroom_nb',
                        label: i18n.t('global:global.bathrooms'),
                        errorMessage: ERROR_MESSAGES.ADD_ROOM,
                        required: true,
                        icon: 'bathtub'
                    }
                ], analyticsName: 'nbr_of_rooms' }),
            9: __assign(__assign({}, COMMON_FIELDS.COUNTER), { title: i18n.t('home:funnel.homeCreation.nbBeds.label'), callout: {
                    description: i18n.t('home:funnel.homeCreation.nbBeds.callout'),
                    textError: i18n.t('home:funnel.homeCreation.nbBeds.unfilledError'),
                    hasIcon: true
                }, fieldName: [
                    'detail[big_double_bed]',
                    'detail[double_bed]',
                    'detail[single_bed]',
                    'detail[children_bed]',
                    'detail[baby_bed]',
                    'detail[double_bed_up]',
                    'detail[single_bed_up]',
                    'detail[children_bed_up]',
                    'detail[baby_bed_up]'
                ], validationRules: [
                    {
                        rule: 'requiredOneOf',
                        fieldNames: [
                            'detail[big_double_bed]',
                            'detail[double_bed]',
                            'detail[single_bed]',
                            'detail[double_bed_up]',
                            'detail[single_bed_up]'
                        ]
                    }
                ], inputGroup: [
                    {
                        title: i18n.t('home:funnel.homeCreation.nbBeds.beds.title')
                    },
                    {
                        title: i18n.t('home:funnel.homeCreation.nbBeds.putupBeds.title'),
                        description: i18n.t('home:funnel.homeCreation.nbBeds.putupBeds.subtitle')
                    }
                ], options: [
                    {
                        fieldName: 'detail[big_double_bed]',
                        value: 'big_double_bed',
                        label: i18n.t('global:global.beds.kingSize'),
                        icon: 'double-bed',
                        description: i18n.t('global:global.beds.kingSize.desc'),
                        group: 1
                    },
                    {
                        fieldName: 'detail[double_bed]',
                        value: 'double_bed',
                        label: i18n.t('global:global.beds.queenSize'),
                        icon: 'double-bed',
                        description: i18n.t('global:global.beds.queenSize.desc'),
                        group: 1
                    },
                    {
                        fieldName: 'detail[single_bed]',
                        value: 'single_bed',
                        label: i18n.t('global:global.beds.single'),
                        icon: 'simple-bed',
                        description: i18n.t('global:global.beds.single.desc'),
                        border: true,
                        group: 1
                    },
                    {
                        fieldName: 'detail[children_bed]',
                        value: 'children_bed',
                        label: i18n.t('global:global.beds.child'),
                        icon: 'simple-bed',
                        description: i18n.t('global:global.beds.child.desc'),
                        group: 1
                    },
                    {
                        fieldName: 'detail[baby_bed]',
                        value: 'baby_bed',
                        label: i18n.t('global:global.beds.crib'),
                        icon: 'baby-bed',
                        description: i18n.t('global:global.beds.crib.desc'),
                        group: 1
                    },
                    {
                        fieldName: 'detail[double_bed_up]',
                        value: 'double_bed_up',
                        label: i18n.t('global:global.putUpBeds.double'),
                        icon: 'double-bed-up',
                        description: i18n.t('global:global.putUpBeds.double.desc'),
                        group: 2
                    },
                    {
                        fieldName: 'detail[single_bed_up]',
                        value: 'single_bed_up',
                        label: i18n.t('global:global.putUpBeds.single'),
                        icon: 'simple-bed-up',
                        description: i18n.t('global:global.putUpBeds.single.desc'),
                        border: true,
                        group: 2
                    },
                    {
                        fieldName: 'detail[children_bed_up]',
                        value: 'children_bed_up',
                        label: i18n.t('global:global.putUpBeds.child'),
                        icon: 'simple-bed-up',
                        description: i18n.t('global:global.putUpBeds.child.desc'),
                        group: 2
                    },
                    {
                        fieldName: 'detail[baby_bed_up]',
                        value: 'baby_bed_up',
                        label: i18n.t('global:global.putUpBeds.crib'),
                        icon: 'baby-bed',
                        description: i18n.t('global:global.putUpBeds.crib.desc'),
                        group: 2
                    }
                ], analyticsName: 'type_of_beds' })
        }
    },
    2: {
        title: i18n.t('home:funnel.homeCompletion.nav.amenities'),
        steps: {
            0: {
                title: i18n.t('home:funnel.homeCreation.part.amenities.title'),
                description: i18n.t('home:funnel.homeCreation.part.amenities.body'),
                img: IMAGES(isCollection).AMENITIES,
                callout: {
                    description: i18n.t('home:funnel.homeCreation.part.amenities.calloutBody'),
                    modal: {
                        text: i18n.t('home:funnel.homeCreation.part.amenities.calloutLink'),
                        title: i18n.t('home:modal.homeCompletion.guarantees.title'),
                        contentModal: React.createElement(GuaranteesModal, { isCollection: isCollection }),
                        confirmActionText: i18n.t('global:global.cta.understood')
                    }
                },
                nextTitle: STEP_TITLES.NEXT,
                backTitle: STEP_TITLES.BACK,
                excludedFromStepper: true,
                analyticsName: 'p2_what_equipment_do_you_have_at_home?'
            },
            1: __assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), { title: i18n.t('home:funnel.homeCreation.basicAmenities.label'), fieldName: 'feature[practical]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_PRACTICAL; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'practical_amenities' }),
            2: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.multimediaAmenities.label'), description: i18n.t('home:funnel.homeCreation.multimediaAmenities.helper'), fieldName: 'feature[multimedia]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_MULTIMEDIA; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'multimedia_amenities' }),
            3: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.uniqueAmenities.label'), description: i18n.t('home:funnel.homeCreation.uniqueAmenities.helper'), fieldName: 'feature[various]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_VARIOUS; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'unique_amenities' }),
            4: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.outsideAmenities.label'), description: i18n.t('home:funnel.homeCreation.outsideAmenities.helper'), fieldName: 'feature[outdoor]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_OUTDOOR; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'outside_amenities' }),
            5: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.kidAmenities.label'), fieldName: 'feature[kids]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_KIDS; })
                    .filter(function (feature) { return feature.value !== FeatureModels.AMENITIES_BIT.SIXTEEN_BITS; })
                    .map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'kids-friendly_amenities' }),
            6: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.remoteAmenities.label'), fieldName: 'feature[remote]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_REMOTE; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'remote-work_amenities' }),
            7: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.transportAmenities.label'), callout: {
                    hasIcon: true,
                    description: i18n.t('home:funnel.homeCreation.transportAmenities.calloutBody')
                }, fieldName: 'feature[transportation]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.OPTS_TRANSPORTATION; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'transport_amenities' }),
            8: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.sustainableAmenities.label'), description: i18n.t('home:funnel.homeCreation.sustainableAmenities.helper'), fieldName: 'feature[eco]', options: FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_ECO; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), analyticsName: 'eco-friendly_amenities' }),
            9: __assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), { title: i18n.t('home:funnel.homeCreation.petsitting.label'), description: i18n.t('home:funnel.homeCreation.petsitting.helper'), fieldName: 'feature[animal]', options: __spreadArray([
                    {
                        value: '0',
                        label: i18n.t('global:global.pets.none'),
                        icon: 'cancel'
                    }
                ], FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.AMENITIES_ANIMALS; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label) })); }), true), analyticsName: 'animals' }),
            10: __assign(__assign(__assign({}, COMMON_FIELDS.CHECKBOX_GROUP), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.preferences.label'), description: i18n.t('home:funnel.homeCreation.preferences.helper'), fieldName: ['feature[favor]', 'feature[rule]'], options: __spreadArray(__spreadArray([], FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.FAVOR; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label), fieldName: 'feature[favor]' })); }), true), FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.RULE; }).map(function (feature) { return (__assign(__assign({}, feature), { label: i18n.t(feature.label), fieldName: 'feature[rule]' })); }), true), analyticsName: 'preferences_and_requests' })
        }
    },
    3: {
        title: i18n.t('home:funnel.homeCompletion.nav.homeDesc'),
        steps: {
            0: {
                title: i18n.t('home:funnel.homeCreation.part.homeDesc.title'),
                description: i18n.t('home:funnel.homeCreation.part.homeDesc.body'),
                img: IMAGES(isCollection).DESCRIPTION,
                callout: {
                    title: i18n.t('home:funnel.homeCreation.part.homeDesc.calloutTitle'),
                    description: i18n.t('home:funnel.homeCreation.part.homeDesc.calloutBody')
                },
                nextTitle: STEP_TITLES.NEXT,
                backTitle: STEP_TITLES.BACK,
                excludedFromStepper: true,
                analyticsName: 'p3_what_makes_your_home_unique?'
            },
            1: __assign(__assign({}, COMMON_FIELDS.TEXT), { title: i18n.t('home:funnel.homeCreation.listingTitle.label'), description: i18n.t('home:funnel.homeCreation.listingTitle.helper'), fieldName: 'descriptions[0][title]', examples: [{ text: i18n.t('home:funnel.homeCreation.listingTitle.calloutBody') }], hidden: [
                    {
                        fieldName: 'descriptions[0][locale]'
                    }
                ], analyticsName: 'listing_name' }),
            2: __assign(__assign(__assign({}, COMMON_FIELDS.TEXT), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.homeDesc.label'), description: i18n.t('home:funnel.homeCreation.homeDesc.helper'), fieldName: 'descriptions[0][good_feature]', examples: [
                    {
                        text: i18n.t('home:funnel.homeCreation.homeDesc.calloutBody')
                    }
                ], hidden: [
                    {
                        fieldName: 'descriptions[0][locale]'
                    }
                ], analyticsName: 'home_description' }),
            3: __assign(__assign(__assign({}, COMMON_FIELDS.TEXT), COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.neighborhoodDesc.label'), description: i18n.t('home:funnel.homeCreation.neighborhoodDesc.helper'), fieldName: 'descriptions[0][good_place]', examples: [
                    {
                        text: i18n.t('home:funnel.homeCreation.neighborhoodDesc.calloutBody')
                    }
                ], hidden: [
                    {
                        fieldName: 'descriptions[0][locale]'
                    }
                ], analyticsName: 'neighborhood_description' })
        }
    },
    4: {
        title: i18n.t('home:funnel.homeCompletion.nav.photos'),
        steps: {
            0: {
                title: i18n.t('home:funnel.homeCreation.part.photos.title'),
                description: i18n.t('home:funnel.homeCreation.part.photos.body'),
                img: IMAGES(isCollection).PHOTOS,
                callout: {
                    title: i18n.t('home:funnel.homeCreation.part.photos.calloutTitle'),
                    description: i18n.t('home:funnel.homeCreation.part.photos.calloutBody')
                },
                nextTitle: STEP_TITLES.NEXT,
                backTitle: STEP_TITLES.BACK,
                excludedFromStepper: true,
                analyticsName: 'p4_what_does_your_home_look_like?'
            },
            1: __assign(__assign({}, COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.photos.label'), description: i18n.t('home:funnel.homeCreation.photos.helper'), type: 'file-upload', nextTitle: STEP_TITLES.NEXT, backTitle: STEP_TITLES.BACK, analyticsName: 'photos' })
        }
    },
    5: {
        title: i18n.t('home:funnel.homeCompletion.nav.calendar'),
        steps: {
            0: {
                title: i18n.t('home:funnel.homeCreation.part.calendar.title'),
                description: i18n.t('home:funnel.homeCreation.part.calendar.body'),
                img: IMAGES(isCollection).AVAILABILITIES,
                callout: {
                    title: i18n.t('home:funnel.homeCreation.part.calendar.calloutTitle'),
                    description: i18n.t('home:funnel.homeCreation.part.calendar.calloutBody')
                },
                nextTitle: STEP_TITLES.NEXT,
                backTitle: STEP_TITLES.BACK,
                excludedFromStepper: true,
                analyticsName: 'p5_when_is_your_home_available?'
            },
            1: __assign(__assign({}, COMMON_FIELDS.SKIP_STEP), { title: i18n.t('home:funnel.homeCreation.calendar.label'), description: i18n.t('home:funnel.homeCreation.calendar.helper'), type: 'calendar', nextTitle: STEP_TITLES.NEXT, backTitle: STEP_TITLES.BACK, analyticsName: 'calendar' })
        }
    }
}); };
export var routesConfig = routes(false);
export var routesConfigCollection = routes(true);
