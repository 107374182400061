import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { Callout, Text, Flex, Button, Input } from '@homeexchange/design';

export const getTextLegaleRegistrations = (baseKeyLocalise, legalRegistrationName, fieldName = '') => {
    const keysToTry = [
        `${baseKeyLocalise}-${fieldName}-${legalRegistrationName}`,
        `${baseKeyLocalise}-${fieldName}`,
        `${baseKeyLocalise}-${legalRegistrationName}`,
        `${baseKeyLocalise}`
    ];

    const existingKey = keysToTry.find((key) => i18n.exists(key));
    return existingKey ? i18n.t(existingKey) : i18n.t(baseKeyLocalise);
};

const renderInput = (
    data,
    state,
    setState,
    legalRegistrationName,
    { renderSubmitBtn, sending, canSubmit, onSubmit }
) => {
    const { name } = data;
    const value = state[name];

    return (
        <>
            <Flex gap="2" isFullWidth flexWrap="nowrap" alignItems="end" className="wrapper-input-submit">
                <Input
                    label={getTextLegaleRegistrations(
                        'home:legal-registration-label-input',
                        legalRegistrationName,
                        name
                    )}
                    helperText={getTextLegaleRegistrations(
                        'home:legal-registration-helper',
                        legalRegistrationName,
                        name
                    )}
                    value={value}
                    onChange={(e) => setState({ ...state, [name]: e.target.value })}
                    isFullWidth
                />
                {renderSubmitBtn && (
                    <Button type="submit" isLoading={sending} disabled={!canSubmit} onClick={onSubmit}>
                        {getTextLegaleRegistrations('common:submit', legalRegistrationName)}
                    </Button>
                )}
            </Flex>
        </>
    );
};

const LegalRegistration = ({
    onSubmit,
    fields,
    state,
    setState,
    canSubmit,
    renderSubmitBtn = false,
    sending = false,
    legalRegistrationName = ''
}) => {
    const currentLocale = i18n.language;

    return (
        <div className={'wrapper-container'}>
            {!renderSubmitBtn && (
                <h1>{getTextLegaleRegistrations('home:legal-registration-title', legalRegistrationName)}</h1>
            )}
            <Flex gap="4">
                <Callout status="info" hasIcon={renderSubmitBtn}>
                    <Flex gap="4">
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: getTextLegaleRegistrations(
                                    'home:legal-registration-description',
                                    legalRegistrationName
                                )
                            }}
                        />
                        <Button
                            href={`https://help.homeexchange.com/hc/${
                                ['fr', 'nl'].includes(currentLocale) ? currentLocale : 'en-us'
                            }/articles/13532862813341`}
                            color="tertiary"
                            target="_blank"
                        >
                            {getTextLegaleRegistrations('home:legal-registration-cta', legalRegistrationName)}
                        </Button>
                    </Flex>
                </Callout>
                <Flex gap="2" isFullWidth>
                    {fields.map((field) => {
                        switch (field.type) {
                            case 'text':
                                return renderInput(field, state, setState, legalRegistrationName, {
                                    renderSubmitBtn,
                                    sending,
                                    canSubmit,
                                    onSubmit
                                });
                            default:
                                return null;
                        }
                    })}
                </Flex>
            </Flex>
            <div
                className="text-issue"
                dangerouslySetInnerHTML={{
                    __html: getTextLegaleRegistrations(
                        'home:legal-registration-support-text',
                        legalRegistrationName
                    )
                }}
            ></div>
        </div>
    );
};

LegalRegistration.propTypes = {
    fields: PropTypes.array,
    setState: PropTypes.func,
    state: PropTypes.object,
    validation: PropTypes.object,
    canSubmit: PropTypes.bool,
    renderSubmitBtn: PropTypes.bool,
    onSubmit: PropTypes.func,
    hideBottom: PropTypes.bool,
    sending: PropTypes.bool,
    legalRegistrationName: PropTypes.string
};

export default LegalRegistration;
