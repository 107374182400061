import 'isomorphic-fetch';

import Common from '../api/Common';
import { redirect } from '../history';

export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const RESET_TOKEN = 'RESET_TOKEN';
export const TOKEN_RESPONSE = 'TOKEN_RESPONSE';

export function fetchToken(body) {
    return fetch(`${Common.getApiUri()}/v1/oauth/v2/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'include'
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        const error = new Error(response.statusText);
        error.statusCode = response.status;
        throw error;
    });
}

function computeClockSkew(response) {
    if (response instanceof Response && response.headers.get('Date')) {
        return new Date(response.headers.get('Date')).getTime() - new Date().getTime();
    } else if (response instanceof XMLHttpRequest) {
        return new Date(response.getResponseHeader('Date')).getTime() - new Date().getTime();
    } else {
        return 0;
    }
}

export function handleTokenResponse(tokenResponse) {
    return (
        tokenResponse instanceof Response ? tokenResponse.json() : Promise.resolve(tokenResponse.response)
    ).then((json) => ({
        type: TOKEN_RESPONSE,
        payload: typeof json === 'string' ? JSON.parse(json) : json,
        clockCorrection: computeClockSkew(tokenResponse)
    }));
}

export function resetToken() {
    return {
        type: RESET_TOKEN
    };
}

export function refreshToken() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return new Promise((resolve, reject) => {
            // should have refresh token
            if (auth.refreshToken) {
                resolve();
            } else {
                reject(new Error('No refresh token.'));
            }
        })
            .then(() =>
                fetchToken({
                    client_id: process.env.CLIENT_ID,
                    client_secret: process.env.CLIENT_SECRET,
                    grant_type: 'refresh_token',
                    refresh_token: auth.refreshToken
                })
            )
            .then((response) => handleTokenResponse(response).then(dispatch))
            .catch((err) => {
                dispatch(resetToken());
                if (err.statusCode >= 400 && err.statusCode < 500) {
                    // try to reload page to update tokens
                    document.location.reload();
                } else {
                    // logout user
                    redirect('/auth/logout');
                }
                return Promise.reject(new Error('Failed to refresh token.'));
            });
    };
}

export const handleUnauthorized = async (dispatch, func) => {
    try {
        return await func();
    } catch (err) {
        if (err.code === 401) {
            return dispatch(refreshToken()).then(func);
        } else {
            throw err;
        }
    }
};

export function handleRequestTokenFailure(request) {
    return (dispatch) => {
        if (request.status == 401) {
            console.info('Access token expired. Try to refresh it.');
            return dispatch(refreshToken());
        }
        return dispatch(resetToken());
    };
}

export function requestToken(username, password) {
    return (dispatch) =>
        fetchToken({
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            grant_type: 'password',
            username,
            password
        }).then(
            (response) => handleTokenResponse(response).then(dispatch),
            (request) => dispatch(handleRequestTokenFailure(request))
        );
}

export function requestTokenFacebook(fbId, fbToken) {
    return (dispatch) =>
        fetchToken({
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            grant_type: 'http://api.guesttoguest.com/grants/facebook',
            fb_id: fbId,
            fb_token: fbToken
        }).then(
            (response) => handleTokenResponse(response).then(dispatch),
            (request) => dispatch(handleRequestTokenFailure(request))
        );
}

export function requestTokenApple(authorizationCode, firstName, lastName, localeUser) {
    return (dispatch) =>
        fetchToken({
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            grant_type: 'http://api.guesttoguest.com/grants/apple',
            authorization_code: authorizationCode,
            firstname: firstName,
            lastname: lastName,
            locale: localeUser
        }).then(
            (response) => handleTokenResponse(response).then(dispatch),
            (request) => dispatch(handleRequestTokenFailure(request))
        );
}
