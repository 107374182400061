import i18n from '../../../../../i18n';
import Utils from '../../../../../utils/utils';
export var STEP_TITLES = {
    NEXT: i18n.t('global:global.continue'),
    BACK: i18n.t('global:global.back'),
    SKIP: i18n.t('global:global.skip'),
    START: i18n.t('global:global.start'),
    CONFIRM_ADDRESS: i18n.t('home:funnel.homeCreation.address.cta')
};
export var COMMON_FIELDS = {
    CHECKBOX_GROUP: {
        type: 'checkbox-group',
        nextTitle: STEP_TITLES.NEXT,
        backTitle: STEP_TITLES.BACK
    },
    RADIO: {
        type: 'radio',
        nextTitle: STEP_TITLES.NEXT,
        backTitle: STEP_TITLES.BACK
    },
    TEXT: {
        type: 'text',
        nextTitle: STEP_TITLES.NEXT,
        backTitle: STEP_TITLES.BACK
    },
    COUNTER: {
        type: 'counter',
        nextTitle: STEP_TITLES.NEXT,
        backTitle: STEP_TITLES.BACK
    },
    SKIP_STEP: {
        skipStep: true,
        skipTitle: STEP_TITLES.SKIP
    }
};
export var IMAGES = function (isCollection) {
    return ({
        HOME: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/home").concat(isCollection ? '-collection' : '', ".svg"),
        AMENITIES: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/amenities").concat(isCollection ? '-collection' : '', ".svg"),
        DESCRIPTION: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/description").concat(isCollection ? '-collection' : '', ".svg"),
        PHOTOS: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/photos").concat(isCollection ? '-collection' : '', ".svg"),
        AVAILABILITIES: "".concat(Utils.getCloudfrontDomain(), "/images/website/migration/funnel/availabilities").concat(isCollection ? '-collection' : '', ".svg")
    });
};
export var ERROR_MESSAGES = {
    SELECT_OPTION: i18n.t('home:funnel.homeCreation.touristSites.errorBlank'),
    SELECT_ADDRESS: i18n.t('home:funnel.homeCreation.address.field.errorBlank'),
    ADD_ROOM: i18n.t('home:funnel.homeCreation.nbRooms.bedrooms.errorBlank'),
    POSITIVE_NUMBER: i18n.t('home:funnel.homeCreation.surface.errorPositive')
};
