import { getStayRequest, getExchangeConfig, updateStayRequestDates, changeStayRequestToReciprocal, changeStayRequestToNonReciprocal, preapproveExchange, cancelPreApprobation, createStayRequest as baseCreateStayRequest } from '@homeexchange/conversations';
import { exchangeUpdated, exchangeUpdateFailed, fetchConversation } from './conversations';
import { handleUnauthorized } from './auth';
export var RECEIVE_STAY_REQUEST_CONFIG = 'RECEIVE_STAY_REQUEST_CONFIG';
export var RECEIVE_STAY_REQUESTS_FOR_CONVERSATION_ID = 'RECEIVE_STAY_REQUESTS_FOR_CONVERSATION_ID';
export var RECEIVE_UPDATE_STAY_REQUEST_TO_RECIPROCAL = 'RECEIVE_UPDATE_STAY_REQUEST_TO_RECIPROCAL';
export function receiveStayRequestConfig(response) {
    return {
        type: RECEIVE_STAY_REQUEST_CONFIG,
        nbDaysPreapprobationExpiration: response.NB_DAYS_PREAPPROVAL_EXPIRATION
    };
}
export function fetchStayRequestConfig() {
    return function (dispatch, getState) {
        return handleUnauthorized(dispatch, function () {
            var _a = getState().auth, accessToken = _a.accessToken, refreshToken = _a.refreshToken;
            return getExchangeConfig({ access_token: accessToken, refresh_token: refreshToken });
        }).then(function (response) {
            dispatch(receiveStayRequestConfig(response));
        });
    };
}
export function receiveStayRequestsForConversation(response, id) {
    return {
        type: RECEIVE_STAY_REQUESTS_FOR_CONVERSATION_ID,
        stayRequests: response,
        id: id
    };
}
export function fetchStayRequestsForConversation(conversation) {
    return function (dispatch, getState) {
        if (!conversation || conversation.isMutual()) {
            return;
        }
        return handleUnauthorized(dispatch, function () {
            var _a = getState().auth, accessToken = _a.accessToken, refreshToken = _a.refreshToken;
            return getStayRequest(conversation.id, {
                access_token: accessToken,
                refresh_token: refreshToken
            });
        }).then(function (response) {
            dispatch(receiveStayRequestsForConversation(response, conversation.id));
        });
    };
}
export function updateDatesForConversation(conversationId, exchangeId, startOn, endOn) {
    return function (dispatch, getState) {
        return handleUnauthorized(dispatch, function () {
            var _a = getState().auth, accessToken = _a.accessToken, refreshToken = _a.refreshToken;
            return updateStayRequestDates(conversationId, { exchangeId: exchangeId, startOn: startOn, endOn: endOn }, { access_token: accessToken, refresh_token: refreshToken });
        })
            .then(function (response) {
            dispatch(exchangeUpdated(response));
        })
            .catch(function (response) {
            dispatch(exchangeUpdateFailed(response));
            throw response;
        });
    };
}
export function receiveUpdateStayRequestToReciprocal(conversationId) {
    return {
        type: RECEIVE_UPDATE_STAY_REQUEST_TO_RECIPROCAL,
        conversationId: conversationId
    };
}
export function updateStayRequestToReciprocal(conversationId, guestHomeId) {
    return function (dispatch, getState) {
        return handleUnauthorized(dispatch, function () {
            var _a = getState().auth, accessToken = _a.accessToken, refreshToken = _a.refreshToken;
            return changeStayRequestToReciprocal(conversationId, guestHomeId, {
                access_token: accessToken,
                refresh_token: refreshToken
            });
        })
            .then(function () {
            dispatch(fetchConversation(conversationId));
            dispatch(receiveUpdateStayRequestToReciprocal(conversationId));
        })
            .catch(function (response) { return dispatch(exchangeUpdateFailed(response)); });
    };
}
export function updateStayRequestToNonReciprocal(conversationId, exchangeIdToDelete) {
    return function (dispatch, getState) {
        var conversation = getState().conversations.conversations.find(function (_a) {
            var id = _a.id;
            return id === conversationId;
        });
        return handleUnauthorized(dispatch, function () {
            var _a = getState().auth, accessToken = _a.accessToken, refreshToken = _a.refreshToken;
            return changeStayRequestToNonReciprocal(conversationId, exchangeIdToDelete, {
                access_token: accessToken,
                refresh_token: refreshToken
            });
        })
            .then(function () {
            dispatch(fetchConversation(conversationId));
            dispatch(fetchStayRequestsForConversation(conversation));
        })
            .catch(function (response) { return dispatch(exchangeUpdateFailed(response)); });
    };
}
export function preapproveStayRequest(conversationId) {
    return function (dispatch, getState) {
        return handleUnauthorized(dispatch, function () {
            var accessToken = getState().auth.accessToken;
            return preapproveExchange(conversationId, {
                access_token: accessToken
            });
        });
    };
}
export function cancelStayRequest(conversationId) {
    return function (dispatch, getState) {
        return handleUnauthorized(dispatch, function () {
            var accessToken = getState().auth.accessToken;
            return cancelPreApprobation(conversationId, {
                access_token: accessToken
            });
        });
    };
}
export function createStayRequest(data) {
    return function (dispatch, getState) {
        return handleUnauthorized(dispatch, function () {
            var accessToken = getState().auth.accessToken;
            return baseCreateStayRequest(data, {
                access_token: accessToken
            });
        });
    };
}
