import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../../i18n';
import User from '../../../models/User';

import Loading from '../../common/Loading';
import { Button, Divider, Flex, Link, Text } from '@homeexchange/design';

const displayNextSteps = (hasEverSubscribed, achievements, user) => {
    if (!achievements) {
        return null;
    }

    return (
        <Flex isFullWidth gap={'0'}>
            {!hasEverSubscribed && (
                <Text isBold className="achievement-title">
                    {i18n.t('global:gpMenu.nextSteps', { count: achievements.length })}
                </Text>
            )}
            {hasEverSubscribed && (
                <Text isBold className="achievement-title">
                    {i18n.t('global:gpMenu.getMore')}
                </Text>
            )}
            {achievements.map((achievement) => {
                let step_link = i18n.t(achievement.step_link);
                if (achievement.step_translated === 'gpMenu.completeHome') {
                    const homes = user.getHomesNotCompleted();
                    if (homes?.length > 0) {
                        step_link = homes[0].getEditUrl();
                    }
                }
                return (
                    <Flex isFullWidth flexGrow key={achievement.step_translated}>
                        <Link href={step_link} noUnderline>
                            <Flex justifyContent={'space-between'} isFullWidth>
                                <Text>{i18n.t(`global:${achievement.step_translated}`)}</Text>
                                <Text isSpan>
                                    <Text isBold isSpan color={'secondary'}>
                                        +{achievement.value}
                                    </Text>{' '}
                                    GP
                                </Text>
                            </Flex>
                        </Link>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export const MenuGuestpoints = ({ user, transfers }) => {
    if (!user || !transfers) {
        return (
            <div
                className="dropdown-menu scrollable-menu moving-hover gp-menu"
                role="menu"
                id="guestpoint_elt"
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="dropdown-menu scrollable-menu moving-hover gp-menu" role="menu" id="guestpoint_elt">
            <Flex gap={'4'}>
                <Flex justifyContent={'space-between'} isFullWidth>
                    <Text isBold>{i18n.t('global:gpMenu.yourGP')}</Text>
                    <Text isSpan>
                        <Text isBold isSpan color={'secondary'}>
                            {transfers.balance}
                        </Text>{' '}
                        GP
                    </Text>
                </Flex>
                <Divider smallSpace={true} className={'no-margin'} />
                {displayNextSteps(user.hasAlreadySubscribed(), transfers.achievements, user)}
                {!user.hasAlreadySubscribed() && (
                    <>
                        <Divider smallSpace={true} className={'no-margin'} />
                        <Flex gap={'1'}>
                            <Text isBold>{i18n.t('global:gpMenu.introGP.title')}</Text>
                            <Text>{i18n.t('global:gpMenu.introGP.desc')}</Text>
                        </Flex>
                    </>
                )}
                <Button
                    isFullWidth
                    color={'tertiary'}
                    href={i18n.t('url:url.gp.history')}
                    className="gp-menu-button"
                >
                    {i18n.t('global:gpMenu.seeHistory.cta')}
                </Button>
            </Flex>
        </div>
    );
};

MenuGuestpoints.propTypes = {
    user: PropTypes.instanceOf(User),
    transfers: PropTypes.arrayOf(
        PropTypes.shape({
            balance: PropTypes.number,
            achievements: PropTypes.array
        })
    )
};

const mapStateToProps = (state) => ({
    user: state.user,
    transfers: state.transfers
});

export default connect(mapStateToProps)(MenuGuestpoints);
