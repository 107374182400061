import PropTypes from 'prop-types';
import React from 'react';
import { Radio } from '@homeexchange/design';
import Home from '../../models/Home';
import i18n from '../../i18n';

const CalendarSwitchButtons = ({ contactableUnavailablePeriods, toggleContactableUnavailablePeriods }) => {
    const handleChange = async (value, name, track) => {
        toggleContactableUnavailablePeriods({ value, name, track });
    };

    return (
        <div>
            <Radio
                label={i18n.t('home:title-contact-radio-section')}
                onChange={(value) => handleChange(value, 'contact_allowed', 'contact_allowed_changed')}
                value={contactableUnavailablePeriods}
                name="contact_allowed"
            >
                <React.Fragment key=".0">
                    <Radio.Item
                        label={i18n.t('home:title-contact-radio-section-label-unavailable')}
                        description={i18n.t('home:title-contact-radio-section-description-unavailable')}
                        id="1"
                        value={Home.CONTACT_ALLOWED_ON_UNAVAILABLE}
                    />
                    <Radio.Item
                        label={i18n.t('home:title-contact-radio-section-label-available')}
                        description={i18n.t('home:title-contact-radio-section-description-available')}
                        id="2"
                        value={Home.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE}
                    />
                </React.Fragment>
            </Radio>
        </div>
    );
};

CalendarSwitchButtons.propTypes = {
    contactableUnavailablePeriods: PropTypes.oneOf([
        Home.CONTACT_ALLOWED_ON_UNAVAILABLE,
        Home.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE
    ]).isRequired,
    toggleContactableUnavailablePeriods: PropTypes.func.isRequired
};

CalendarSwitchButtons.defaultProps = {
    contactableUnavailablePeriods: Home.CONTACT_ALLOWED_ON_UNAVAILABLE
};

export default CalendarSwitchButtons;
