import Api from '../api/Api';
import FakeApi from '../api/FakeApi';
import Message from '../models/Message';
import Tour from '../utils/Tour';

export const REQUEST_MESSAGES = 'REQUEST_MESSAGES';
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const SENDING_MESSAGE = 'SENDING_MESSAGE';
export const FAIL_SEND_MESSAGE = 'FAIL_SEND_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export function requestMessages() {
    return {
        type: REQUEST_MESSAGES
    };
}

export function receiveMessages(response) {
    return {
        type: RECEIVE_MESSAGES,
        messages: response.map((message) => new Message(message))
    };
}

export function sendingMessage(message) {
    return {
        type: SENDING_MESSAGE,
        message
    };
}

export function addMessage(message) {
    message = new Message(message);
    return {
        type: ADD_MESSAGE,
        message
    };
}

export function failSendMessage() {
    return {
        type: FAIL_SEND_MESSAGE
    };
}

export function clearMessages() {
    return {
        type: CLEAR_MESSAGES
    };
}

export function fetchMessages(filters, offset, limit, orderBy) {
    return (dispatch) => {
        dispatch(requestMessages());
        return Tour.shouldUseFakeApi('messaging_tour', 'Conversation.getMessages').then(
            (shouldUseFakeApi) => {
                if (shouldUseFakeApi) {
                    return FakeApi.MessagingTour.Conversation.getMessages(
                        filters,
                        offset,
                        limit,
                        orderBy
                    ).then((response) => dispatch(receiveMessages(response)));
                } else {
                    return Api.Conversation.getMessagesGraphQL(filters.conversation).then((response) => {
                        if (response.data) {
                            dispatch(receiveMessages(response.data.messages));
                        } else {
                            dispatch(receiveMessages(response));
                        }
                    });
                }
            }
        );
    };
}

export function sendMessage(conversationId, message) {
    return (dispatch) => {
        dispatch(sendingMessage(message));
        return Api.Conversation.sendMessage({
            content: message,
            conversation: conversationId
        })
            .then((mesg) => dispatch(addMessage(mesg)))
            .catch((error) => {
                dispatch(failSendMessage());
                return Promise.reject(error);
            });
    };
}
