import Api from '../api/Api';
import FakeApi from '../api/FakeApi';
import Model from '../models/Model';
import Conversation from '../models/Conversation';
import Exchange from '../models/Exchange';
import Tour from '../utils/Tour';

import i18n from '../i18n';
import { updateStayRequestToReciprocal } from './stayRequest';

// current conversation request
export let request; // eslint-disable-line

export const STATS_CONVERSATIONS = 'STATS_CONVERSATIONS';
export const REQUEST_CONVERSATIONS = 'REQUEST_CONVERSATIONS';
export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS';
export const RANGE_CONVERSATIONS = 'RANGE_CONVERSATIONS';
export const FILTER_CONVERSATIONS = 'FILTER_CONVERSATIONS';
export const SELECT_CONVERSATIONS = 'SELECT_CONVERSATIONS';
export const SEARCH_CONVERSATIONS = 'SEARCH_CONVERSATIONS';
export const TOGGLE_FAVORITE_CONVERSATIONS = 'TOGGLE_FAVORITE_CONVERSATIONS';
export const ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION';
export const ARCHIVE_CONVERSATIONS = 'ARCHIVE_CONVERSATIONS';
export const UNARCHIVE_CONVERSATION = 'UNARCHIVE_CONVERSATION';
export const UNARCHIVE_CONVERSATIONS = 'UNARCHIVE_CONVERSATIONS';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const DELETE_CONVERSATIONS = 'DELETE_CONVERSATIONS';
export const REQUEST_CONVERSATION = 'REQUEST_CONVERSATION';
export const RECEIVE_CONVERSATION = 'RECEIVE_CONVERSATION';
export const FAILED_REQUEST_CONVERSATION = 'FAILED_REQUEST_CONVERSATION';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const EXCHANGE_UPDATE = 'EXCHANGE_UPDATE';
export const EXCHANGE_UPDATE_SUCCESS = 'EXCHANGE_UPDATE_SUCCESS';
export const EXCHANGE_UPDATE_FAILED = 'EXCHANGE_UPDATE_FAILED';
export const EXCHANGE_FINALIZATION_FAILED = 'EXCHANGE_FINALIZATION_FAILED';
export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION';

export function requestConversations() {
    return {
        type: REQUEST_CONVERSATIONS
    };
}

export function rangeConversations(offset, limit) {
    return {
        type: RANGE_CONVERSATIONS,
        offset,
        limit
    };
}

export function filterConversations(filter) {
    return {
        type: FILTER_CONVERSATIONS,
        filter
    };
}

export function receiveConversations(response) {
    let conversations;
    let total;
    // GraphQL response format
    if (response.data) {
        conversations = response.data.conversations.edges.map((edge) => new Conversation(edge.node));
        total = response.data.conversations.totalCount || 0;
    } else {
        conversations = response.conversations.map((conversation) => new Conversation(conversation));
        total = response.total_conversations;
    }
    return {
        type: RECEIVE_CONVERSATIONS,
        conversations,
        total
    };
}

export function statsConversations(response) {
    return {
        type: STATS_CONVERSATIONS,
        stats: response
    };
}

export function selectConversations(selected) {
    return {
        type: SELECT_CONVERSATIONS,
        selected: [].concat(selected)
    };
}

export function requestConversation(id) {
    return {
        type: REQUEST_CONVERSATION,
        id
    };
}

export function receiveConversation(conversation) {
    return {
        type: RECEIVE_CONVERSATION,
        conversation: new Conversation(conversation)
    };
}

export function failedRequestConversation(error) {
    return {
        type: FAILED_REQUEST_CONVERSATION,
        error: error instanceof Error ? error.message : error
    };
}

export function updateConversation(conversation) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_CONVERSATION,
            conversation
        });
    };
}

export function clearConversation() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CONVERSATION
        });
    };
}

export function updateExchange(fields) {
    return {
        type: EXCHANGE_UPDATE,
        fields
    };
}

export function exchangeUpdated(exchanges) {
    // givebackDeposit and givebackGP return a single exchange
    if (!Array.isArray(exchanges)) {
        exchanges = [exchanges];
    }
    return {
        type: EXCHANGE_UPDATE_SUCCESS,
        exchanges: exchanges.map((exchange) => new Exchange(exchange))
    };
}

export function exchangeUpdateFailed(response) {
    return {
        type: EXCHANGE_UPDATE_FAILED,
        error: response.responseJSON
            ? response.responseJSON.message
                ? response.responseJSON.message
                : response.responseText
            : response.responseText
    };
}

export function exchangeFinalizationFailed(response) {
    return {
        type: EXCHANGE_FINALIZATION_FAILED,
        payload: {
            error: response.responseJSON ? response.responseJSON : response.responseText,
            code: response.status
        }
    };
}

export function fetchConversationsStats() {
    return (dispatch) => Api.Conversation.stats().then((response) => dispatch(statsConversations(response)));
}

export function toggleFavoriteConversation(id, favorite) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_FAVORITE_CONVERSATIONS,
            id,
            favorite
        });
        return Api.Conversation.toggleFavorite(id, favorite).then(() => dispatch(fetchConversationsStats()));
    };
}

export function archiveConversation(id) {
    return (dispatch) => {
        dispatch({
            type: ARCHIVE_CONVERSATION,
            id
        });
        return Api.Conversation.archive(id).then(() => dispatch(fetchConversationsStats()));
    };
}

export function batchArchiveConversation(ids) {
    return (dispatch) => {
        dispatch({
            type: ARCHIVE_CONVERSATIONS,
            ids
        });
        return Promise.all(ids.map((id) => Api.Conversation.archive(id))).then(() =>
            dispatch(fetchConversationsStats())
        );
    };
}

export function unarchiveConversation(id) {
    return (dispatch) => {
        dispatch({
            type: UNARCHIVE_CONVERSATION,
            id
        });
        return Api.Conversation.unarchive(id).then(() => dispatch(fetchConversationsStats()));
    };
}

export function batchUnarchiveConversation(ids) {
    return (dispatch) => {
        dispatch({
            type: UNARCHIVE_CONVERSATIONS,
            ids
        });
        return Promise.all(ids.map((id) => Api.Conversation.unarchive(id))).then(() =>
            dispatch(fetchConversationsStats())
        );
    };
}

export function deleteConversation(id) {
    return (dispatch) =>
        Api.Conversation.delete(id).then(() => {
            dispatch({
                type: DELETE_CONVERSATION,
                id
            });
            return dispatch(fetchConversationsStats());
        });
}

export function batchDeleteConversation(ids) {
    return (dispatch) => {
        dispatch({
            type: DELETE_CONVERSATIONS,
            ids
        });
        return Promise.all(ids.map((id) => Api.Conversation.delete(id))).then(() =>
            dispatch(fetchConversationsStats())
        );
    };
}

export function fetchConversations(filter = 'all', offset, limit, orderBy) {
    return (dispatch) => {
        dispatch(requestConversations());
        dispatch(fetchConversationsStats());
        return Tour.shouldUseFakeApi('messaging_tour', 'Conversation.meGraphQL').then((shouldUseFakeApi) => {
            const formatedFilter = typeof filter === 'string' ? filter.toUpperCase() : 'all';
            if (shouldUseFakeApi) {
                return FakeApi.MessagingTour.Conversation.meGraphQL(formatedFilter, offset, limit).then(
                    (response) => {
                        dispatch(receiveConversations(response));
                    }
                );
            } else {
                return Api.Conversation.meGraphQL(formatedFilter, offset, limit, orderBy).then((response) =>
                    dispatch(receiveConversations(response))
                );
            }
        });
    };
}

export function searchConversations(search, offset, limit) {
    if (search) {
        return {
            type: SEARCH_CONVERSATIONS,
            search
        };
    }
    return (dispatch) => {
        dispatch({
            type: SEARCH_CONVERSATIONS,
            search: ''
        });
        return dispatch(fetchConversations('all', offset, limit));
    };
}

export function executeSearchConversations(search, offset, limit) {
    return (dispatch) => {
        dispatch(requestConversations());
        return Api.Conversation.search(search, offset, limit).then((response) =>
            dispatch(receiveConversations(response))
        );
    };
}

export function fetchConversation(id, polling = false) {
    if (request && request.state() === 'pending' && typeof request.abort === 'function') {
        request.abort();
    }
    return (dispatch) => {
        dispatch(requestConversation(id));
        return Tour.shouldUseFakeApi('messaging_tour', 'Conversation.get').then((shouldUseFakeApi) => {
            if (shouldUseFakeApi) {
                return FakeApi.MessagingTour.Conversation.get(id).then((response) => {
                    if (response.length > 0) {
                        dispatch(receiveConversation(_.first(response)));
                    } else {
                        dispatch(failedRequestConversation(response));
                    }
                });
            } else {
                request = Api.Conversation.getGraphQL(id, polling);
                return request.then(
                    (response) => {
                        // GraphQL response format
                        if (response.data && response.data.conversation) {
                            dispatch(receiveConversation(response.data.conversation));
                        } else if (response.length > 0) {
                            dispatch(receiveConversation(_.first(response)));
                        } else {
                            dispatch(failedRequestConversation(response.error));
                        }
                    },
                    (response) => {
                        if (response.statusText != 'abort') {
                            dispatch(failedRequestConversation(response.statusText));
                        }
                    }
                );
            }
        });
    };
}

export function patchExchange(exchangeId, fields) {
    return (dispatch) => {
        // use ID property if value is an instance of Model
        fields = _.mapObject(fields, (value) => {
            if (value instanceof Model) {
                return value.id;
            }
            return value;
        });
        return Api.Exchange.patch(exchangeId, fields).then(
            (response) => dispatch(exchangeUpdated(response)),
            (response) => dispatch(exchangeUpdateFailed(response))
        );
    };
}

export function changeConversationToReciprocal(conversation) {
    const guest = conversation.getExchange().get('guest');
    return (dispatch) =>
        Api.Home.getGraphQL(guest.id, 'ONLINE')
            .then((homes) =>
                _.sortBy(homes, (home) => Math.sin(moment(home.full_completion_date).format('X')))
            )
            .then((homes) => {
                if (homes.length === 0) {
                    sweetAlert({
                        icon: 'warning',
                        title: i18n.t('common:problem_occured'),
                        text: i18n.t('exchange:home_unavailable', {
                            first_name: guest.get('first_name')
                        })
                    });
                    return Promise.reject();
                } else {
                    return dispatch(updateStayRequestToReciprocal(conversation.id, homes[0].id));
                }
            });
}

export function approveConversation(conversationId) {
    return (dispatch) =>
        Api.Conversation.approve(conversationId).then(
            (response) => dispatch(exchangeUpdated(response)),
            (response) => {
                dispatch(exchangeUpdateFailed(response));
                return Promise.reject(response);
            }
        );
}

function handleFinalizeSuccess(response, userId) {
    let exchangeId;
    // Get exchange id where the current user is the guest
    if (response && response.length === 1) {
        // Simple exchange
        exchangeId = response[0].id;
    } else if (response && response.length === 2) {
        // Reciprocal exchange
        for (let i = 0, l = response.length; i < l; i++) {
            if (response[i].guest.id == userId) {
                exchangeId = response[i].id;
            }
        }
    }
    /** @Tracking */
    window.analytics.track('finalized-exchange', {
        exchangeId
    });
}

export function finalizeConversation(conversationId, userId) {
    return (dispatch) =>
        Api.Conversation.finalize(conversationId).then(
            (response) => {
                handleFinalizeSuccess(response, userId);
                return dispatch(exchangeUpdated(response));
            },
            (response) => {
                const errorAction = dispatch(exchangeFinalizationFailed(response));
                return Promise.reject(errorAction);
            }
        );
}

export function finalizeBillingConversation(
    conversationId,
    data,
    paymentType = 'credit-card',
    userId,
    couponName,
    is3xPaymentFromFinalisation = false
) {
    let payFunction = is3xPaymentFromFinalisation
        ? Api.Conversation.finalizeWithToken3x
        : Api.Conversation.finalizeWithToken;

    if (paymentType === 'saved-card') {
        payFunction = is3xPaymentFromFinalisation
            ? Api.Conversation.finalizeWithCardId3x
            : Api.Conversation.finalizeWithCardId;
    } else if (paymentType === 'saved-paypal') {
        payFunction = Api.Conversation.finalizeWithExistingPaypalAccount;
    } else if (paymentType === 'paypal') {
        payFunction = Api.Conversation.finalizeWithPaypal;
    }

    return (dispatch) =>
        payFunction(conversationId, ...data, couponName).then(
            (response) => {
                handleFinalizeSuccess(response, userId);
                return dispatch(exchangeUpdated(response));
            },
            (response) => {
                sweetAlert({
                    title: i18n.t('common:an_error_occured'),
                    text: i18n.t('common:an_error_occured'),
                    icon: 'error'
                });

                const errorAction = dispatch(exchangeFinalizationFailed(response));
                return Promise.reject(errorAction);
            }
        );
}

export function cancelConversation(conversationId, data) {
    return (dispatch) =>
        Api.Conversation.cancel(conversationId, data).then(
            (response) => dispatch(exchangeUpdated(response)),
            (response) => dispatch(exchangeUpdateFailed(response))
        );
}

export function manageDeposit(conversationId, giveback) {
    return (dispatch) =>
        Api.Exchange.manageDeposit(conversationId, giveback).then(
            (response) => dispatch(exchangeUpdated(response)),
            (response) => dispatch(exchangeUpdateFailed(response))
        );
}

export function manageGuestpoints(conversationId, giveback) {
    return (dispatch) =>
        Api.Exchange.manageGuestpoints(conversationId, giveback).then(
            (response) => dispatch(exchangeUpdated(response)),
            (response) => dispatch(exchangeUpdateFailed(response))
        );
}
