import { combineReducers } from 'redux';

import auth from './auth';
import availabilities from './availabilities';
import calendar from './calendar';
import collection from './collection';
import conversations from './conversations';
import friends from './friends';
import loadedHomes from './loadedHomes';
import loadedUsers from './loadedUsers';
import localeDescription from './localeDescription';
import groups from './groups';
import groupDetails from './groupDetails';
import homes from './homes';
import homeFavorites from './homeFavorites';
import homeRatings from './homeRatings';
import messages from './messages';
import notifications from './notifications';
import topMenuNotifications from './top-menu-notifications';
import paymentMethods from './paymentMethods';
import pendingFriends from './pendingFriends';
import products from './products';
import productsByExchange from './productsByExchange';
import rewards from './rewards';
import savedSearches from './savedSearches';
import sentFriendRequests from './sentFriendRequests';
import search from './search';
import subscription from './subscription';
import roulette from './roulette';
import transferts from './transferts';
import user from './user';
import userGroups from './userGroups';
import travelers from './travelers';
import exchangesFinalized from './exchangesFinalized';
import loading from './loading';
import transfers from './transfers';
import stayRequest from './stayRequest';

const reducers = combineReducers({
    auth,
    availabilities,
    calendar,
    collection,
    conversations,
    groups,
    groupDetails,
    friends,
    loadedHomes,
    loadedUsers,
    localeDescription,
    homes,
    homeFavorites,
    homeRatings,
    messages,
    notifications,
    topMenuNotifications,
    paymentMethods,
    pendingFriends,
    stayRequest,
    products,
    productsByExchange,
    savedSearches,
    sentFriendRequests,
    search,
    subscription,
    rewards,
    roulette,
    transferts,
    user,
    userGroups,
    travelers,
    exchangesFinalized,
    loading,
    transfers
});

export default reducers;
