import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import Availability from '../../models/Availability';
import Home from '../../models/Home';
import Analytics from '../../utils/analytics';

import CalendarSwitchButtons from '../calendar/CalendarSwitchButtons';
import { AlertDialog } from '@homeexchange/design';

const HomeEditCalendarSwitchButtons = ({ availabilities, home, onChange }) => {
    const [showUnavailableModal, setShowUnavailableModal] = useState(false);
    const [limitRequests, setLimitRequests] = useState(false);
    const [updateAvailabilities, setUpdateAvailabilities] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [confirmButtonText, setConfirmButtonText] = useState('');
    const [targetValues, setTargetValues] = useState(null);

    const hasUnavailability = (availabilities) => {
        return availabilities.some(
            (availability) => availability.attributes.type === Availability.RESERVED.type
        );
    };

    const hasNoAvailablePeriods = (availabilities) => {
        return !availabilities?.some(
            (availability) => availability.attributes.type !== Availability.RESERVED.type
        );
    };

    const toggleContactableUnavailablePeriods = (target) => {
        setTargetValues(target);
        if (home.get('contact_allowed') === Home.CONTACT_ALLOWED_ON_UNAVAILABLE) {
            Analytics.trackGTM('HomeEdition', {
                event_data: {
                    action: 'click',
                    text: 'Limited_request',
                    area: 'home edit'
                }
            });

            if (hasNoAvailablePeriods(availabilities)) {
                setShowUnavailableModal(true);
                setTitle(i18n.t('home:home_no_availabilities'));
                setDescription(i18n.t('home:indicate_availability_contact_preferences'));
                setConfirmButtonText(i18n.t('home:update_calendar'));
                Analytics.trackGTM('modal', {
                    event_data: {
                        action: 'click',
                        text: 'limited_request_you_don’t_have_any_availability_period',
                        area: 'home edit'
                    }
                });
            } else {
                if (hasUnavailability(availabilities)) {
                    Analytics.trackGTM('modal', {
                        event_data: {
                            action: 'click',
                            text: 'lose_your_unavailable_dates_modal',
                            area: 'home edit'
                        }
                    });
                    setShowUnavailableModal(true);
                    setLimitRequests(true);
                    setTitle(i18n.t('home:lose_unavailable_dates'));
                    setDescription(i18n.t('home:lose_unavailable_date_description'));
                    setConfirmButtonText(i18n.t('home:limit_requests_option'));
                } else {
                    onChange(null, {}, target);
                }
            }
        } else {
            Analytics.trackGTM('HomeEdition', {
                event_data: {
                    action: 'click',
                    text: 'Open_request',
                    area: 'home edit'
                }
            });

            if (!hasNoAvailablePeriods(availabilities)) {
                Analytics.trackGTM('modal', {
                    event_data: {
                        action: 'click',
                        text: 'set_unavailable_period_modal',
                        area: 'home edit'
                    }
                });
                setUpdateAvailabilities(true);
                setTitle(i18n.t('home:set_unavailable_periods_in_your_calendar'));
                setDescription(i18n.t('home:set_unavailable_periods_in_your_calendar_description'));
                setConfirmButtonText(i18n.t('home:update_my_availabilities'));
                setShowUnavailableModal(true);
            } else {
                onChange(null, {}, target);
            }
        }
    };

    const handleCloseUnavailableModal = () => {
        setShowUnavailableModal(false);
    };

    const handleLimitRequests = () => {
        Analytics.trackGTM('button', {
            event_data: {
                action: 'click',
                text: 'limit_my_requests_option',
                area: 'home edit'
            }
        });
        onChange(null, {}, targetValues);
        setShowUnavailableModal(false);
        setLimitRequests(false);
    };

    const updateCalendar = () => {
        if (updateAvailabilities) {
            Analytics.trackGTM('button', {
                event_data: {
                    action: 'click',
                    text: 'update_my_availabilities',
                    area: 'home edit'
                }
            });
        } else {
            Analytics.trackGTM('button', {
                event_data: {
                    action: 'click',
                    text: 'update_my_calendar_to_switch_to_limited_request',
                    area: 'home edit'
                }
            });
        }

        if (!hasNoAvailablePeriods(availabilities)) {
            onChange(null, {}, targetValues);
        }

        setShowUnavailableModal(false);

        const element = document.getElementById('availabilities-of-your-home');
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - 90;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <CalendarSwitchButtons
                contactableUnavailablePeriods={home.get('contact_allowed')}
                toggleContactableUnavailablePeriods={toggleContactableUnavailablePeriods}
            />
            <AlertDialog
                title={title}
                confirmActionText={confirmButtonText}
                isOpen={showUnavailableModal}
                onConfirmAction={limitRequests ? handleLimitRequests : updateCalendar}
                confirmActionColor="primary"
                dismissActionText={i18n.t('home:cancel-availability')}
                onDismissAction={handleCloseUnavailableModal}
            >
                <p>{description}</p>
            </AlertDialog>
        </div>
    );
};

HomeEditCalendarSwitchButtons.propTypes = {
    availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
    home: PropTypes.instanceOf(Home).isRequired,
    onChange: PropTypes.func.isRequired
};

export default HomeEditCalendarSwitchButtons;
