var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { Autocomplete, Checkbox, Textarea } from '@homeexchange/design';
import i18n from '../../../../i18n';
import AutocompleteService from '../../../../utils/AutocompleteService';
import InteractiveMap from '../../../common/map-ts/interactive-map/InteractiveMap';
import styles from './addressautocomplete.module.scss';
import Icons from '../../../../utils/Icons';
import IconsNumberOfPath from '../../../../models/IconsNumberOfPath';
import { COORDINATE_PATTERN, parseCoordinates } from '../../../../utils-ts/utils';
import Analytics from '../../../../utils/analytics';
import { HOME_EDIT_DATA_AREA, HOME_FUNNEL_DATA_AREA } from '../../../../utils/utils-ts';
var MAP_HEIGHT = 328;
var OVERRIDDEN_ADDRESS_TRUE = 1;
var OVERRIDDEN_ADDRESS_FALSE = 0;
var debounce = function (func, wait) {
    var timeout;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return new Promise(function (resolve) {
            clearTimeout(timeout);
            timeout = setTimeout(function () { return resolve(func.apply(void 0, args)); }, wait);
        });
    };
};
var getFormattedResults = function (suggestions) {
    var result = {};
    suggestions.forEach(function (item) {
        result[item.formatedTitle] = item;
    });
    return result;
};
var getSuggestionsFromResults = function (results) {
    return Object.keys(results).map(function (key, index) { return ({ value: key, id: index }); });
};
var getPredictions = debounce(AutocompleteService.getAddressSuggestions, 500);
var validateCoordinate = function (value) { return COORDINATE_PATTERN.test(value); };
var AddressAutocomplete = function (_a) {
    var name = _a.name, _b = _a.value, value = _b === void 0 ? '' : _b, onChange = _a.onChange, selectHandler = _a.selectHandler, handleTextareaChange = _a.handleTextareaChange, handleCheckboxChange = _a.handleCheckboxChange, isAutocompleteEmpty = _a.isAutocompleteEmpty, isSecondAutocompleteEmpty = _a.isSecondAutocompleteEmpty, _c = _a.coordinates, coordinates = _c === void 0 ? '' : _c, _d = _a.textareaValue, textareaValue = _d === void 0 ? '' : _d, _e = _a.textareaError, textareaError = _e === void 0 ? '' : _e, _f = _a.firstAutocompleteError, firstAutocompleteError = _f === void 0 ? '' : _f, _g = _a.secondAutocompleteError, secondAutocompleteError = _g === void 0 ? '' : _g, _h = _a.checked, checked = _h === void 0 ? false : _h, _j = _a.firstAutocompleteLabelHelperContent, firstAutocompleteLabelHelperContent = _j === void 0 ? false : _j, _k = _a.isHomeVerified, isHomeVerified = _k === void 0 ? false : _k, _l = _a.missingLocation, missingLocation = _l === void 0 ? false : _l, _m = _a.isFunnel, isFunnel = _m === void 0 ? false : _m, triggerOnChange = _a.triggerOnChange;
    var _o = useState(coordinates
        ? {
            lat: parseFloat(coordinates.split(',')[0]),
            lon: parseFloat(coordinates.split(',')[1])
        }
        : null), center = _o[0], setCenter = _o[1];
    var _p = useState(coordinates ? true : false), showMarker = _p[0], setShowMarker = _p[1];
    var _q = useState(true), showMap = _q[0], setShowMap = _q[1];
    var _r = useState(null), firstPredictions = _r[0], setFirstPredictions = _r[1];
    var _s = useState(null), secondPredictions = _s[0], setSecondPredictions = _s[1];
    var _t = useState(checked), isChecked = _t[0], setIsChecked = _t[1];
    var _u = useState(textareaError), textareaErrorState = _u[0], setTextareaError = _u[1];
    var _v = useState(firstAutocompleteError), firstAutocompleteErrorState = _v[0], setFirstAutocompleteError = _v[1];
    var _w = useState(secondAutocompleteError), secondAutocompleteErrorState = _w[0], setSecondAutocompleteError = _w[1];
    var _x = useState(value), firstAutocompleteValue = _x[0], setFirstAutocompleteValue = _x[1];
    var _y = useState(coordinates), secondAutocompleteValue = _y[0], setSecondAutocompleteValue = _y[1];
    useEffect(function () {
        setTextareaError(textareaError || null);
    }, [textareaError]);
    useEffect(function () {
        setFirstAutocompleteError(firstAutocompleteError || null);
    }, [firstAutocompleteError]);
    useEffect(function () {
        setSecondAutocompleteError(secondAutocompleteError || null);
    }, [secondAutocompleteError]);
    useEffect(function () {
        Analytics.trackGTM('address_unrecognized', {
            event_data: {
                action: 'click',
                text: 'confirm_location_address_recognized',
                area: isFunnel ? HOME_FUNNEL_DATA_AREA : HOME_EDIT_DATA_AREA,
                value: checked ? 'true' : 'false'
            }
        });
        setIsChecked(checked);
    }, [checked]);
    useEffect(function () {
        var fetchInitialAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, lat, lon, results, firstResult, error_1, _b, lat, lon, results, firstResult, error_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(value && !isChecked)) return [3 /*break*/, 7];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        _a = coordinates.split(','), lat = _a[0], lon = _a[1];
                        if (!(lat && lon)) return [3 /*break*/, 2];
                        setCenter({
                            lat: parseFloat(lat),
                            lon: parseFloat(lon)
                        });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, AutocompleteService.getAddressSuggestions(value)];
                    case 3:
                        results = _c.sent();
                        firstResult = results[0];
                        if (firstResult === null || firstResult === void 0 ? void 0 : firstResult.geometry) {
                            setCenter({
                                lat: firstResult.geometry.coordinates[1],
                                lon: firstResult.geometry.coordinates[0]
                            });
                        }
                        _c.label = 4;
                    case 4:
                        setShowMarker(true);
                        setShowMap(true);
                        isAutocompleteEmpty && isAutocompleteEmpty(false);
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _c.sent();
                        console.error('Error fetching initial address:', error_1);
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 11];
                    case 7:
                        if (!(coordinates && isChecked)) return [3 /*break*/, 11];
                        _b = coordinates.split(',').map(Number), lat = _b[0], lon = _b[1];
                        _c.label = 8;
                    case 8:
                        _c.trys.push([8, 10, , 11]);
                        return [4 /*yield*/, AutocompleteService.getGidFromReverseGeocoding({ lat: lat, lon: lon })];
                    case 9:
                        results = _c.sent();
                        firstResult = results[0];
                        if (firstResult === null || firstResult === void 0 ? void 0 : firstResult.geometry) {
                            setShowMarker(true);
                            setShowMap(true);
                            setCenter({
                                lat: firstResult.geometry.coordinates[1],
                                lon: firstResult.geometry.coordinates[0]
                            });
                            isSecondAutocompleteEmpty && isSecondAutocompleteEmpty(false);
                        }
                        return [3 /*break*/, 11];
                    case 10:
                        error_2 = _c.sent();
                        console.error('Error fetching reverse geocoding data:', error_2);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        }); };
        fetchInitialAddress();
    }, []);
    var handleSelect = function (selectedValue_1) {
        var args_1 = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args_1[_i - 1] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([selectedValue_1], args_1, true), void 0, function (selectedValue, checkCoordinates) {
            var parsedCoords, lat, lon, results, firstResult, error_3, results, firstResult, error_4;
            if (checkCoordinates === void 0) { checkCoordinates = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!selectedValue) return [3 /*break*/, 1];
                        triggerChange('');
                        setCenter(null);
                        if (isChecked) {
                            setSecondAutocompleteError(i18n.t('home:enter_address_or_gps'));
                        }
                        return [3 /*break*/, 9];
                    case 1:
                        if (!(validateCoordinate(selectedValue) && checkCoordinates)) return [3 /*break*/, 6];
                        parsedCoords = parseCoordinates(selectedValue);
                        lat = parsedCoords.latitude, lon = parsedCoords.longitude;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, AutocompleteService.getGidFromReverseGeocoding({ lat: lat, lon: lon })];
                    case 3:
                        results = _a.sent();
                        firstResult = results[0];
                        if (firstResult === null || firstResult === void 0 ? void 0 : firstResult.geometry) {
                            setShowMarker(true);
                            setShowMap(true);
                            setCenter({
                                lat: firstResult.geometry.coordinates[1],
                                lon: firstResult.geometry.coordinates[0]
                            });
                            if (selectHandler) {
                                selectHandler({
                                    label: '',
                                    gid: firstResult.properties.gid,
                                    coordinates: "".concat(firstResult.geometry.coordinates[1], ", ").concat(firstResult.geometry.coordinates[0]),
                                    center: firstResult.geometry.coordinates
                                });
                            }
                            triggerChange(selectedValue);
                            isSecondAutocompleteEmpty && isSecondAutocompleteEmpty(false);
                            setSecondAutocompleteError(null);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_3 = _a.sent();
                        console.error('Error fetching reverse geocoding data:', error_3);
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 9];
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, AutocompleteService.getAddressSuggestions(selectedValue)];
                    case 7:
                        results = _a.sent();
                        firstResult = results[0];
                        if (firstResult === null || firstResult === void 0 ? void 0 : firstResult.geometry) {
                            setShowMarker(true);
                            setShowMap(true);
                            setCenter({
                                lat: firstResult.geometry.coordinates[1],
                                lon: firstResult.geometry.coordinates[0]
                            });
                            if (selectHandler) {
                                selectHandler({
                                    label: firstResult.formatedTitle,
                                    gid: firstResult.properties.gid,
                                    coordinates: '',
                                    center: firstResult.geometry.coordinates
                                });
                            }
                            triggerChange(firstResult.formatedTitle);
                            isAutocompleteEmpty && isAutocompleteEmpty(false);
                            if (isChecked) {
                                setSecondAutocompleteError(null);
                                isSecondAutocompleteEmpty && isSecondAutocompleteEmpty(false);
                            }
                            else {
                                setFirstAutocompleteError(null);
                            }
                        }
                        else {
                            if (isChecked) {
                                setSecondAutocompleteError(i18n.t('home:enter_address_or_gps'));
                            }
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        error_4 = _a.sent();
                        console.error('Error fetching address suggestions:', error_4);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    var triggerChange = function (changedValue) {
        if (onChange) {
            var inputEvent = {
                target: {
                    value: changedValue,
                    name: name
                }
            };
            onChange(inputEvent);
        }
    };
    var onFirstInputChange = function (value) {
        setFirstAutocompleteValue(value);
        if (value !== '') {
            getPredictions(value)
                .then(function (predictions) {
                setFirstPredictions(getFormattedResults(predictions));
            })
                .catch(function (error) {
                console.error('Error fetching predictions:', error);
            });
        }
        else if (value === '') {
            setShowMap(false);
            setFirstPredictions(null);
            isAutocompleteEmpty && isAutocompleteEmpty(true);
            triggerOnChange && handleUnrecognizedAddressChange('address', value);
        }
    };
    var handleUnrecognizedAddressChange = function (name, value) {
        var event = {
            target: {
                name: name,
                value: value,
                getAttribute: function () { return 'home_address_unrecognized_address'; }
            }
        };
        triggerOnChange(event);
    };
    var onSecondInputChange = function (value) {
        var _a;
        setSecondAutocompleteValue(value);
        if (validateCoordinate(value)) {
            var _b = value.split(',').map(Number), lat = _b[0], lon = _b[1];
            setSecondPredictions((_a = {},
                _a[value] = {
                    formatedTitle: value,
                    bbox: '',
                    properties: { layer: '', value: '', gid: '' },
                    geometry: { coordinates: [lat, lon] }
                },
                _a));
        }
        else if (value !== '') {
            getPredictions(value)
                .then(function (predictions) {
                setSecondPredictions(getFormattedResults(predictions));
            })
                .catch(function (error) {
                console.error('Error fetching predictions:', error);
            });
        }
        else if (value === '') {
            setShowMap(false);
            setSecondPredictions(null);
            isSecondAutocompleteEmpty && isSecondAutocompleteEmpty(true);
            setSecondAutocompleteError(i18n.t('home:enter_address_or_gps'));
            triggerOnChange && handleUnrecognizedAddressChange('location_id', value);
        }
    };
    var onCheckboxChangeHandler = function () {
        var newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        if (handleCheckboxChange) {
            handleCheckboxChange(newCheckedState);
            var overriddenAddress = newCheckedState ? OVERRIDDEN_ADDRESS_TRUE : OVERRIDDEN_ADDRESS_FALSE;
            triggerOnChange && handleUnrecognizedAddressChange('overridden_address', overriddenAddress);
        }
        if (!newCheckedState) {
            setTextareaError(null);
            setSecondAutocompleteError(null);
        }
        else {
            setFirstPredictions(null);
            setSecondPredictions(null);
            if (isChecked) {
                setSecondAutocompleteError(i18n.t('home:enter_address_or_gps'));
            }
            else {
                isAutocompleteEmpty && isAutocompleteEmpty(true);
            }
        }
        if (newCheckedState) {
            setFirstAutocompleteValue('');
            handleTextareaChange && firstAutocompleteValue && handleTextareaChange(firstAutocompleteValue);
        }
    };
    var onTextareaChangeHandler = function (e) {
        var newValue = e.target.value;
        if (handleTextareaChange) {
            handleTextareaChange(newValue);
        }
        if (!newValue) {
            setTextareaError(i18n.t('home:enter_full_address_manually'));
        }
        else {
            setTextareaError(null);
        }
    };
    return (React.createElement("div", { className: styles['container'] },
        React.createElement(Autocomplete, __assign({ label: i18n.t('search:home_address'), inputValue: isChecked ? '' : firstAutocompleteValue || '', suggestions: getSuggestionsFromResults(firstPredictions || {}) }, (firstAutocompleteLabelHelperContent && {
            labelHelperContent: String(i18n.t('home:home_address_info'))
        }), { onChange: onFirstInputChange, onSelect: handleSelect, noSuggestionText: i18n.t('common:no_result'), disabled: isChecked || (isHomeVerified && !missingLocation), hasError: !!firstAutocompleteErrorState, errorText: firstAutocompleteErrorState || '', inputIcon: "search-location-pin", itemsIcon: "search-location-pin" })),
        isHomeVerified && !missingLocation ? (React.createElement("div", { className: styles['home-verified-container'] },
            React.createElement("span", { className: "icon-selection-tick-selected" }, Icons.transform(IconsNumberOfPath.SELECTION_TICK_SELECTED)),
            React.createElement("p", { className: styles['verified-address-status'] }, String(i18n.t('home:verified_address_status'))))) : (React.createElement("div", { className: styles['checkbox-container'] },
            React.createElement(Checkbox, { label: i18n.t('search:home_address_is_not_on_the_list'), value: "addressNotListed", onChange: onCheckboxChangeHandler, checked: isChecked }))),
        isChecked && (React.createElement("div", { className: styles['unrecognised-address-container'] },
            React.createElement(Textarea, { id: "unrecognised-textarea", title: i18n.t('search:full_address'), helperText: i18n.t('search:enter_address_for_guests'), onChange: onTextareaChangeHandler, value: textareaValue || '', status: textareaErrorState ? 'error' : 'default', message: textareaErrorState || '' }),
            React.createElement("div", { className: styles['autocomplete-coordinates'] },
                React.createElement(Autocomplete, { label: i18n.t('search:closest_address_or_gps'), inputValue: secondAutocompleteValue || '', labelHelperContent: React.createElement("span", { dangerouslySetInnerHTML: {
                            __html: i18n.t('search:enter_nearest_address_or_gps')
                        } }), suggestions: getSuggestionsFromResults(secondPredictions || {}), onChange: onSecondInputChange, onSelect: function (value) { return handleSelect(value, true); }, noSuggestionText: i18n.t('common:no_result'), hasError: !!secondAutocompleteErrorState, errorText: secondAutocompleteErrorState || '', inputIcon: "search-location-pin", itemsIcon: "search-location-pin" })))),
        center && showMap && (React.createElement("div", { className: styles['map-container'] },
            React.createElement(InteractiveMap, { latitude: center.lat, longitude: center.lon, height: MAP_HEIGHT, showControls: true, centeredMarker: showMarker })))));
};
export default AddressAutocomplete;
