import React from 'react';
import Utils from '../../../../../utils/utils';
import { routesConfig } from '../config';
import { useParams } from 'react-router-dom';
import styles from './header-funnel.module.scss';
import clsx from 'clsx';
import { Flex, Text } from '@homeexchange/design';
import i18n from '../../../../../i18n';
var getMaxStepsForPart = function (partId) {
    var part = routesConfig[partId];
    if (!part)
        return 0;
    return Object.values(part.steps).filter(function (step) {
        return !step.excludedFromStepper;
    }).length;
};
var getProgressForPart = function (currentPartId, currentStepId, partId) {
    if (partId < currentPartId)
        return 100;
    if (partId > currentPartId)
        return 0;
    var maxSteps = getMaxStepsForPart(partId);
    if (currentStepId === 0)
        return 0;
    var normalizedStepId = Math.floor(currentStepId);
    return (normalizedStepId / maxSteps) * 100;
};
var getTotalSteps = function () {
    return Object.values(routesConfig).reduce(function (total, part) {
        return total + Object.values(part.steps).filter(function (step) { return !step.excludedFromStepper; }).length;
    }, 0);
};
var getAddress = function (currentHome) {
    var _a, _b, _c, _d;
    if (!currentHome || !((_b = (_a = currentHome === null || currentHome === void 0 ? void 0 : currentHome.attributes) === null || _a === void 0 ? void 0 : _a.translated_admins) === null || _b === void 0 ? void 0 : _b.admin3))
        return '';
    var homeType = (_c = currentHome === null || currentHome === void 0 ? void 0 : currentHome.attributes) === null || _c === void 0 ? void 0 : _c.shared_part;
    var admin3 = (_d = currentHome === null || currentHome === void 0 ? void 0 : currentHome.attributes) === null || _d === void 0 ? void 0 : _d.address;
    var homeTypeLabels = {
        1: i18n.t('home:shared-type.whole-unit'),
        2: i18n.t('home:shared-type.private-room')
    };
    var homeTypeLabel = homeTypeLabels[homeType];
    if (!homeTypeLabel)
        return '';
    return (React.createElement(Flex, { className: styles['address-container'], direction: "column", gap: "1" },
        React.createElement(Text, { isSmall: true, isBold: true }, homeTypeLabel),
        React.createElement(Text, { className: styles['address-text'], isSmall: true }, admin3)));
};
export var HeaderFunnel = function (_a) {
    var _b;
    var noMargin = _a.noMargin, redirectToDashboard = _a.redirectToDashboard, currentHome = _a.currentHome, isCollection = _a.isCollection, noRedirect = _a.noRedirect;
    var _c = useParams(), partId = _c.partId, stepId = _c.stepId;
    var currentPartId = parseInt(partId);
    var currentStepId = parseInt(stepId);
    var totalParts = Object.keys(routesConfig).length;
    return (React.createElement(Flex, { className: clsx(styles['header-funnel'], stepId ? styles['header-funnel-step'] : undefined, noMargin ? styles['no-margin'] : undefined), alignItems: "center", gap: "0", isFullWidth: true },
        React.createElement("picture", { onClick: function () {
                if (noRedirect) {
                    return;
                }
                if (redirectToDashboard) {
                    window.location.href = i18n.t('url:dashboard');
                }
            } },
            React.createElement("source", { media: "(max-width: 768px)", srcSet: "".concat(Utils.getCloudfrontDomain(), "/images/website/logo/he-logo-mobile").concat(isCollection ? '-collections' : '', ".svg") }),
            React.createElement("img", { id: !redirectToDashboard || noRedirect ? 'blocker-funnel' : undefined, className: clsx(styles['logo-img'], noRedirect ? styles['no-redirect'] : undefined), src: "".concat(Utils.getCloudfrontDomain(), "/images/website/logo/he-logo").concat(isCollection ? '-collections' : '', ".svg"), alt: "Logo HomeExchange" })),
        stepId && stepId !== '0' && (React.createElement(Flex, { direction: "column", className: styles['step-counter'], gap: "1" },
            React.createElement("span", { className: styles['step-counter-text'] }, (_b = routesConfig[currentPartId]) === null || _b === void 0 ? void 0 : _b.title),
            React.createElement("span", null, "".concat(currentStepId, "/").concat(getMaxStepsForPart(currentPartId))))),
        getAddress(currentHome),
        stepId && (React.createElement("div", { className: styles['progress-bar-container'] }, Array.from({ length: totalParts }, function (_, index) {
            var segmentPartId = index + 1;
            var stepWidth = 100 / getTotalSteps();
            var segmentWidth = stepWidth * getMaxStepsForPart(segmentPartId);
            return (React.createElement("div", { key: segmentPartId, className: styles['progress-segment'], style: {
                    width: "".concat(segmentWidth, "%"),
                    '--progress': "".concat(getProgressForPart(currentPartId, currentStepId, segmentPartId), "%")
                } }));
        })))));
};
