import React from 'react';
import { AlertDialog, Callout, Flex, Icon, Text } from '@homeexchange/design';
import i18n from '../../../../i18n';
export var ModalPrm = function (props) {
    return (React.createElement(AlertDialog, { isOpen: props.open, setIsOpen: props.onClose, title: i18n.t('home:modalPRM.title'), confirmActionText: i18n.t('home:modalPRM.confirmActionText'), onConfirmAction: function () { return props.onConfirm(); }, dismissActionText: i18n.t('home:modalPRM.dismissActionText'), onDismissAction: function () { return props.onDismiss(); } },
        React.createElement(Flex, { direction: "column", gap: "8", flexWrap: "nowrap" },
            React.createElement(Text, null,
                React.createElement(React.Fragment, null, i18n.t('home:modalPRM.description'))),
            React.createElement(Callout, { status: "info" },
                React.createElement(Flex, { direction: "column", gap: "4", flexWrap: "nowrap" }, [
                    {
                        title: i18n.t('home:modalPRM.stepFreeEntry.title'),
                        description: i18n.t('home:modalPRM.stepFreeEntry.description'),
                        icon: 'step-free-entry'
                    },
                    {
                        title: i18n.t('home:modalPRM.wideDoors.title'),
                        description: i18n.t('home:modalPRM.wideDoors.description'),
                        icon: 'wide-doors'
                    },
                    {
                        title: i18n.t('home:modalPRM.accessibleBathroom.title'),
                        description: i18n.t('home:modalPRM.accessibleBathroom.description'),
                        icon: 'accessible-bathroom'
                    },
                    {
                        title: i18n.t('home:modalPRM.easyNavigation.title'),
                        description: i18n.t('home:modalPRM.easyNavigation.description'),
                        icon: 'easy-navigation'
                    },
                    {
                        title: i18n.t('home:modalPRM.accessibleBedroom.title'),
                        description: i18n.t('home:modalPRM.accessibleBedroom.description'),
                        icon: 'accessible-bedroom'
                    }
                ].map(function (item, index) { return (React.createElement(Flex, { key: index, direction: "row", gap: "4", flexWrap: "nowrap" },
                    React.createElement(Icon, { name: item.icon, size: "2xl" }),
                    React.createElement("div", null,
                        React.createElement(Text, { isBold: true, isSpan: true }, item.title),
                        React.createElement(Text, { isSmall: true }, item.description)))); }))))));
};
